import React, { useState, useRef } from 'react';
import ModalWrapper from './ModalWrapper';
import {
    Button,
    CloseIcon,
    FullscreenOverlay,
    TrackDetails,
    Heading4,
    InfoMessage
} from '../styled';
import uploadTrackAudio from '../../api/uploadTrackAudio';
import setNoAudio from '../../api/setNoAudio';
import shortenLabel from '../../util/shortenLabel';
import { Recording } from '../../util/types';
import combineTitle from '../../util/combineTitle';
import CustomCloseIcon from '../../assets/close.svg';
import UploadIcon from '../../assets/upload.svg';
import NoIcon from '../../assets/no.svg';
import CompleteIcon from '../../assets/complete.svg';

interface Props {
    artistId: string;
    track: Recording;
    onClose: () => void;
    refetch: () => void;
}

const TrackDetailsModal: React.FC<Props> = ({ artistId, track, onClose, refetch }) => {
    const [file, setFile] = useState<File | null>();
    const [success, setSuccess] = useState<boolean | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const inputComponent = useRef<HTMLInputElement | null>(null);

    const fileUploadButton = () => {
        if (inputComponent.current) {
            inputComponent.current.click();
            // @ts-ignore
            inputComponent.current.onchange = (e) => setFile(e.target?.files[0]);
        }
    };

    // Convert file to base64String, upload the file to AWS, show success message, refetch data
    const onSubmit = async (noAudio?: boolean) => {
        if (file || noAudio !== undefined) {
            try {
                setLoading(true);

                if (file) await uploadTrackAudio(artistId, track.recording_id, file);
                else if (noAudio !== undefined)
                    await setNoAudio(artistId, track.recording_id, noAudio);

                setSuccess(true);
                setLoading(false);
                setFile(null);
                refetch();
            } catch (e) {
                setSuccess(false);
                setLoading(false);
            }
        }
    };

    return (
        <FullscreenOverlay>
            <ModalWrapper onClose={onClose}>
                <TrackDetails>
                    <div>
                        <Heading4>{combineTitle(track.title, track.title_addition)}</Heading4>

                        <CloseIcon onClick={onClose} customImage relative>
                            <img src={CustomCloseIcon} alt="Close modal" />
                        </CloseIcon>
                    </div>

                    <span />

                    <div>
                        <p>
                            <span>ISRC</span>
                            {track.isrc || '-'}
                        </p>
                        <p>
                            <span>Duration</span>
                            {track.duration || '-'}
                        </p>
                        <p>
                            <span>Language</span>
                            {track.language || '-'}
                        </p>
                        <p>
                            <span>Lyrics by</span>
                            {track.authors.lyrics
                                ?.filter((value, index, self) => self.indexOf(value) === index)
                                .join(', ') || '-'}
                        </p>
                        <p>
                            <span>Music by</span>
                            {track.authors.music
                                ?.filter((value, index, self) => self.indexOf(value) === index)
                                .join(', ') || '-'}
                        </p>
                        <p>
                            <span>Publisher</span>
                            {shortenLabel(track.publisher || '-')}
                        </p>
                    </div>

                    <span />

                    <div>
                        <input type="file" accept="audio/mp3,audio/*" hidden ref={inputComponent} />

                        {!file ? (
                            track.no_audio ? (
                                <div>
                                    <p>
                                        <img src={NoIcon} alt="No audio exists" />
                                        No audio file
                                    </p>
                                    {!track.indexed && (
                                        <Button transparent onClick={() => onSubmit(false)}>
                                            {loading ? 'Loading...' : 'Add audio file'}
                                        </Button>
                                    )}
                                </div>
                            ) : track.audio_uploaded ? (
                                <div>
                                    <p>
                                        <img src={CompleteIcon} alt="Audio uploaded already" />
                                        Audio uploaded
                                    </p>
                                    {!track.indexed && (
                                        <Button transparent onClick={fileUploadButton}>
                                            <img src={UploadIcon} alt="Upload file" />
                                            Replace
                                        </Button>
                                    )}
                                </div>
                            ) : (
                                <>
                                    <Button transparent onClick={fileUploadButton}>
                                        <img src={UploadIcon} alt="Upload file" />
                                        Upload audio file
                                    </Button>

                                    <span onClick={() => onSubmit(true)}>
                                        {loading
                                            ? 'Loading...'
                                            : 'Mark as complete without audio file'}
                                    </span>
                                </>
                            )
                        ) : (
                            <div>
                                <p>
                                    <img
                                        src={CustomCloseIcon}
                                        alt="Select new file"
                                        onClick={() => setFile(null)}
                                    />
                                    {shortenLabel(file.name, 24)}
                                </p>
                                <Button transparent onClick={() => onSubmit()}>
                                    <img src={UploadIcon} alt="Submit" />
                                    {loading ? 'Loading...' : 'Submit'}
                                </Button>
                            </div>
                        )}

                        {success === false && (
                            <InfoMessage error marginTop>
                                Oops, something went wrong, please try again.
                            </InfoMessage>
                        )}
                    </div>
                </TrackDetails>
            </ModalWrapper>
        </FullscreenOverlay>
    );
};

export default TrackDetailsModal;
