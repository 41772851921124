import {MonitorFilter} from "./types";


const intervalToDate = (interval: MonitorFilter): [startDate: string | undefined, endDate: string | undefined] => {
    const today = new Date();
    const lastWeek = new Date();
    const lastMonth = new Date();
    const lastYear = new Date();

    lastWeek.setDate(today.getDate() - 7);
    lastMonth.setMonth(today.getMonth() - 1);
    if (lastMonth.getMonth() === today.getMonth()) lastMonth.setDate(0);
    lastYear.setFullYear(today.getFullYear() - 1);

    let startDate: string | undefined;
    let endDate: string | undefined = today.toISOString().slice(0, 10);

    try {
        switch (interval) {
            case 'Last 7 days':
                startDate = lastWeek.toISOString().slice(0, 10);
                break;
            case 'Last 30 days':
                startDate = lastMonth.toISOString().slice(0, 10);
                break;
            case 'Last year':
                startDate = lastYear.toISOString().slice(0, 10);
                break;
            case 'All time':
                startDate = undefined;
                endDate = undefined;
                break;
            default:
                startDate = new Date(interval.start_interval)
                    .toISOString()
                    .slice(0, 10);
                endDate = new Date(interval.end_interval).toISOString().slice(0, 10);
                break;
        }
    } catch (e) {
        startDate = lastMonth.toISOString().slice(0, 10);
    }
    return [startDate, endDate]
}

export default intervalToDate;
