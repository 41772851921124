import React, { useRef } from 'react';
import { Pie } from 'react-chartjs-2';
import formatNumber from '../../util/formatNumber';
import { ChartStyles } from '../../util/types';
import { PieChartWrapper } from '../styled';
import ChartWrapper from './ChartWrapper';

interface Props {
    title: string;
    description: string;
    explanation: string;
    unit: string;
    values: Array<number>;
    labels: Array<string>;
    noLegend?: boolean;
    roundTo?: number;
    sales_data?: Array<object>;
    noTooltipTitles?: boolean;
    chartStyles: ChartStyles;
}

const PieChart: React.FC<Props> = (props) => {
    const {
        title,
        description,
        explanation,
        unit,
        values,
        labels,
        noLegend,
        roundTo,
        sales_data,
        chartStyles,
        noTooltipTitles
    } = props;

    const chartRef = useRef();

    const options = {
        layout: {
            padding: {
                bottom: 30
            }
        },
        plugins: {
            datalabels: { display: false },
            legend: {
                display: noLegend ? false : true,
                position: 'bottom',
                labels: {
                    boxWidth: 12,
                    padding: 20,
                    font: {
                        family: chartStyles.fontFamily,
                        size: 14
                    },
                    color: chartStyles.white,
                    usePointStyle: true
                },
                onHover: (event: any, legendItem: any) => {
                    if (legendItem) event.native.target.style.cursor = 'pointer';
                }
            },
            tooltip: {
                yAlign: 'center',
                intersect: true,
                backgroundColor: chartStyles.white,
                titleFont: {
                    family: chartStyles.fontFamily,
                    size: 14,
                    weight: 'bold'
                },
                titleColor: chartStyles.black,
                titleSpacing: 8,
                bodyFont: {
                    family: chartStyles.fontFamily,
                    size: 15,
                    weight: 'normal'
                },
                bodyColor: chartStyles.black,
                bodySpacing: 4,
                cornerRadius: 5,
                displayColors: false,
                borderWidth: 0,
                padding: { x: 15, y: 10 },
                caretPadding: 20,
                callbacks: {
                    title: (tooltipItems: any) => {
                        const { dataIndex, dataset } = tooltipItems[0];
                        let value = dataset.data[dataIndex];
                        let percentage = (
                            (value / dataset.data.reduce((a: number, b: number) => a + b, 0)) *
                            100
                        ).toFixed(2);

                        if (noTooltipTitles) return percentage + '%';
                        else return labels[dataIndex] + ': ' + percentage + '%';
                    },
                    label: (context: any) => {
                        const { dataset, dataIndex } = context;

                        let value = dataset.data[dataIndex];

                        if (sales_data) {
                            let datalabels: Array<string> = [];

                            datalabels.push(`Total: ${formatNumber(value)}${unit}`);

                            Object.keys(sales_data[dataIndex]).forEach((key: any) => {
                                datalabels.push(
                                    `${
                                        key.charAt(0).toUpperCase() + key.toLowerCase().slice(1)
                                    }: ${formatNumber(
                                        // @ts-ignore
                                        sales_data[dataIndex][key]
                                    )}${unit}`
                                );
                            });

                            return datalabels;
                        } else {
                            if (values.length === 2) {
                                return (
                                    labels[dataIndex] +
                                    ': ' +
                                    (roundTo ? value.toFixed(roundTo) : formatNumber(value)) +
                                    unit
                                );
                            } else {
                                return formatNumber(value) + unit;
                            }
                        }
                    }
                },
                external: () => {
                    if (chartRef !== undefined) {
                        // @ts-ignore
                        chartRef.current.canvas.style.cursor = 'default';
                    }
                }
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            arc: {
                backgroundColor: chartStyles.brand[0],
                borderWidth: 0,
                borderColor: 'transparent',
                fontColor: 'white'
            }
        }
    };

    const data = {
        labels,
        datasets: [
            {
                label: title,
                data: values,
                backgroundColor: [
                    chartStyles.brand[1],
                    chartStyles.brand[0],
                    chartStyles.brand[2],
                    chartStyles.brand[3]
                ]
            }
        ]
    };

    return (
        <ChartWrapper explanation={explanation} title={title} description={description}>
            <PieChartWrapper>
                <Pie
                    // @ts-ignore
                    ref={chartRef}
                    type="pie"
                    data={data}
                    options={options}
                />
            </PieChartWrapper>
        </ChartWrapper>
    );
};

export default PieChart;
