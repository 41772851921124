import React, {useEffect, useState} from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { BackButton, Button, Container, Heading1, Heading4, StatementPreview } from '../styled';
import PDFViewer from '../misc/PDFViewer';
import ChevronRight from '../../assets/chevron-right.svg';
import PayoutIcon from '../../assets/payout.svg';
import CreditIcon from '../../assets/credit.svg';
import formatNumber from '../../util/formatNumber';
import {Artist, ServiceStatement, User} from '../../util/types';
// Test data, delete file later
import usePrevious from "../../util/usePrevious";
import {useParams} from "react-router";
import fetchServiceStatement from "../../api/fetchServiceStatement";
import fetchServiceStatementPreSignedUrl from "../../api/fetchServiceStatementPreSignedUrl";
import updateServiceStatementStatus from "../../api/updateServiceStatementStatus";
import downloadServiceStatementZip from "../../api/downloadServiceStatementZip";

interface Props {
    user: User | null;
    artist: Artist | null;
}

const ServiceStatementPreview: React.FC<Props> = ({ user, artist }) => {
    const { service_statement_id } = useParams<{ service_statement_id: string }>();

    const { length: historyLength, goBack, push: redirectTo } = useHistory();

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [statement, setStatement] = useState<ServiceStatement>();
    const [statementAccepted, setStatementAccepted] = useState<boolean>(false)
    const [pdfUrl, setPdfUrl] = useState<string>();

    const fetchData = async (service_statement_id: string) => {
        try {
            setLoading(true);

            // FETCH DATA
            const {service_statement} = await fetchServiceStatement(service_statement_id)

            setStatement(service_statement);
            setStatementAccepted(service_statement.meta_data.status === "ACCEPTED")

            let downloadLink = await fetchServiceStatementPreSignedUrl(service_statement_id)
            setPdfUrl(downloadLink)


        } catch (e) {
            setError(
                'An unexpected error occurred, please try reloading the page or contacting us.'
            );
        } finally {
            setLoading(false);
        }
    };

    const updateStatus = async (statement_id: string) => {
        // TODO: Ask user for confirmation
        let newStatus = await updateServiceStatementStatus(statement_id)
        let tmpStatement = statement
        if (tmpStatement) {
            tmpStatement.meta_data.status = newStatus
            setStatement(tmpStatement)
        }
        setStatementAccepted(newStatus === "ACCEPTED")
    }

    const downloadFile = async (statement_id: string) => {
        let blob = await downloadServiceStatementZip(service_statement_id)
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${statement_id}.zip`);
        document.body.appendChild(link)
        link.click();
        if (link && link.parentNode) {
            link.parentNode.removeChild(link)
        }
    }

    const prevArtist = usePrevious(artist);

    // Fetch data on first load + when artist changes
    useEffect(() => {
        const artistChanged =
            prevArtist !== undefined && prevArtist?.artist_id !== artist?.artist_id;

        if (
            user &&
            artist &&
            (!statement || artistChanged) &&
            artist.services.indexOf('INSIGHT') !== -1
        ) {
            fetchData(service_statement_id);
        }
    }, [user, artist, statement, prevArtist, service_statement_id]);

    if (!user || !artist) return <Redirect to="/error" />;

    if (artist.services.indexOf('SERVICE') === -1) return <Redirect to="/" />;

    return (
        <>
            {
                statement && !loading && !error &&
                    <>
                        <Container>
                            <BackButton onClick={() => (historyLength <= 2 ? redirectTo('/service') : goBack())}>
                                <img src={ChevronRight} alt="Go back" />
                                <p>Go back</p>
                            </BackButton>

                            <StatementPreview>
                                <div>
                                    <Heading4>Statement details</Heading4>
                                    <Heading1>
                                        { !statement.featured_artist.is_company &&
                                            `${statement.featured_artist.first_name} ${statement.featured_artist.last_name}`
                                        }
                                        { statement.featured_artist.is_company &&
                                            `${statement.featured_artist.company_name}`
                                        }
                                    </Heading1>

                                    <div>
                                        <div>
                                            <span>Credit</span>
                                            <div>
                                                <div>
                                                    <img src={CreditIcon} alt="Credit" />
                                                </div>
                                                <p>{formatNumber(statement.total.new_balance)}€</p>
                                            </div>
                                        </div>
                                        <div>
                                            <span>Payout</span>
                                            <div>
                                                <div>
                                                    <img src={PayoutIcon} alt="Payout" />
                                                </div>
                                                <p>{formatNumber(statement.total.net_payout)}€</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <Button
                                            disabled={statementAccepted}
                                            secondary
                                            transparent={statementAccepted}
                                            onClick={async () => {await updateStatus(service_statement_id)}}
                                        >
                                            Approve
                                        </Button>
                                        <img src={ChevronRight} alt="Next step" />
                                        <Button
                                            disabled={!statementAccepted}
                                            secondary
                                            transparent={!statementAccepted}
                                            onClick={(async () => {
                                                await downloadFile(service_statement_id)
                                            })}
                                        >
                                            Download
                                        </Button>
                                    </div>
                                </div>

                                <div>
                                    <PDFViewer file={pdfUrl} />
                                </div>
                            </StatementPreview>
                        </Container>
                    </>
            }
        </>

    );
};

export default ServiceStatementPreview;
