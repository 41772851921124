import { MonitorData } from '../util/types';
import { apiUrl } from './config';

const fetchMonitorData = async (
    artist_id: string,
    interval_start: string = '1990-01-01',
    interval_end: string = new Date().toISOString().slice(0, 10)
): Promise<MonitorData> => {
    try {
        const res = await fetch(
            apiUrl + '/music/v1/auth/monitor/dsp_metrics?' +
                new URLSearchParams({
                    artist_id,
                    interval_start,
                    interval_end
                }),
            {
                method: 'get',
                headers: new Headers({
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                })
            }
        );

        if (!res.ok) throw new Error();

        return await res.json();
    } catch (e) {
        throw new Error();
    }
};

export default fetchMonitorData;
