import { apiUrl } from './config';

const renameDocument = async (
    artistId: string,
    document_id: string,
    filename: string
): Promise<void> => {
    try {
        const res = await fetch(apiUrl + '/artist/v1/artist/' + artistId + '/document/rename', {
            method: 'post',
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                document_id,
                filename
            })
        });

        if (!res.ok) throw new Error();

        return;
    } catch (e) {
        throw new Error();
    }
};

export default renameDocument;
