const fetchUser = async () => {
    try {
        const res = await fetch('https://api.takkt.io/music/v1/auth/user/self', {
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            })
        });

        if (!res.ok) throw new Error('Request failed');

        return await res.json();
    } catch (e) {
        throw new Error();
    }
};

export default fetchUser;
