import React, {useEffect, useState} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {DefaultTheme, withTheme} from 'styled-components';
import {Artist, User, ServiceStatement} from '../../util/types';
import ProductHeader from '../navigation/ProductHeader';
import ErrorComponent from '../misc/Error';
import Loading from '../misc/Loading';
import {
    ButtonSmall,
    Container,
    Heading4,
    ProductWrapper,
    ServiceStatementsList,
    StatusLabel
} from '../styled';
import products from '../../util/products';
import usePrevious from '../../util/usePrevious';
import PayoutIcon from '../../assets/payout-grey.svg';
import UserIcon from '../../assets/users.svg';
import FolderIcon from '../../assets/folder.svg';
import formatNumber from '../../util/formatNumber';
// Test data, delete file later
import fetchCollaborations from "../../api/fetchCollaborations";

interface Props {
    user: User | null;
    artist: Artist | null;
    theme: DefaultTheme;
}


const ServiceStatements: React.FC<Props> = ({user, artist, theme}) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    // Type correctly, add types in ../../util/types.ts
    const [data, setData] = useState<{ periods: Map<string, ServiceStatement[]> }>();

    const fetchData = async (artist_id: string) => {
        try {
            setLoading(true);

            // FETCH DATA
            const {collaborations} = await fetchCollaborations(artist_id)

            let period_identifiers = collaborations.map((x) => {
                return x.service_statements.map((y) => {
                    return y.meta_data.settlement_period
                })
            }).reduce((acc, val) => acc.concat(val), [])

            const periods = new Map<string, ServiceStatement[]>()
            period_identifiers.forEach(x => {
                if (!periods.get(x)) {
                    periods.set(x, [])
                }
            })

            console.log(periods)

            collaborations.forEach(c => {
                c.service_statements.forEach(s => {
                    let tmp = periods.get(s.meta_data.settlement_period)
                    if (tmp) {
                        tmp.push(s)
                    }
                })
            })

            setData({periods: periods});
        } catch (e) {
            setError(
                'An unexpected error occurred, please try reloading the page or contacting us.'
            );
        } finally {
            setLoading(false);
        }
    };

    const prevArtist = usePrevious(artist);

    // Fetch data on first load + when artist changes
    useEffect(() => {
        const artistChanged =
            prevArtist !== undefined && prevArtist?.artist_id !== artist?.artist_id;

        if (
            user &&
            artist &&
            (!data || artistChanged) &&
            artist.services.indexOf('INSIGHT') !== -1
        ) {
            fetchData(artist.artist_id);
        }
    }, [user, artist, data, prevArtist]);

    if (!user || !artist) return <Redirect to="/error"/>;

    if (artist.services.indexOf('SERVICE') === -1) return <Redirect to="/"/>;

    return (
        <ProductWrapper>
            <ProductHeader
                category={2}
                title="Service Statements"
                description={products.filter((p) => p.name === 'SERVICE')[0].product_description}
                customButtons={() => (
                    <ButtonSmall>
                        <Link to="/files">Upload new statement data</Link>
                    </ButtonSmall>
                )}
            ></ProductHeader>

            <Container>
                {loading ? (
                    <Loading color={theme.colors.brandTwo}/>
                ) : error || !data || !artist ? (
                    <ErrorComponent message={error}/>
                ) : (
                    <>
                        {(data.periods.size > 0) &&
                            <ServiceStatementsList>
                                {Array.from(data.periods).map((period, index) => (
                                    <div key={index}>
                                        {period[1].length > 0 &&
                                            <>
                                                <Link to={`/service/${period[0].toLowerCase()}`}>
                                                    <li>
                                                        <div>
                                                            <img src={FolderIcon}
                                                                 alt="Service statement"/>
                                                            <Heading4>{period[0]}</Heading4>
                                                        </div>
                                                        <div>
                                                            <img src={UserIcon}
                                                                 alt="Featured artists"/>
                                                            {period[1].filter((item, idx) => idx < 2).map((stmt) => (
                                                                <p>
                                                                    { !stmt.featured_artist.is_company &&
                                                                        `${stmt.featured_artist.first_name}`
                                                                    }
                                                                    { stmt.featured_artist.is_company &&
                                                                        `${stmt.featured_artist.company_name}`
                                                                    }
                                                                </p>
                                                            ))
                                                            }
                                                            {
                                                                period[1].length >= 2 && (period[1].length-2 > 0) &&
                                                                <p>({period[1].length-2} more)</p>
                                                            }
                                                        </div>
                                                        <div>
                                                            <img src={PayoutIcon}
                                                                 alt="Total payout"/>
                                                            <p>
                                                                {formatNumber(
                                                                    period[1]
                                                                        .map((statement) => statement.total.net_payout)
                                                                        .reduce((a, b) => a + b, 0)
                                                                )}
                                                                €
                                                            </p>
                                                        </div>
                                                        <div>
                                                            {
                                                                period[1].length - period[1].filter(
                                                                    (statement) => (statement.meta_data.status === 'ACCEPTED')
                                                                ).length > 0 &&
                                                                <StatusLabel primary>
                                                                    {
                                                                        `${period[1].length - period[1].filter(
                                                                            (statement) => (statement.meta_data.status === 'ACCEPTED')
                                                                        ).length} open`
                                                                    }
                                                                </StatusLabel>
                                                            }
                                                            {
                                                                period[1].length - period[1].filter(
                                                                    (statement) => (statement.meta_data.status === 'ACCEPTED')
                                                                ).length == 0 &&
                                                                <StatusLabel success>
                                                                    completed
                                                                </StatusLabel>
                                                            }
                                                        </div>
                                                    </li>
                                                </Link>
                                            </>
                                        }
                                    </div>
                                ))}
                            </ServiceStatementsList>
                        }
                        {
                            (data.periods.size === 0) &&
                            <ErrorComponent
                                message={"There are no service statements available yet."}/>
                        }
                    </>
                )}
            </Container>
        </ProductWrapper>
    );
};

export default withTheme(ServiceStatements);
