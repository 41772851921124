import React from 'react';
import formatNumber from '../../util/formatNumber';
import { NumberValue, NumberWrapper } from '../styled';
import ChartWrapper from './ChartWrapper';

interface Props {
    title: string;
    description: string;
    explanation: string;
    value: number;
    unit: string;
    linkedSection?: string;
    percentage?: number;
    secondary?: boolean;
    roundTo?: number;
}

const Number: React.FC<Props> = (props) => {
    const { title, description, explanation, value, unit, percentage, roundTo, secondary } = props;

    return (
        <ChartWrapper explanation={explanation} title={title} description={description}>
            <NumberWrapper secondary={secondary} angle={-Math.asin(value) * (180 / Math.PI)}>
                <NumberValue>
                    {roundTo !== undefined ? value.toFixed(roundTo) : formatNumber(value)}
                    {unit}
                </NumberValue>

                {percentage !== undefined && (
                    <p>
                        {`${percentage.toFixed(2)}`}% of total {unit === ' ' ? 'entries' : 'sales'}
                    </p>
                )}
            </NumberWrapper>
        </ChartWrapper>
    );
};

export default Number;
