import { Release } from '../util/types';
import { apiUrl } from './config';

const uploadLabelCopy = async (artist_id: string, file: File): Promise<Release> => {
    try {
        const formData = new FormData();
        formData.append('labelcopy', file);

        const res = await fetch(apiUrl + '/artist/v1/artist/' + artist_id + '/parse_labelcopy', {
            method: 'post',
            body: formData,
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token')
            })
        });

        if (!res.ok) throw new Error();

        return await res.json();
    } catch (e) {
        throw new Error();
    }
};

export default uploadLabelCopy;
