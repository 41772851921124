import { useEffect } from 'react';
import logout from '../../util/logout';
import Loading from '../misc/Loading';

const BadLogin = () => {
    useEffect(() => {
        logout();
    }, []);

    return <Loading fullScreen />;
};

export default BadLogin;
