import { apiUrl } from './config';

const uploadTrackAudio = async (
    artist_id: string,
    recording_id: string,
    file: File
): Promise<void> => {
    try {
        const formData = new FormData();
        formData.append('audio', file);
        formData.append('recording_id', recording_id);

        const res = await fetch(apiUrl + '/artist/v1/artist/' + artist_id + '/recording/upload', {
            method: 'post',
            body: formData,
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token')
            })
        });

        if (!res.ok) throw new Error();

        return;
    } catch (e) {
        throw new Error();
    }
};

export default uploadTrackAudio;
