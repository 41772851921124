import { apiUrl } from './config';
import {ServiceStatement} from "../util/types";

const fetchServiceStatement = async (
    service_statement_id: string
): Promise<{
    service_statement: ServiceStatement;
}> => {
    try {
        const res = await fetch(apiUrl + '/contract/v1/auth/service_statements/' + service_statement_id, {
            method: 'get',
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            })
        });

        if (!res.ok) throw new Error();

        let service_statement = await res.json();

        return { service_statement };
    } catch (e) {
        throw new Error();
    }
};

export default fetchServiceStatement;
