import {apiUrl} from "./config";

const fetchServiceStatement = async (
    service_statement_id: string
): Promise<string> => {
    try {
        const res = await fetch(apiUrl + '/contract/v1/auth/service_statements/' + service_statement_id + '/update_status', {
            method: 'put',
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            })
        });

        if (!res.ok) throw new Error();

        return await res.text();
    } catch (e) {
        throw new Error();
    }
};

export default fetchServiceStatement;
