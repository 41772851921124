import React from 'react';
import { Bar } from 'react-chartjs-2';
import formatNumber from '../../util/formatNumber';
import { ChartStyles } from '../../util/types';
import { DoubleDoubleBarChartWrapper } from '../styled';
import ChartWrapper from './ChartWrapper';

interface Props {
    title: string;
    description: string;
    explanation: string;
    labels: Array<string>;
    units: Array<string>;
    values: Array<Array<number>>;
    chartStyles: ChartStyles;
}

const DoubleDoubleBarChart: React.FC<Props> = (props) => {
    const { title, description, explanation, labels, units, values, chartStyles } = props;

    const maxRoyalty = Math.max(
            ...values.filter((_v, i) => i % 2 === 0)[0],
            ...values.filter((_v, i) => i % 2 === 0)[1]
        ),
        maxSales = Math.max(
            ...values.filter((_v, i) => i % 2 !== 0)[0],
            ...values.filter((_v, i) => i % 2 !== 0)[1]
        );

    const commonOptions = {
        indexAxis: 'y',
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            }
        },
        title: {
            display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            bar: {
                borderRadius: 25,
                borderSkipped: false,
                minBarLength: 200
            }
        }
    };

    const optionsLeft = {
        ...commonOptions,
        layout: {
            padding: {
                left: (() => {
                    if (window.innerWidth >= 800) return 100;
                    else return window.innerWidth >= 700 ? 85 : 75;
                })(),
                right: (() => {
                    if (window.innerWidth >= 800) return 80;
                    else return window.innerWidth >= 700 ? 65 : 55;
                })()
            }
        },
        scales: {
            x1: {
                type: 'linear',
                display: false,
                ticks: {
                    beginAtZero: true,
                    min: -maxRoyalty
                }
            },
            x2: {
                type: 'linear',
                display: false,
                ticks: {
                    beginAtZero: true,
                    min: -maxSales
                }
            },
            yAxis: {
                display: false,
                ticks: {
                    beginAtZero: true
                }
            }
        }
    };

    const optionsRight = {
        ...commonOptions,
        layout: {
            padding: {
                right: (() => {
                    if (window.innerWidth >= 800) return 100;
                    else return window.innerWidth >= 700 ? 85 : 75;
                })(),
                left: (() => {
                    if (window.innerWidth >= 800) return 80;
                    else return window.innerWidth >= 700 ? 65 : 55;
                })()
            }
        },
        scales: {
            x1: {
                type: 'linear',
                display: false,
                ticks: {
                    beginAtZero: true,
                    max: maxRoyalty
                }
            },
            x2: {
                type: 'linear',
                display: false,
                ticks: {
                    beginAtZero: true,
                    max: maxSales
                }
            },
            yAxis: {
                display: false,
                ticks: {
                    beginAtZero: true
                }
            }
        }
    };

    // Subscription streaming in € and units
    // Index 0 = Royalty Amount (€), Index 1 = Sales (units)
    const dataLeft = {
        labels,
        datasets: values
            .filter((_v: number[], i: number) => i < 2)
            .map((data: number[], index: number) => ({
                label: index,
                data: data.map((value: number) => -value),
                xAxisID: `x${(index % 2) + 1}`,
                maxBarThickness: window.innerWidth >= 1100 ? 25 : 20,
                minBarLength: 50,
                datalabels: {
                    anchor: 'start',
                    align: 'left',
                    offset: window.innerWidth >= 700 ? 30 : 20,
                    color: chartStyles.white,
                    font: { weight: 400, size: window.innerWidth >= 700 ? 16 : 14 },
                    formatter: (v: number) => formatNumber(-v) + units[index]
                },
                backgroundColor: chartStyles.brand[0]
            }))
    };

    // Ad-funded streaming in € and units
    // Index 0 = Royalty Amount (€), Index 1 = Sales (units)
    const dataRight = {
        labels,
        datasets: values
            .filter((_v: number[], i: number) => i > 1)
            .map((data: number[], index: number) => ({
                label: index,
                data,
                xAxisID: `x${(index % 2) + 1}`,
                maxBarThickness: window.innerWidth >= 1100 ? 25 : 20,
                minBarLength: 50,
                datalabels: {
                    anchor: 'end',
                    align: 'start',
                    offset: window.innerWidth >= 700 ? -80 : -70,
                    font: { weight: 400, size: window.innerWidth >= 700 ? 16 : 14 },
                    color: chartStyles.white,
                    formatter: (v: number) => formatNumber(v) + units[index]
                },
                backgroundColor: chartStyles.brand[2]
            }))
    };

    return (
        <ChartWrapper explanation={explanation} title={title} description={description}>
            <DoubleDoubleBarChartWrapper>
                <div>
                    <Bar type="horizontal" data={dataLeft} options={optionsLeft} />
                </div>

                <div>
                    {labels.map((label) => (
                        <p key={label}>{label}</p>
                    ))}
                </div>

                <div>
                    <Bar type="horizontal" data={dataRight} options={optionsRight} />
                </div>
            </DoubleDoubleBarChartWrapper>
        </ChartWrapper>
    );
};

export default DoubleDoubleBarChart;
