import React from 'react';
import Explanation from '../misc/Explanation';
import { Heading4, MetricTop, MetricWrapper } from '../styled';
import NewBadge from "../misc/NewBadge";

interface Props {
    explanation?: string;
    title?: string;
    description?: string;
    noWrapper?: boolean;
    customTitle?: any;
    isNew?: boolean;
}

const ChartWrapper: React.FC<Props> = ({
    explanation,
    title,
    description,
    noWrapper,
    customTitle,
    children,
    isNew
}) => (
    <MetricWrapper noWrapper={noWrapper}>
        {isNew && <NewBadge/>}
        {explanation && <Explanation text={explanation} />}

        {title &&
            description &&
            (customTitle || (
                <MetricTop>
                    <Heading4>{title}</Heading4>
                    <p>{description}</p>
                </MetricTop>
            ))}

        {children}
    </MetricWrapper>
);

export default ChartWrapper;
