function shortenLabel(label: string, maxLength?: number) {
    const reduction = label === label.toUpperCase() ? 5 : 0;

    let regularLength = maxLength || 28;

    if (window.innerWidth < 600) regularLength -= 5;
    else if (window.innerWidth < 800) regularLength -= 2;

    const length = regularLength - reduction;

    return label.length > length ? label.substr(0, length) + '...' : label;
}

export default shortenLabel;
