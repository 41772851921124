import Cookies from 'universal-cookie';

const logout = () => {
    const cookies = new Cookies();

    // Remove token + all cached data from localStorage
    localStorage.clear();
    cookies.remove('authenticated', { domain: '.takkt.io', httpOnly: false, path: '/' });
    window.location.replace('https://www.takkt.io/login/');
};

export default logout;
