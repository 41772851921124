import React from 'react';
import ModalWrapper from './ModalWrapper';
import { CloseIcon, FullscreenOverlay, UpcomingFeatures, Heading3 } from '../styled';

interface Props {
    content: string;
    onClose: () => void;
}

const UpcomingFeaturesModal: React.FC<Props> = ({ content, onClose }) => {
    return (
        <FullscreenOverlay>
            <ModalWrapper onClose={onClose}>
                <UpcomingFeatures>
                    <Heading3>Upcoming features</Heading3>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                    <CloseIcon onClick={onClose} />
                </UpcomingFeatures>
            </ModalWrapper>
        </FullscreenOverlay>
    );
};

export default UpcomingFeaturesModal;
