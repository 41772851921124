import React, { Dispatch, SetStateAction, useState } from 'react';
import { Link } from 'react-router-dom';
import DoubleChevron from '../../assets/double-chevron.svg';
import LogoSrc from '../../assets/logo.png';
import logout from '../../util/logout';
import { Artist, User } from '../../util/types';
import ModalWrapper from '../modals/ModalWrapper';
import { Container, Navbar, NavbarMenu, NavbarWrapper, NavbarRight, Profile } from '../styled';

interface Props {
    user: User | null;
    artist: Artist | null;
    setArtist: Dispatch<SetStateAction<Artist | null>>;
}

const NavbarComponent: React.FC<Props> = (props) => {
    const { user, artist, setArtist } = props;

    const [scroll, setScroll] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    window.addEventListener('scroll', () => {
        if (window.innerWidth >= 800) {
            if (!scroll && window.pageYOffset >= 370) setScroll(true);
            else if (scroll && window.pageYOffset < 370) setScroll(false);
        }
    });

    return (
        <Navbar scroll={scroll}>
            <Container>
                <NavbarWrapper>
                    <Link to="/">
                        <img src={LogoSrc} alt="Takkt logo" />
                    </Link>

                    <ModalWrapper onClose={() => setShowMenu(false)}>
                        {user && artist && (
                            <>
                                <NavbarRight>
                                    <Profile
                                        imgSrc={artist.profile_image_url || ''}
                                        onClick={() => setShowMenu(!showMenu)}
                                        active={showMenu}
                                    >
                                        <div />
                                        <p>{artist.pseudonym}</p>
                                        <img src={DoubleChevron} alt="Toggle dropdown" />
                                    </Profile>
                                </NavbarRight>

                                <NavbarMenu show={showMenu}>
                                    <div>
                                        <div />
                                        <div>
                                            <span>Signed in as</span>
                                            {user.first_name}
                                        </div>
                                    </div>
                                    {user.artists
                                        .sort((a, b) => a.pseudonym.localeCompare(b.pseudonym))
                                        .map((activeArtist, index) => (
                                            <div
                                                key={index}
                                                onClick={() => {
                                                    setArtist(activeArtist);
                                                    setShowMenu(false);
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        backgroundImage: `url(${
                                                            activeArtist.profile_image_url || ''
                                                        })`
                                                    }}
                                                />
                                                <span>{activeArtist.pseudonym}</span>
                                            </div>
                                        ))}
                                    <div onClick={logout}>
                                        <div />
                                        <span>Log out</span>
                                    </div>
                                </NavbarMenu>
                            </>
                        )}
                    </ModalWrapper>
                </NavbarWrapper>
            </Container>
        </Navbar>
    );
};

export default NavbarComponent;
