import React, { useState } from 'react';
import { User } from '../../util/types';
import InfoModal from '../modals/InfoModal';
import { Container, FooterWrapper } from '../styled';

interface Props {
    user: User | null;
}

const Footer: React.FC<Props> = ({ user }) => {
    const [showInfo, openInfo] = useState<string | null>(null);

    return (
        <FooterWrapper>
            <Container>
                <p>
                    © {new Date().getFullYear()} Takkt GmbH
                </p>

                <div>
                    {user && (
                        <span onClick={() => !showInfo && openInfo('INDIVIDUAL')}>
                            Individual services
                        </span>
                    )}
                    <a href="mailto:hello@takkt.io">Contact</a>
                </div>
            </Container>

            {showInfo && user && (
                <InfoModal showInfo={showInfo} onClose={() => openInfo(null)} user={user} />
            )}
        </FooterWrapper>
    );
};

export default Footer;
