import styled from 'styled-components';
import LogoutIcon from '../../assets/logout.svg';
import UserIcon from '../../assets/user.svg';
interface NavbarProps {
    scroll?: boolean;
}

const Navbar = styled.div<NavbarProps>`
    position: absolute;
    height: 7rem;
    width: 100%;
    border-bottom: ${({ theme }) => theme.border};
    z-index: 10;
    transition: all 0.3s;

    img {
        width: 9.5rem;

        @media (max-width: 750px) {
            width: 8rem;
        }
    }

    ${(props) =>
        props.scroll &&
        `
        @media (min-width: 751px) {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            height: 8rem;
            background-color: ${props.theme.colors.black};
            border-bottom: ${props.theme.border};
            box-shadow: ${props.theme.boxShadow1};
            padding: 1.5rem 0;
        }
    `}
`;

const NavbarWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
`;

const NavbarRight = styled.div`
    display: flex;
    align-items: center;

    > a {
        margin-right: 3rem;
        font-weight: 400;
    }
`;

interface ProfileProps {
    imgSrc: string;
    active: boolean;
}

const Profile = styled.div<ProfileProps>`
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.darkGrey};
    border-radius: ${({ theme }) => theme.borderRadius1};
    padding: 6px;
    transition: all 0.1s;
    cursor: pointer;

    div {
        height: 3rem;
        width: 3rem;
        background-color: ${({ theme }) => theme.colors.white};
        background-image: url(${(props) => props.imgSrc});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 6px;
    }

    p {
        color: ${({ theme }) => theme.colors.white};
        font-size: 1.6rem;
        font-weight: 500;
        margin: 0 8px;
    }

    img {
        height: 1.6rem;
        width: 1.6rem;
        margin-right: 6px;
        transition: all 0.4s;
        ${(props) => props.active && 'transform: rotateX(180deg);'}
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.darkGrey};
    }
`;

interface NavbarMenuProps {
    show: boolean;
}

const NavbarMenu = styled.div<NavbarMenuProps>`
    visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.show ? 1 : 0)};

    position: absolute;
    right: 0;
    top: 6.5rem;
    z-index: 1000;
    background-color: ${({ theme }) => theme.colors.darkGrey};
    border-radius: ${({ theme }) => theme.borderRadius1};
    box-shadow: ${({ theme }) => theme.boxShadow1};
    border: ${({ theme }) => theme.border};
    width: 20rem;
    overflow: hidden;
    transition: all 0.3s;

    > div {
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.colors.white}B0;
        font-weight: 400;
        min-height: 5rem;
        padding: 1rem;
        transition: all 0.1s;
        cursor: pointer;

        > div:first-of-type {
            width: 3rem;
            height: 3rem;
            min-width: 3rem;
            min-height: 3rem;
            margin-right: 1rem;
            border-radius: 6px;
            background-color: ${({ theme }) => theme.colors.lightGrey}20;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        &:first-of-type {
            border-bottom: ${({ theme }) => theme.border};
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            div:first-of-type {
                background-image: url(${UserIcon});
                background-size: 50%;
            }

            div:last-of-type {
                display: flex;
                align-items: flex-start;
                flex-direction: column;

                span {
                    font-size: 1.4rem;
                    margin-bottom: 3px;
                }
            }
        }

        &:last-of-type {
            border-top: ${({ theme }) => theme.border};
            border-top-left-radius: 0;
            border-top-right-radius: 0;

            div {
                background-image: url(${LogoutIcon});
                background-size: 50%;
            }
        }

        &:hover {
            color: ${({ theme }) => theme.colors.white};
            background-color: ${({ theme }) => theme.colors.lightGrey}20;
        }
    }
`;

interface NavLinkProps {
    active?: boolean;
}

const NavLink = styled.a<NavLinkProps>`
    transition: all 0.2s;
    font-size: 1.6rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.white};

    &:hover {
        color: ${({ theme }) => theme.colors.brandThree};
    }

    &:not(:last-child) {
        margin: 0 4rem 0 0;
    }

    ${(props) => props.active && `color: ${props.theme.colors.brandThree};`}
`;

const FooterWrapper = styled.footer`
    height: 8rem;
    margin-top: auto;

    > div:first-of-type {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
            display: grid;
            grid-template-columns: repeat(2, auto);
            gap: 4rem;
        }

        p,
        span,
        a {
            color: ${({ theme }) => theme.colors.lightGrey};
            font-size: 1.6rem;
            font-weight: 400;
            cursor: pointer;
        }
    }

    @media (max-width: 1000px) {
        > div:first-of-type {
            > div {
                gap: 3rem;
            }

            p,
            span,
            a {
                font-size: 1.5rem;
            }
        }
    }

    @media (max-width: 650px) {
        height: auto;

        > div:first-of-type {
            padding: 2rem 0;

            p > span {
                display: none;
            }

            > div {
                gap: 2rem;
            }
        }
    }

    @media (max-width: 360px) {
        > div:first-of-type {
            display: grid;
            grid-template-columns: 1fr;
            gap: 2rem;
            align-items: center;
            text-align: center;

            > div   {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
`;

const Breadcrumb = styled.div`
    margin: 4rem 0;

    h4 {
        display: flex;
        gap: 1rem;
    }
`;

export {
    Navbar,
    NavbarWrapper,
    NavbarRight,
    Profile,
    NavbarMenu,
    NavLink,
    FooterWrapper,
    Breadcrumb
};
