import React, {useEffect, useState} from 'react';
import {DefaultTheme, withTheme} from 'styled-components';
import shortenLabel from '../../util/shortenLabel';
import {Artist, ChartStyles, MonitorData, MonitorFilter, SongPlayCount} from '../../util/types';
import BarChart from '../charts/BarChart';
import LineChart from '../charts/LineChart';
import Number from '../charts/Number';
import Trend from '../charts/Trend';
import {
    ChartGrid,
    ContentWrapper,
    FullWidth,
    Heading2,
    NoData,
    TwoNumbers,
} from '../styled';
import fetchSongData from "../../api/fetchSongData";
import intervalToDateBoundaries from "../../util/intervalToDate";

interface MonitorChartsProps {
    data: MonitorData;
    filter: MonitorFilter;
    theme: DefaultTheme;
    smallScreen: boolean;
    artist: Artist
}

const MonitorCharts: React.FC<MonitorChartsProps> =
    ({
         data,
         filter,
         theme,
         smallScreen,
         artist
     }) => {

        const chartStyles: ChartStyles = {
            fontFamily: theme.fontFamily,
            black: theme.colors.black,
            white: theme.colors.white,
            lightGrey: theme.colors.lightGrey,
            darkGrey: theme.colors.darkGrey,
            accent: theme.colors.brandThree,
            brand: [
                theme.colors.brandOne,
                theme.colors.brandOneAccent2,
                theme.colors.brandOneAccent3,
                theme.colors.brandOneAccent4
            ]
        };

        const {total, youtube, deezer, spotify, amazon, apple, kpi} = data;

        // Determine the biggest dataset to pad linecharts with zeros
        let biggestDataset = deezer || [];

        if (spotify && spotify.length > biggestDataset.length) biggestDataset = spotify;
        if (amazon && amazon.length > biggestDataset.length) biggestDataset = amazon;
        if (apple && apple.length > biggestDataset.length) biggestDataset = apple;

        let total_streams: number = 0;
        let total_listeners: number = 0;

        spotify?.forEach((obj) => {
            total_streams += obj.plays;
            total_listeners += obj.listeners;
        });
        amazon?.forEach((obj) => {
            total_streams += obj.plays;
            total_listeners += obj.listeners;
        });
        deezer?.forEach((obj) => {
            total_streams += obj.plays;
            total_listeners += obj.listeners;
        });
        apple?.forEach((obj) => {
            total_streams += obj.plays;
            total_listeners += obj.listeners;
        });

        const fetchSongDataWrapper = async (artist_id: string, dsp_name: string, interval: MonitorFilter): Promise<void> => {
            try {
                let [start_interval, end_interval] = intervalToDateBoundaries(interval)

                switch (dsp_name) {
                    case 'spotify':
                        setSpotifySongData(await fetchSongData(artist_id, dsp_name, start_interval, end_interval));
                        break;
                    case 'amazon':
                        setAmazonSongData(await fetchSongData(artist_id, dsp_name, start_interval, end_interval));
                        break;
                    case 'apple':
                        setAppleSongData(await fetchSongData(artist_id, dsp_name, start_interval, end_interval));
                        break;
                }
            } catch (e) {
                console.log('An unexpected error occurred, please try reloading the page or contacting us.');
            }
        }

        const [amazonSongData, setAmazonSongData] = useState<SongPlayCount[]>();
        const [appleSongData, setAppleSongData] = useState<SongPlayCount[]>();
        const [spotifySongData, setSpotifySongData] = useState<SongPlayCount[]>();

        // Filters for track lists spotifyTrackFilter
        const [spotifyTrackFilter, setSpotifyTrackFilter] = useState<string>("");
        const [appleTrackFilter, setAppleTrackFilter] = useState<string>("");
        const [amazonTrackFilter, setAmazonTrackFilter] = useState<string>("");

        const trimToMaxLength = (input: string, maxLength: number = 30) => {
            return input.substring(0, Math.min(input.length, maxLength)) + (input.length > maxLength ? '...' : '')
        }

        useEffect(() => {
            if (
                artist &&
                artist.services.indexOf('MONITOR') !== -1
            ) {
                if (spotify) fetchSongDataWrapper(artist.artist_id, 'spotify', filter)
                if (amazon) fetchSongDataWrapper(artist.artist_id, 'amazon', filter)
                if (apple) fetchSongDataWrapper(artist.artist_id, 'apple', filter)
            }

        }, [data, filter, artist, spotify, amazon, apple])


        const getFilteredSongData = (songData: SongPlayCount[], filter: string): SongPlayCount[] => {
            let tmp = songData.filter(item => {
                return item.title.toLowerCase().includes(filter.toLowerCase())
            })
            return tmp
        }

        return (
            <ContentWrapper>
                <ChartGrid>
                    <FullWidth>
                        <Heading2>Overview</Heading2>
                    </FullWidth>

                    {kpi && total ? (
                        <>
                            <Number
                                title="Total Plays"
                                description="The sum of play counts across DSPs"
                                explanation="How many times your music was streamed on leading streaming platforms altogether."
                                value={total_streams}
                                unit=" "
                            />

                            <Number
                                title="Total Listeners"
                                description="The sum of play counts across DSPs"
                                explanation="How many people listened to your music, whereas one person can have multiple accounts."
                                value={total_listeners}
                                unit=" "
                            />

                            <Number
                                title="Total YouTube Views"
                                description="The sum of video views on YouTube"
                                explanation="How many times people watched videos that included your music."
                                value={
                                    youtube && youtube.length
                                        ? youtube[youtube.length - 1].total_views
                                        : 0
                                }
                                unit=" "
                            />

                            {window.innerWidth <= 1100 && (
                                <Number
                                    title="Average Plays per Listener"
                                    description="Plays per listeners"
                                    explanation="How many songs a person listens to on average within the selected time period."
                                    value={kpi.avg_ppl}
                                    unit=" "
                                />
                            )}

                            <FullWidth columns={3}>
                                <LineChart
                                    title1="Total Streams"
                                    description1="..."
                                    unit1=" "
                                    title2="Total Listeners"
                                    description2="..."
                                    unit2=" "
                                    explanation="Your daily streams and listeners over time."
                                    labels={total.map((obj) =>
                                        new Date(obj.date).toISOString().slice(0, 10)
                                    )}
                                    values1={[
                                        filter === 'All time'
                                            ? biggestDataset
                                                .filter(
                                                    (_v, i) =>
                                                        i < biggestDataset.length - total.length - 1
                                                )
                                                .map(() => 0)
                                                .concat(total.map((obj) => obj.plays))
                                            : total.map((obj) => obj.plays)
                                    ]}
                                    values2={[
                                        filter === 'All time'
                                            ? biggestDataset
                                                .filter(
                                                    (_v, i) =>
                                                        i < biggestDataset.length - total.length - 1
                                                )
                                                .map(() => 0)
                                                .concat(total.map((obj) => obj.listeners))
                                            : total.map((obj) => obj.listeners)
                                    ]}
                                    chartStyles={chartStyles}
                                    enableTooltips
                                />
                            </FullWidth>

                            {window.innerWidth > 1100 && (
                                <Number
                                    title="Average Streams per Listener"
                                    description="Plays per listeners"
                                    explanation="How many songs a person listens to on average within the selected time period."
                                    value={kpi.avg_ppl}
                                    unit=" "
                                />
                            )}

                            <Trend
                                title="Streams Trend"
                                description="Development of play counts across DSPs"
                                explanation="Whether the number of streams increases or rather decreases (on a scale from -1 to 1)."
                                value={kpi.play_trend}
                            />

                            <Trend
                                title="Listeners Trend"
                                description="Development of listeners across DSPs"
                                explanation="Whether the number of people listening to your music rather increases or decreases (on a scale from -1 to 1)."
                                value={kpi.listeners_trend}
                            />
                        </>
                    ) : (
                        <FullWidth>
                            <NoData>No data available in this time frame</NoData>
                        </FullWidth>
                    )}
                </ChartGrid>

                <ChartGrid id="youtube">
                    <FullWidth>
                        <Heading2>YouTube</Heading2>
                    </FullWidth>

                    {youtube && youtube.length > 0 ? (

                        <>
                            <Number
                                title="Total Views"
                                description="Aggregated view counts of all videos identified"
                                explanation="How many times your music was played when watching a video."
                                value={youtube[youtube.length - 1].total_views}
                                unit=" "
                            />

                            <Number
                                title="Official Views"
                                description="Views from official sources"
                                explanation="How many of your total views come from official sources."
                                value={youtube[youtube.length - 1].official_views}
                                unit=" "
                            />

                            <Number
                                title="UGC Views"
                                description="Views from user-generated content"
                                explanation="How many of your total views come from user-generated content."
                                value={youtube[youtube.length - 1].ugc_views}
                                unit=" "
                            />

                            {/* <Number
                            title="Total Videos"
                            description="Number of unique videos identified"
                            explanation="How many videos contain your original music."
                            value={youtube[youtube.length - 1].total_videos}
                            unit=" "
                            secondary
                        />

                        <PieChart
                            title="Official vs. UGC Views"
                            description="Share of views by video type"
                            explanation="How many views are generated from official music videos vs user-generated content."
                            unit=" "
                            values={[
                                youtube[youtube.length - 1].official_views,
                                youtube[youtube.length - 1].ugc_views
                            ]}
                            labels={['Official views', 'UGC views']}
                            noTooltipTitles
                            chartStyles={chartStyles}
                        />

                        <PieChart
                            title="Official vs. UGC Videos"
                            description="Share of videos by video type"
                            explanation="How often your music was used in official music videos vs user-generated content."
                            unit=" "
                            values={[
                                youtube[youtube.length - 1].official_videos,
                                youtube[youtube.length - 1].ugc_videos
                            ]}
                            labels={['Official videos', 'UGC videos']}
                            noTooltipTitles
                            chartStyles={chartStyles}
                        /> */}

                            <FullWidth>
                                <BarChart
                                    title="Top Titles"
                                    description="View counts by official and licensed tracks"
                                    explanation="Titles that are identified by YouTube’s Content ID in the order of views."
                                    values={[
                                        youtube[youtube.length - 1].top_titles
                                            .sort((a, b) => b.views - a.views)
                                            .map((title) => title.views)
                                    ]}
                                    unit=" "
                                    labels={youtube[youtube.length - 1].top_titles
                                        .sort((a, b) => b.views - a.views)
                                        .map((title) => title.title)
                                        .map((label) => shortenLabel(label))}
                                    horizontal
                                    smallScreen={smallScreen}
                                    chartStyles={chartStyles}
                                />
                            </FullWidth>
                        </>
                    ) : (
                        <FullWidth>
                            <NoData>No data available in this time frame</NoData>
                        </FullWidth>
                    )}
                </ChartGrid>

                <ChartGrid id="spotify">
                    <FullWidth>
                        <Heading2>Spotify</Heading2>
                    </FullWidth>

                    {spotify && spotify.length > 0 ? (
                        <>
                            <TwoNumbers>
                                <Number
                                    title="Total Plays"
                                    description="Aggregated play counts"
                                    explanation="How often your music was streamed in total."
                                    value={spotify.reduce((a, b) => a + b.plays, 0)}
                                    unit=" "
                                />

                                <Number
                                    title="Daily Listeners"
                                    description="Average number of unique listeners per day"
                                    explanation="How many people listen to your music on a daily basis."
                                    value={
                                        spotify.reduce((a, b) => a + b.listeners, 0) / spotify.length
                                    }
                                    unit=" "
                                    secondary
                                />
                            </TwoNumbers>

                            <FullWidth columns={2}>
                                <LineChart
                                    title1="Plays"
                                    description1="..."
                                    unit1=" "
                                    title2="Listeners"
                                    description2="..."
                                    unit2=" "
                                    explanation="The respective daily values plotted over time."
                                    labels={biggestDataset.map((obj) =>
                                        new Date(obj.date).toISOString().slice(0, 10)
                                    )}
                                    values1={[
                                        filter === 'All time'
                                            ? biggestDataset
                                                .filter(
                                                    (_v, i) =>
                                                        i < biggestDataset.length - spotify.length - 1
                                                )
                                                .map(() => 0)
                                                .concat(spotify.map((obj) => obj.plays))
                                            : spotify.map((obj) => obj.plays)
                                    ]}
                                    values2={[
                                        filter === 'All time'
                                            ? biggestDataset
                                                .filter(
                                                    (_v, i) =>
                                                        i < biggestDataset.length - spotify.length - 1
                                                )
                                                .map(() => 0)
                                                .concat(spotify.map((obj) => obj.listeners))
                                            : spotify.map((obj) => obj.listeners)
                                    ]}
                                    chartStyles={chartStyles}
                                    enableTooltips
                                />
                            </FullWidth>
                            <>
                                {spotify && spotifySongData && (
                                    <FullWidth>
                                        <BarChart
                                            title="Top Titles"
                                            description="The aggregated play counts of the artist"
                                            explanation="The total plays of the artist's most important tracks aggregated for the selected time interval."
                                            values={[getFilteredSongData(spotifySongData, spotifyTrackFilter).map(item => item.total_plays)]}
                                            unit=" "
                                            labels={getFilteredSongData(spotifySongData, spotifyTrackFilter).map(item => trimToMaxLength(item.title))}
                                            horizontal
                                            smallScreen={smallScreen}
                                            chartStyles={chartStyles}
                                            setFilter={setSpotifyTrackFilter}
                                        />
                                    </FullWidth>
                                )}
                            </>
                        </>
                    ) : (
                        <FullWidth>
                            <NoData>No data available in this time frame</NoData>
                        </FullWidth>
                    )}
                </ChartGrid>

                <ChartGrid id="apple">
                    <FullWidth>
                        <Heading2>Apple</Heading2>
                    </FullWidth>

                    {apple && apple.length > 0 ? (
                        <>
                            <TwoNumbers>
                                <Number
                                    title="Total Plays"
                                    description="Aggregated play counts"
                                    explanation="How often your music was streamed in total."
                                    value={apple.reduce((a, b) => a + b.plays, 0)}
                                    unit=" "
                                />

                                <Number
                                    title="Daily Listeners"
                                    description="Average number of unique listeners per day"
                                    explanation="How many people listen to your music on a daily basis."
                                    value={apple.reduce((a, b) => a + b.listeners, 0) / apple.length}
                                    unit=" "
                                    secondary
                                />
                            </TwoNumbers>

                            <FullWidth columns={2}>
                                <LineChart
                                    title1="Plays"
                                    description1="..."
                                    unit1=" "
                                    title2="Listeners"
                                    description2="..."
                                    unit2=" "
                                    explanation="The respective daily values plotted over time."
                                    labels={biggestDataset.map((obj) =>
                                        new Date(obj.date).toISOString().slice(0, 10)
                                    )}
                                    values1={[
                                        filter === 'All time'
                                            ? biggestDataset
                                                .filter(
                                                    (_v, i) =>
                                                        i < biggestDataset.length - apple.length - 1
                                                )
                                                .map(() => 0)
                                                .concat(apple.map((obj) => obj.plays))
                                            : apple.map((obj) => obj.plays)
                                    ]}
                                    values2={[
                                        filter === 'All time'
                                            ? biggestDataset
                                                .filter(
                                                    (_v, i) =>
                                                        i < biggestDataset.length - apple.length - 1
                                                )
                                                .map(() => 0)
                                                .concat(apple.map((obj) => obj.listeners))
                                            : apple.map((obj) => obj.listeners)
                                    ]}
                                    chartStyles={chartStyles}
                                    enableTooltips
                                />
                            </FullWidth>
                            <>
                                {apple && appleSongData && (
                                    <FullWidth>
                                        <BarChart
                                            title="Top Titles"
                                            description="The aggregated play counts of the artist"
                                            explanation="The total plays of the artist's most important tracks aggregated for the selected time interval."
                                            values={[getFilteredSongData(appleSongData, appleTrackFilter).map(item => item.total_plays)]}
                                            unit=" "
                                            labels={getFilteredSongData(appleSongData, appleTrackFilter).map(item => trimToMaxLength(item.title))}
                                            horizontal
                                            smallScreen={smallScreen}
                                            chartStyles={chartStyles}
                                            setFilter={setAppleTrackFilter}
                                        />
                                    </FullWidth>
                                )}
                            </>
                        </>
                    ) : (
                        <FullWidth>
                            <NoData>No data available in this time frame</NoData>
                        </FullWidth>
                    )}
                </ChartGrid>

                <ChartGrid id="amazon">
                    <FullWidth>
                        <Heading2>Amazon</Heading2>
                    </FullWidth>

                    {amazon && amazon.length > 0 ? (
                        <>
                            <TwoNumbers>
                                <Number
                                    title="Total Plays"
                                    description="Aggregated play counts"
                                    explanation="How often your music was streamed in total."
                                    value={amazon.reduce((a, b) => a + b.plays, 0)}
                                    unit=" "
                                />

                                <Number
                                    title="Daily Listeners"
                                    description="Average number of unique listeners per day"
                                    explanation="How many people listen to your music on a daily basis."
                                    value={amazon.reduce((a, b) => a + b.listeners, 0) / amazon.length}
                                    unit=" "
                                    secondary
                                />
                            </TwoNumbers>

                            <FullWidth columns={2}>
                                <LineChart
                                    title1="Plays"
                                    description1="..."
                                    unit1=" "
                                    title2="Listeners"
                                    description2="..."
                                    unit2=" "
                                    explanation="The respective daily values plotted over time."
                                    labels={biggestDataset.map((obj) =>
                                        new Date(obj.date).toISOString().slice(0, 10)
                                    )}
                                    values1={[
                                        filter === 'All time'
                                            ? biggestDataset
                                                .filter(
                                                    (_v, i) =>
                                                        i < biggestDataset.length - amazon.length - 1
                                                )
                                                .map(() => 0)
                                                .concat(amazon.map((obj) => obj.plays))
                                            : amazon.map((obj) => obj.plays)
                                    ]}
                                    values2={[
                                        filter === 'All time'
                                            ? biggestDataset
                                                .filter(
                                                    (_v, i) =>
                                                        i < biggestDataset.length - amazon.length - 1
                                                )
                                                .map(() => 0)
                                                .concat(amazon.map((obj) => obj.listeners))
                                            : amazon.map((obj) => obj.listeners)
                                    ]}
                                    chartStyles={chartStyles}
                                    enableTooltips
                                />
                            </FullWidth>
                            <>
                                {amazon && amazonSongData && (
                                    <FullWidth>
                                        <BarChart
                                            title="Top Titles"
                                            description="The aggregated play counts of the artist"
                                            explanation="The total plays of the artist's most important tracks aggregated for the selected time interval."
                                            values={[getFilteredSongData(amazonSongData, amazonTrackFilter).map(item => item.total_plays)]}
                                            unit=" "
                                            labels={getFilteredSongData(amazonSongData, amazonTrackFilter).map(item => trimToMaxLength(item.title))}
                                            horizontal
                                            smallScreen={smallScreen}
                                            chartStyles={chartStyles}
                                            setFilter={setAmazonTrackFilter}
                                        />
                                    </FullWidth>
                                )}
                            </>
                        </>
                    ) : (
                        <FullWidth>
                            <NoData>No data available in this time frame</NoData>
                        </FullWidth>
                    )}
                </ChartGrid>

                <ChartGrid id="deezer">
                    <FullWidth>
                        <Heading2>Deezer</Heading2>
                    </FullWidth>

                    {deezer && deezer.length > 0 ? (
                        <>
                            <TwoNumbers>
                                <Number
                                    title="Total Plays"
                                    description="Aggregated play counts"
                                    explanation="How often your music was streamed in total."
                                    value={deezer.reduce((a, b) => a + b.plays, 0)}
                                    unit=" "
                                />

                                <Number
                                    title="Daily Listeners"
                                    description="Average number of unique listeners per day"
                                    explanation="How many people listen to your music on a daily basis."
                                    value={deezer.reduce((a, b) => a + b.listeners, 0) / deezer.length}
                                    unit=" "
                                    secondary
                                />
                            </TwoNumbers>

                            <FullWidth columns={2}>
                                <LineChart
                                    title1="Plays"
                                    description1="..."
                                    unit1=" "
                                    title2="Listeners"
                                    description2="..."
                                    unit2=" "
                                    explanation="The respective daily values plotted over time."
                                    labels={biggestDataset.map((obj) =>
                                        new Date(obj.date).toISOString().slice(0, 10)
                                    )}
                                    values1={[
                                        filter === 'All time'
                                            ? biggestDataset
                                                .filter(
                                                    (_v, i) =>
                                                        i < biggestDataset.length - deezer.length - 1
                                                )
                                                .map(() => 0)
                                                .concat(deezer.map((obj) => obj.plays))
                                            : deezer.map((obj) => obj.plays)
                                    ]}
                                    values2={[
                                        filter === 'All time'
                                            ? biggestDataset
                                                .filter(
                                                    (_v, i) =>
                                                        i < biggestDataset.length - deezer.length - 1
                                                )
                                                .map(() => 0)
                                                .concat(deezer.map((obj) => obj.listeners))
                                            : deezer.map((obj) => obj.listeners)
                                    ]}
                                    chartStyles={chartStyles}
                                    enableTooltips
                                />
                            </FullWidth>
                        </>
                    ) : (
                        <FullWidth>
                            <NoData>No data available in this time frame</NoData>
                        </FullWidth>
                    )}
                </ChartGrid>
            </ContentWrapper>
        );
    }
;

export default withTheme(MonitorCharts);
