import styled from 'styled-components';
import brandColorByCategory from '../../util/brandColorByCategory';

const ExplanationWrapper = styled.div`
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 1;

    p {
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.lightGrey}80;
        height: 2.2rem;
        width: 2.2rem;
        border-radius: 50%;
        text-align: center;
        font-size: 1.5rem;
        font-weight: 500;
        padding: -1px -1px 0 0;
        cursor: pointer;
    }

    div {
        position: absolute;
        top: 3rem;
        right: 0;
        width: 20rem;
        padding: 1.5rem;
        line-height: 1.3;
        font-size: 1.4rem;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.white};
        border-radius: ${({ theme }) => theme.borderRadius1};
        background-color: ${({ theme }) => theme.colors.black};
        box-shadow: ${({ theme }) => theme.boxShadow1};
    }

    @media (max-width: 1300px) {
        top: 1.5rem;
        right: 1.5rem;
    }
`;

const NewBadgeWrapper = styled.div`
    position: absolute;
    top: 2rem;
    right: 6rem;
    z-index: 1;

    p {
        color: ${({ theme }) => theme.colors.black};
        background-color: ${({ theme }) => theme.colors.brandThreeAccent1};
        height: 2.2rem;
        width: 5.2rem;
        border-radius: 20px;
        text-align: center;
        font-size: 1.5rem;
        font-weight: 500;
        padding: -1px -1px 0 0;
    }

    @media (max-width: 1300px) {
        top: 1.5rem;
        right: 6rem;
    }
`;

interface CloseIconProps {
    customImage?: boolean;
    relative?: boolean;
}

const CloseIcon = styled.div<CloseIconProps>`
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
    min-height: 2rem;
    margin-left: 1rem;
    opacity: 0.8;
    cursor: pointer;
    transition: opacity 0.1s;

    &:hover {
        opacity: 1;
    }

    img {
        width: 2rem;
    }

    ${({ theme, customImage }) =>
        !customImage &&
        `
        &::before,
        &::after {
            position: absolute;
            left: 10px;
            content: ' ';
            height: 20px;
            width: 2px;
            border-radius: 3px;
            background-color: ${theme.colors.white};
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    `}

    ${({ relative }) =>
        !relative &&
        `
        position: absolute;
        right: 3rem;
        top: 3rem;
    `}
`;

interface ProductInformationProps {
    show?: boolean;
    category: 1 | 2 | 3;
}

const ProductInformation = styled.div<ProductInformationProps>`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 0;
    background-color: ${({ theme }) => theme.colors.darkGrey};
    border: 1px solid ${({ theme }) => theme.colors.white}10;
    border-radius: ${({ theme }) => theme.borderRadius1};
    box-shadow: ${({ theme }) => theme.boxShadow1};
    width: 100%;
    max-width: 85rem;

    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;

    ${(props) =>
        props.show &&
        `
        visibility: visible;
        opacity: 1;
    `}

    > div:first-of-type {
        display: grid;
        align-items: center;
        padding: 4rem;
        border-radius: ${({ theme }) => theme.borderRadius1};
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        background-color: ${({ category, theme }) => brandColorByCategory(category, theme)};

        background-image: linear-gradient(
            to right bottom,
            ${({ category, theme }) => brandColorByCategory(category, theme)},
            ${({ category, theme }) => brandColorByCategory(category, theme, true)}
        );

        img {
            width: 100%;
        }
    }

    > div:nth-of-type(2) {
        display: grid;
        gap: 2rem;
        padding: 4rem;

        > div:first-of-type {
            > p {
                font-size: 1.6rem;
                font-weight: 500;
                color: ${({ theme }) => theme.colors.white};
                margin-bottom: 0.5rem;
            }

            > span {
                display: block;
                line-height: 1.5;
                margin-bottom: 1.5rem;
            }
        }
    }

    > div:last-of-type {
        position: absolute;
        top: 3rem;
        right: 3rem;
    }

    @media (max-width: 1100px) {
        max-width: 90vw;

        > div:last-of-type {
            top: 2.5rem;
            right: 2.5rem;
        }
    }

    @media (max-width: 700px) {
        grid-template-columns: 1fr;

        > div:first-of-type {
            border-top-right-radius: ${({ theme }) => theme.borderRadius1};
            border-bottom-left-radius: 0;

            img {
                max-width: 25rem;
                margin: 0 auto;
            }
        }

        > div:last-of-type {
            top: 2rem;
            right: 2rem;
        }
    }

    @media (max-width: 550px) {
        > div:first-of-type {
            img {
                max-width: 20rem;
            }
        }

        > div:nth-of-type(2) {
            padding: 3rem;
        }
    }
`;

interface ModalProps {
    wider?: boolean;
}

const Modal = styled.div<ModalProps>`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 45rem;
    background-color: ${({ theme }) => theme.colors.darkGrey};
    border: 1px solid ${({ theme }) => theme.colors.white}10;
    border-radius: ${({ theme }) => theme.borderRadius1};
    box-shadow: ${({ theme }) => theme.boxShadow1};

    ${({ wider }) =>
        wider &&
        `
        max-width: 70rem;
    `}

    > span {
        display: block;
        margin: 2.5rem 0;
        border-bottom: ${({ theme }) => theme.border};
    }

    @media (max-width: 800px) {
        > span {
            margin: 2rem 0;
        }
    }
`;

const UpcomingFeatures = styled(Modal)`
    width: 100%;
    max-width: 85rem;
    padding: 4rem;

    h3 {
        margin-bottom: 2rem;
    }

    > div:first-of-type {
        p,
        li {
            font-weight: 400;
            color: ${({ theme }) => theme.colors.white};
            line-height: 1.5;
        }

        ul {
            margin: 2rem 3rem;
        }
    }

    @media (max-width: 1100px) {
        max-width: 90vw;

        > div:last-of-type {
            top: 2.5rem;
            right: 2.5rem;
        }
    }

    @media (max-width: 550px) {
        padding: 3rem;

        > div:last-of-type {
            top: 2rem;
            right: 2rem;
        }
    }
`;

const UploadModal = styled(Modal)`
    width: 100%;
    padding: 2.5rem 0;

    h4 {
        margin: 0;
    }

    > div {
        margin: 0 2.5rem;
        line-height: 1.5;

        &:first-of-type {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        > p {
            display: grid;
            grid-template-columns: 30% 70%;
            gap: 2rem;
            margin: 3rem 0;
            color: ${({ theme }) => theme.colors.white};
            overflow: hidden;

            span {
                color: ${({ theme }) => theme.colors.lightGrey};
            }

            img {
                cursor: pointer;
            }
        }

        > button {
            width: 100%;
        }

        > span {
            display: block;
            text-align: center;
            margin-top: 2rem;
            cursor: pointer;
        }

        > div {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                display: flex;
                align-items: center;
                color: ${({ theme }) => theme.colors.white};
                font-weight: 400;

                img {
                    width: 2rem;
                    margin-right: 1rem;
                    cursor: pointer;
                }
            }
        }
    }

    @media (max-width: 800px) {
        max-width: 90vw;
        padding: 2rem 0;

        > div {
            margin: 0 2rem;
        }
    }
`;

const TrackDetails = styled(UploadModal)``;

const NewDocumentModal = styled(UploadModal)`
    > div {
        > p {
            display: flex;
            align-items: center;
            gap: 1rem;
            margin: 2rem 0;

            img {
                width: 1.75rem;
            }
        }
    }
`;

const MissingAudioModalWrapper = styled(UploadModal)`
    > div:last-of-type {
        max-height: 40vh;
        overflow-y: scroll;

        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        table {
            margin: 0;

            tbody {
                tr {
                    grid-template-columns: auto 12rem 3rem;

                    td {
                        color: ${({ theme }) => theme.colors.white};
                        padding: 0 !important;
                        height: 5rem;
                        cursor: default;

                        &:first-of-type {
                            padding-right: 1.5rem !important;
                        }
                    }
                }
            }
        }

        @media (max-width: 750px) {
            table {
                tbody {
                    tr {
                        grid-template-columns: auto 3rem;

                        td {
                            &:nth-of-type(2) {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
`;

const ReleasePreviewWrapper = styled.div`
    > div:first-of-type {
        display: grid;
        grid-template-columns: auto 15rem;
        gap: 1.5rem;
        align-items: start;

        > div > p:first-of-type {
            display: inline-block;
            margin: 1rem 0 0 0;
            color: ${({ theme }) => theme.colors.lightGrey};

            > span {
                display: inline-block;

                &:not(:last-child) {
                    margin-right: 0.5rem;
                }
            }
        }
    }

    > div:nth-of-type(2) {
        flex-direction: column;
        align-items: stretch;
        gap: 4rem;
        margin: 2rem 0;
        max-height: 40vh;
        overflow-y: scroll;

        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        h4 {
            margin-bottom: 1rem;
        }

        div:first-of-type h4 {
            margin-top: 1rem;
        }

        table {
            margin: 0;

            tr {
                grid-template-columns: 3rem auto 8rem;
            }

            td {
                padding-left: 1rem;
                height: 5.5rem;
                cursor: default;
            }
        }
    }

    > div:last-of-type {
        display: flex;
        justify-content: flex-end;
        gap: 1.5rem;
        margin-top: 3rem;
    }
`;

interface UploadReleaseProps {
    coverUrl?: string;
}

const UploadRelease = styled(Modal)<UploadReleaseProps>`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 2.5rem 0;

    > div {
        margin: 0 2.5rem;

        &:first-of-type {
            width: 40rem;
            height: 40rem;
            background-color: ${({ theme }) => theme.colors.lightGrey}60;

            ${({ coverUrl }) =>
                coverUrl &&
                `
                    background-image: url(${coverUrl});
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
            `}
        }

        &:nth-of-type(2) {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                display: flex;
                align-items: center;
                color: ${({ theme }) => theme.colors.white};
                font-weight: 400;

                img {
                    width: 2rem;
                    margin-right: 1rem;
                    cursor: pointer;
                }
            }
        }
    }

    > span {
        display: block;
        margin: 2rem 0;
        border-bottom: ${({ theme }) => theme.border};
    }

    > button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 2.5rem;
    }

    @media (max-width: 550px) {
        padding: 2rem 0;

        > div:first-of-type {
            width: 70vw;
            height: 70vw;
            margin: 0 2rem;
        }

        button {
            margin: 0 2rem;
        }
    }
`;

const ChangeRequest = styled(Modal)`
    width: 100%;
    max-width: 50rem;
    padding: 2.5rem 0;

    textarea,
    input {
        width: 100%;
        margin: 1.5rem 0;
    }

    button {
        justify-self: flex-end;
    }

    > div,
    form {
        margin: 0 2.5rem;

        > p {
            margin: 2rem 0;
        }

        > div:first-of-type {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 2rem;

            p {
                cursor: pointer;
            }
        }
    }

    @media (max-width: 600px) {
        max-width: 90vw;
        padding: 2rem 0;

        > div,
        form {
            margin: 0 2rem;
        }
    }
`;

const ListItemOptions = styled.div`
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: ${({ theme }) => theme.colors.darkGrey};
    border: 1px solid ${({ theme }) => theme.colors.white}10;
    border-radius: ${({ theme }) => theme.borderRadius1};
    box-shadow: ${({ theme }) => theme.boxShadow1};
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    z-index: 2;

    > div {
        width: 100%;
        display: grid;
        grid-template-columns: 2rem auto;
        gap: 1rem;
        justify-content: start;
        align-items: center;
        cursor: pointer;

        > p {
            color: ${({ theme }) => theme.colors.white};
            min-width: 8.5rem;
        }

        img {
            width: 1.75rem;
        }
    }
`;

const RenameModal = styled(ChangeRequest)`
    max-width: 45rem;
    padding: 2rem 0;

    > div,
    form {
        margin: 0 2rem;
    }

    h4 {
        margin: 0;
    }
`;

export {
    ExplanationWrapper,
    NewBadgeWrapper,
    CloseIcon,
    ProductInformation,
    UpcomingFeatures,
    TrackDetails,
    NewDocumentModal,
    ReleasePreviewWrapper,
    UploadRelease,
    MissingAudioModalWrapper,
    ChangeRequest,
    ListItemOptions,
    RenameModal
};
