// Convert file to base64 string
const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result?.toString() || '');
    });
};

export default convertFileToBase64;
