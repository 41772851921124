import styled from 'styled-components';
import brandColorByCategory from '../../util/brandColorByCategory';

interface HeaderWrapperProps {
    category: number;
    scroll?: boolean;
}

const HeaderWrapper = styled.header<HeaderWrapperProps>`
    position: relative;
    height: 45rem;
    width: 100%;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    background-color: ${({ category, theme }) => brandColorByCategory(category, theme)};
    background-image: linear-gradient(
        to right bottom,
        ${({ category, theme }) => brandColorByCategory(category, theme)},
        ${({ category, theme }) => brandColorByCategory(category, theme, true)}
    );

    @media (max-width: 750px) {
        padding: 8rem 0 4rem 0;
        height: auto;
        justify-content: flex-start;
    }
`;

const HeaderMain = styled.div`
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 9;
    transition: all 0.2s;

    > div {
        height: auto;
        margin-top: 4rem;

        > div:first-child {
            max-width: 55%;

            p {
                color: ${({ theme }) => theme.colors.white};
                font-weight: 300;
            }
        }

        > div:not(:first-child) {
            position: absolute;
            top: 0;
            right: 0;

            > div {
                margin: 0;
            }
        }
    }

    @media (max-width: 1100px) {
        > div {
            > div:first-child {
                max-width: 70%;
            }
        }
    }

    @media (max-width: 900px) {
        > div {
            > div:first-child {
                max-width: 100%;
            }
        }
    }

    @media (max-width: 750px) {
        padding: 5rem 0;

        > div {
            margin-top: 0;
        }
    }

    @media (max-width: 500px) {
        padding: 5rem 0 2rem 0;

        > div {
            > div:not(:first-child) {
                position: relative;
                top: auto;
                right: auto;
                padding-top: 3rem;

                select {
                    width: 100%;
                }
            }
        }
    }
`;

interface HeaderBottomProps {
    navbar?: boolean;
}

const HeaderBottom = styled.div<HeaderBottomProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8rem;
    border-top: ${({ theme }) => theme.border};

    @media (max-width: 750px) {
        display: none;
    }

    ${(props) =>
        props.navbar &&
        `
        position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        border-top: none;
        z-index: 14;

        @media (max-width: 1000px) {
            display: none;
        }
    `}
`;

const SettingsHeader = styled.div`
    border-bottom: ${({ theme }) => theme.border};

    > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2.5rem 0;
    }
`;

interface DashboardHeaderProps {
    artistImg: string;
}

const DashboardHeader = styled.div<DashboardHeaderProps>`
    margin: 16rem 0 8rem 0;

    h1 {
        display: flex;
        align-items: center;

        div {
            height: 12rem;
            width: 12rem;
            min-height: 12rem;
            min-width: 12rem;
            margin-right: 3rem;
            background-color: ${({ theme }) => theme.colors.white};
            background-image: url(${(props) => props.artistImg});
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 50%;
        }
    }

    h4 {
        margin-bottom: 3rem;
    }

    @media (max-width: 1400px) {
        h1 {
            div {
                height: 10rem;
                width: 10rem;
                min-height: 10rem;
                min-width: 10rem;
            }
        }
    }

    @media (max-width: 900px) {
        margin: 12rem 0 6rem 0;

        h4 {
            display: none;
        }
    }

    @media (max-width: 500px) {
        h1 {
            div {
                height: 8rem;
                width: 8rem;
                min-height: 8rem;
                min-width: 8rem;
            }
        }
    }
`;

interface ReleaseHeaderProps {
    coverUrl?: string;
}

const ReleaseHeader = styled.div<ReleaseHeaderProps>`
    display: flex;
    padding-bottom: 5rem;

    > div:first-of-type {
        position: relative;
        height: 20rem;
        width: 20rem;
        min-height: 20rem;
        min-width: 20rem;
        border-radius: ${({ theme }) => theme.borderRadius1};
        margin-right: 4rem;
        background-color: ${({ theme }) => theme.colors.lightGrey}60;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        overflow: hidden;

        ${({ coverUrl }) =>
            coverUrl &&
            `
            > div {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: url(${coverUrl});
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                z-index: 1;
            }
        `}

        img {
            z-index: 0;
            height: 4rem;
            opacity: 0.75;
        }
    }

    > div:last-of-type {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        h1 {
            font-family: ${({ theme }) => theme.fontFamily};
            margin-bottom: 2rem;
        }

        p {
            span {
                &:not(:last-child) {
                    padding-right: 5px;
                }
            }
        }

        b {
            display: inline-block;
            margin-top: 2rem;
            font-size: 1.6rem;
            font-weight: 500;
            cursor: pointer;
            padding: 0.75rem 1.5rem;
            background-color: ${({ theme }) => theme.colors.white}20;
            border-radius: ${({ theme }) => theme.borderRadius1};
        }
    }

    @media (max-width: 700px) {
        > p {
            display: none;
        }

        display: grid;
        gap: 3rem;
        padding: 0 0 3rem 0;

        > div:first-of-type {
            margin: 0 auto;
            width: 25rem;
            height: 25rem;
        }
    }
`;

const ServiceStatementHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    > div:last-of-type {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: ${({ theme }) => theme.colors.white};

        p {
            font-weight: 500;
        }

        h4 {
            margin: 0;
        }
    }

    @media (max-width: 500px) {
        flex-direction: column-reverse;

        > div:last-of-type {
            margin-bottom: 3rem;
        }
    }
`;

export {
    HeaderWrapper,
    HeaderMain,
    HeaderBottom,
    SettingsHeader,
    DashboardHeader,
    ReleaseHeader,
    ServiceStatementHeader
};
