import styled from 'styled-components';

const FullscreenOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(2px);
  background-color: ${({theme}) => theme.colors.darkGrey}A0;
  z-index: 15;
  transition: all 0.2s;
`;

const LoadingButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface LoadingWrapperProps {
    fullScreen?: boolean;
}

const LoadingWrapper = styled.div<LoadingWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.black};
  padding: 15rem 0;
  width: 100%;
  z-index: 200;

  ${({fullScreen}) =>
          fullScreen &&
          `
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    `}
`;

const ErrorWrapper = styled(LoadingWrapper)`
  flex-direction: column;
  text-align: center;

  span {
    font-size: 4rem;
    font-weight: 500;
    margin-bottom: 2.5rem;
    color: ${({theme}) => theme.colors.white};
  }
`;

interface InfoMessageProps {
    error?: boolean;
    success?: boolean;
    marginTop?: boolean;
}

const InfoMessage = styled.div<InfoMessageProps>`
  background-color: ${(props) =>
          props.error ? props.theme.colors.brandOne + '80' : props.theme.colors.white + '20'};
  border-radius: ${({theme}) => theme.borderRadius1};
  color: ${({theme}) => theme.colors.white};
  font-size: 1.6rem;
  line-height: 1.5;
  padding: 1.25rem 2rem;

  ${({marginTop}) => marginTop && `margin-top: 1.5rem !important;`}
`;

interface StatusLabelProps {
    primary?: boolean;
    success?: boolean;
}

const StatusLabel = styled.span<StatusLabelProps>`
  color: ${({theme}) => theme.colors.white};
  padding: 4px 1.2rem;
  font-size: 1.5rem;
  font-weight: 500;
  border-radius: 6px;
  background-color: ${({primary, success, theme}) =>
          primary ? theme.colors.brandTwo : success ? theme.colors.green : theme.colors.lightGrey};
`;

const BackButton = styled.div`
  display: flex;
  align-items: center;
  margin: 12rem 0 4rem 0;
  transition: all 0.2s;
  cursor: pointer;

  img {
    transform: scaleX(-1);
    margin-right: 1rem;
    height: 1em;
  }

  p {
    display: inline-block;
  }

  &:hover {
    color: ${({theme}) => theme.colors.white}D0;
  }

  @media (max-width: 1200px) {
    margin: 10rem 0 4rem 0;
  }
`;

const ProgressBar = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2px;
  margin: 3rem 0;

  span,
  i {
    display: block;
    width: 100%;
    height: 10px;
    border-radius: 2px;
    background-color: ${({theme}) => theme.colors.brandTwo};
  }

  i {
    background-color: ${({theme}) => theme.colors.lightGrey}80;
  }
`;

const PDFWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 90vh;
  width: 100%;
  max-height: 1160px;
  max-width: 900px;
  background-color: ${({theme}) => theme.colors.white};
  overflow-y: scroll;
  overflow-x: hidden;

  @media (max-width: 1000px) {
    max-width: 90vw;
  }
`;

const PDFControls = styled.div`
  position: fixed;
  bottom: 5rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    width: 4rem;
    background-color: ${({theme}) => theme.colors.black}D0;
    cursor: pointer;

    img {
      height: 2rem;
    }

    &:first-of-type img {
      transform: scaleX(-100%);
    }
  }
`;


const TooltipText = styled.div`
  cursor: pointer;
`;
const TooltipBox = styled.div`
  visibility: hidden;
  position: absolute;
  top: -5px;
`;
const TooltipCard = styled.div`
  & ${TooltipText}:hover + ${TooltipBox} {
    visibility: visible;
    background-color: ${({theme}) => theme.colors.brandThree};
    color: ${({theme}) => theme.colors.black};
    padding: 1rem 1.2rem;
    border-radius: ${({theme}) => theme.borderRadius1};
  }
`;

export {
    FullscreenOverlay,
    LoadingButton,
    LoadingWrapper,
    ErrorWrapper,
    InfoMessage,
    StatusLabel,
    BackButton,
    ProgressBar,
    PDFWrapper,
    PDFControls,
    TooltipBox,
    TooltipCard,
    TooltipText
};
