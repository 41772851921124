import { version } from '../../package.json';
import { CachedSettlementData, SettlementData } from '../util/types';
import validateSettlementData from '../util/validateSettlementData';

const fetchInsightData = async (
    artist_id: string,
    settlements?: string[],
    filter?: boolean
): Promise<SettlementData | CachedSettlementData> => {
    try {
        // Determine the relevant settlement period
        let settlementsKey: string;

        if (!filter) settlementsKey = localStorage.getItem('settlements') || 'ALL_TIME';
        else settlementsKey = settlements ? JSON.stringify(settlements) : 'ALL_TIME';

        // Check if the desired settlement has already been cached
        if (localStorage.getItem(settlementsKey)) {
            const cached: CachedSettlementData = JSON.parse(
                localStorage.getItem(settlementsKey) || ''
            );

            // Check if the cached data is from the right user, up to date & wasn't modified
            if (validateSettlementData(cached, version, artist_id)) {
                localStorage.setItem('settlements', settlementsKey);

                return cached;
            }
        }

        // Fetch the new settlement data
        const res = await fetch('https://api.takkt.io/music/v1/auth/insight/all', {
            method: 'post',
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                artist_id,
                settlements: settlementsKey === 'ALL_TIME' ? undefined : JSON.parse(settlementsKey)
            })
        });

        if (!res.ok) throw new Error();

        const data = await res.json();

        localStorage.setItem('settlements', settlementsKey);

        // Cache settlement period to LS + save version
        localStorage.setItem(
            settlementsKey,
            JSON.stringify({
                ...data,
                metadata: {
                    version,
                    artist_id,
                    timestamp: new Date().getTime()
                }
            })
        );

        return data;
    } catch (e) {
        throw new Error();
    }
};

export default fetchInsightData;
