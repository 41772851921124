import { Release } from '../util/types';
import { apiUrl } from './config';

const confirmNewRelease = async (
    artist_id: string,
    release: Release | null,
    file: File
): Promise<Release> => {
    try {
        const formData = new FormData();
        formData.append('labelcopy', file);
        formData.append('release', JSON.stringify(release));

        const res = await fetch(apiUrl + '/artist/v1/artist/' + artist_id + '/new_release', {
            method: 'post',
            body: formData,
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token')
            })
        });

        if (!res.ok) throw new Error();

        return await res.json();
    } catch (e) {
        throw new Error();
    }
};

export default confirmNewRelease;
