import React, { useEffect, useState } from 'react';
import {Link, Route, Redirect, Switch, useLocation, useParams} from 'react-router-dom';
import { withTheme, DefaultTheme } from 'styled-components';

import { Container, ContentWrapper, FolderGrid, Folder, Heading4, Button } from '../styled';

import FolderIcon from '../../assets/folder-filled.svg';
import UploadIcon from '../../assets/upload.svg';

import { Artist, User, Document } from '../../util/types';
import usePrevious from '../../util/usePrevious';
import products from '../../util/products';

import Files from './Files';
import Loading from '../misc/Loading';
import ErrorComponent from '../misc/Error';
import ProductHeader from '../navigation/ProductHeader';

import fetchDocuments from '../../api/fetchDocuments';
import NewDocumentModal from '../modals/NewDocumentModal';
import Spinner from "../../assets/spinner.svg";
import {startPreprocessingPipeline} from "../../api/preprocessingPipeline";
interface Props {
    user: User | null;
    artist: Artist | null;
    theme: DefaultTheme;
}

const Folders: React.FC<Props> = ({ user, artist, theme }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [files, setFiles] = useState<Document[] | null>(null);
    const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
    const [globalProcessing, setGlobalProcessing] = useState<boolean>(false)

    const { pathname } = useLocation();

    const type = pathname.includes('contracts') ? 'CONTRACT' : 'STATEMENT';

    const fetchData = async (artist_id: string) => {
        try {
            setLoading(true);

            setFiles(await fetchDocuments(artist_id));
        } catch (e) {
            setError(
                'An unexpected error occurred, please try reloading the page or contacting us.'
            );
        } finally {
            setLoading(false);
        }
    };

    const startPipeline = async () => {
        if (artist) {
            await startPreprocessingPipeline(
                artist.artist_id
            )
        }
    }

    const prevArtist = usePrevious(artist);

    // Fetch data on first load + when artist changes
    useEffect(() => {
        const artistChanged =
            prevArtist !== undefined && prevArtist?.artist_id !== artist?.artist_id;

        if (
            user &&
            artist &&
            (!files || artistChanged) &&
            artist.services.indexOf('FILES') !== -1
        ) {
            fetchData(artist.artist_id);
        }
    }, [user, artist, files, prevArtist]);

    useEffect(() => {
        if (artist && files && !globalProcessing) {
            fetchData(artist.artist_id);
        }
    }, [globalProcessing])

    if (!user || !artist) return <Redirect to="/error" />;

    if (artist.services.indexOf('FILES') === -1) return <Redirect to="/" />;

    return (
        <>
            <ProductHeader
                title="Files"
                category={3}
                description={products.filter((p) => p.name === 'FILES')[0].product_description}
            >
                <Route path="/files/contracts">
                    <div>
                        <Button dark onClick={() => setShowUploadModal(true)}>
                            <img src={UploadIcon} alt="Upload file" /> Upload
                        </Button>
                    </div>
                </Route>
                <Route path="/files/statements">
                    <div>
                        <Button dark onClick={() => setShowUploadModal(true)}>
                            <img src={UploadIcon} alt="Upload file" /> Upload
                        </Button>
                        <div style={{height: "2rem"}}></div>
                        <Button
                            onClick={() => {
                                startPipeline()
                            }}
                        >
                            Process all
                        </Button>
                    </div>
                </Route>
            </ProductHeader>

            <Container>
                {loading ? (
                    <Loading color={theme.colors.brandThree} />
                ) : error || !files || !artist ? (
                    <ErrorComponent message={error} />
                ) : (
                    <Switch>
                        <Route path="/files/:folder">
                            <Files
                                artist={artist}
                                files={files}
                                loading={loading}
                                setLoading={setLoading}
                                refetch={() => fetchData(artist.artist_id)}
                                setGlobalProcessing={setGlobalProcessing}
                            />
                        </Route>

                        <Route path="/files">
                            <ContentWrapper>
                                <FolderGrid>
                                    <Link to="/files/contracts">
                                        <Folder>
                                            <img src={FolderIcon} alt="Folder" />
                                            <Heading4>Contracts</Heading4>
                                            <p>
                                                {
                                                    files.filter((file) => file.type === 'CONTRACT')
                                                        .length
                                                }{' '}
                                                files
                                            </p>
                                        </Folder>
                                    </Link>
                                    <Link to="/files/statements">
                                        <Folder>
                                            <img src={FolderIcon} alt="Folder" />
                                            <Heading4>Statements</Heading4>
                                            <p>
                                                {
                                                    files.filter(
                                                        (file) => file.type === 'STATEMENT'
                                                    ).length
                                                }{' '}
                                                files
                                            </p>
                                        </Folder>
                                    </Link>
                                </FolderGrid>
                            </ContentWrapper>
                        </Route>
                    </Switch>
                )}
            </Container>

            {showUploadModal && (
                <NewDocumentModal
                    artist_id={artist.artist_id}
                    type={type}
                    refetch={() => fetchData(artist.artist_id)}
                    onClose={() => setShowUploadModal(false)}
                />
            )}
        </>
    );
};

export default withTheme(Folders);
