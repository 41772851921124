import React, { useState } from 'react';
import { NewBadgeWrapper } from '../styled';

interface Props {}


const Explanation: React.FC<Props> = () => {
    const [opened, open] = useState<boolean>(false);

    return (
        <NewBadgeWrapper>
            <p onMouseUp={() => !opened && open(true)}>New</p>
        </NewBadgeWrapper>
    );
};

export default Explanation;
