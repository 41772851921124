import React from 'react';
import Loader from 'react-spinners/ScaleLoader';
import { DefaultTheme, withTheme } from 'styled-components';
import { LoadingButton, LoadingWrapper } from '../styled';

interface Props {
    theme: DefaultTheme;
    color?: string;
    small?: boolean;
    fullScreen?: boolean;
}

const Loading: React.FC<Props> = ({ theme, color, small, fullScreen }) => {
    const baseSize = window.innerWidth >= 1100 ? 5 : 4;

    return small ? (
        <LoadingButton>
            <Loader
                loading={true}
                color={color || theme.colors.brandTwo}
                height={30}
                width={3}
                margin={3}
                radius={2}
            />
        </LoadingButton>
    ) : (
        <LoadingWrapper fullScreen={fullScreen}>
            <Loader
                loading={true}
                color={color || theme.colors.brandTwo}
                height={baseSize * 10}
                width={baseSize}
                margin={baseSize}
                radius={2}
            />
        </LoadingWrapper>
    );
};

export default withTheme(Loading);
