import { DefaultTheme } from 'styled-components';

const brandColorByCategory = (category: number, theme: DefaultTheme, accent?: boolean) => {
    if (accent) {
        if (category === 1) return theme.colors.brandOneAccent1;
        else if (category === 2) return theme.colors.brandTwoAccent1;
        else return theme.colors.brandThreeAccent1;
    } else {
        if (category === 1) return theme.colors.brandOne;
        else if (category === 2) return theme.colors.brandTwo;
        else return theme.colors.brandThree;
    }
};

export default brandColorByCategory;
