import React, { useState } from 'react';
import ModalWrapper from './ModalWrapper';
import { Button, RenameModal as RenameModalWrapper, Heading4, Input } from '../styled';

interface Props {
    title: string;
    initialValue: string;
    loading: boolean;
    onClose: () => void;
    onSubmit: (newValue: string) => void;
}

const RenameModal: React.FC<Props> = ({ title, initialValue, loading, onClose, onSubmit }) => {
    const [value, setValue] = useState<string>(initialValue);

    return (
        <ModalWrapper onClose={onClose}>
            <RenameModalWrapper>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        onSubmit(value);
                    }}
                >
                    <Heading4>{title}</Heading4>

                    <Input
                        transparent
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        placeholder="New title"
                        autoFocus
                    />

                    <div>
                        <p onClick={onClose}>Cancel</p>
                        <Button type="submit">{loading ? 'Loading...' : 'Ok'}</Button>
                    </div>
                </form>
            </RenameModalWrapper>
        </ModalWrapper>
    );
};

export default RenameModal;
