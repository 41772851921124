import styled from 'styled-components';

interface MetricWrapperProps {
    noWrapper?: boolean;
}

const MetricWrapper = styled.div<MetricWrapperProps>`
    position: relative;
    width: 100%;
    padding: 3rem;
    background-color: ${({ theme }) => theme.colors.darkGrey};
    border-radius: ${({ theme }) => theme.borderRadius1};

    ${(props) =>
        props.noWrapper &&
        `
        padding: 0;
        background-color: transparent;
        border-radius: none;
    `}

    select {
        max-width: 100%;
    }

    @media (max-width: 1300px) {
        padding: 2.5rem;
    }
`;

const MetricTop = styled.div`
    @media (max-width: 800px) {
        padding-right: 2.5rem;
    }
`;

interface NumberWrapperProps {
    secondary?: boolean;
    angle?: number;
}

const NumberWrapper = styled.div<NumberWrapperProps>`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 45rem;

    p,
    a {
        color: ${({ theme }) => theme.colors.lightGrey};
    }

    > p:last-of-type {
        color: ${(props) =>
            props.secondary ? props.theme.colors.brandTwoAccent3 : props.theme.colors.brandTwo};
        font-weight: 500;
        margin-top: 2rem;
    }

    a {
        svg {
            height: 2rem;
            width: 2rem;
            margin: 0 0 -4px 3px;
        }

        @media (max-width: 800px) {
            display: none;
        }
    }

    img {
        width: 3.5rem;

        transform: rotateZ(${(props) => props.angle}deg);
    }
`;

const NumberValue = styled.div`
    font-size: 6.5rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.white};
    margin-top: 2rem;

    @media (max-width: 1300px) {
        font-size: 5.5rem;
    }

    @media (max-width: 600px) {
        font-size: 5rem;
    }

    @media (max-width: 500px) {
        font-size: 4.5rem;
    }
`;

interface TrendWrapperProps {
    angle: number;
}

const TrendWrapper = styled.div<TrendWrapperProps>`
    position: relative;
    margin: 3rem 0;

    div {
        width: 100%;
        height: 3rem;
        background-image: linear-gradient(
            to right,
            ${({ theme }) => theme.colors.brandOneAccent1},
            ${({ theme }) => theme.colors.green}
        );
        border-radius: 5px;

        span {
            position: absolute;
            left: ${(props) => props.angle}%;
            top: 0;
            display: block;
            width: 1.5rem;
            height: 3rem;
            border-radius: 2.5px;
            background-color: ${({ theme }) => theme.colors.white};
        }
    }
`;

interface LineChartWrapperProps {
    legend?: boolean;
    scroll?: boolean;
}

const LineChartWrapper = styled.div<LineChartWrapperProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;

    > p:last-of-type {
        color: ${({ theme }) => theme.colors.lightGrey}C0;
        font-weight: 400;
        margin: 3rem 0 -2rem 7rem;
    }

    > div:last-of-type {
        height: ${(props) => (props.legend ? '40rem' : '35rem')};
        width: 100%;
        overflow: hidden;
    }

    @media (max-width: 1100px) {
        > div:last-of-type {
            height: 40rem;
        }
    }

    @media (max-width: 700px) {
        overflow-x: scroll;

        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }

        > div:last-of-type {
            width: 200vw;
        }

        > div:last-of-type {
            margin-top: 3rem;
        }

        > p:last-of-type {
            margin: 3rem 0 -5rem 7rem;
        }
    }
`;

interface BarChartWrapperProps {
    scroll?: boolean;
}

const BarChartWrapper = styled.div<BarChartWrapperProps>`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: ${(props) => (props.scroll ? 'scroll' : 'hidden')};
    overflow-y: hidden;

    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
`;

interface BarChartContainerProps {
    horizontal?: boolean;
    bars?: number;
    fixedWidth?: boolean;
}

const BarChartContainer = styled.div<BarChartContainerProps>`
    margin-top: 1.5rem;
    height: ${(props) => (props.horizontal && props.bars ? `${8 * props.bars}rem` : '40rem')};
    width: ${(props) => (!props.fixedWidth && props.bars ? `${16 * props.bars}rem` : '100%')};
    overflow: auto;

    @media (max-width: 1100px) {
        margin-top: 1rem;

        height: ${(props) => (props.horizontal && props.bars ? `${7 * props.bars}rem` : '35rem')};
    }
`;

const BarChartNav = styled.div`
    display: flex;

    p {
        display: flex;
        align-items: center;

        span {
            margin: 0 3px -3px 0;
        }

        cursor: pointer;
        margin-top: 2rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.brandThree};

        margin-right: 2rem;

        @media (max-width: 600px) {
            font-size: 1.5rem;
            margin-right: 1rem;

            span {
                margin: 0 2px -3px 0;
            }
        }
    }
`;

const DoubleDoubleBarChartWrapper = styled.div`
    position: relative;
    width: 100%;
    max-width: 100%;
    overflow: visible !important;
    height: 72rem;
    margin-top: 7.5rem;
    display: flex;

    > div {
        width: 100%;
        max-width: 100%;
        height: 100%;

        &:nth-child(2) {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            background-color: ${({ theme }) => theme.colors.black};
            border: ${({ theme }) => theme.border};
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            width: 18rem;
            border-radius: ${({ theme }) => theme.borderRadius2};
            box-shadow: ${({ theme }) => theme.boxShadow1};
            padding-bottom: 20px;
            overflow: hidden;
            z-index: 3;

            p {
                font-size: 1.6rem;
                font-weight: 600;
                color: ${({ theme }) => theme.colors.white};
            }
        }

        div {
            max-width: 100%;
        }

        canvas {
            height: 100%;
            width: 100%;
            max-width: 100%;
        }

        &:nth-child(1) canvas {
            margin-left: auto;
        }

        &:nth-child(3) canvas {
            margin-right: auto;
        }
    }

    @media (max-width: 800px) {
        margin-top: 5rem;

        > div {
            &:nth-child(2) {
                width: 15rem;

                p {
                    font-weight: 500;
                }
            }
        }
    }

    @media (max-width: 700px) {
        > div {
            min-width: 0;
            &:nth-child(2) {
                width: 13rem;

                p {
                    font-size: 1.5rem;
                }
            }
        }
    }
`;

const PieChartWrapper = styled.div`
    margin-top: 5rem;
    width: 100%;
    max-width: 100%;
    height: 30rem;

    @media (max-width: 1300px) {
        height: 27.5rem;
    }

    @media (max-width: 1100px) {
        margin: 5rem auto 0 auto;
    }

    @media (max-width: 600px) {
        margin: 3rem auto 0 auto;
    }
`;

const MapChartWrapper = styled.div`
    position: relative;
    width: 100%;
    justify-content: center;

    > div:last-of-type {
        margin-top: 5rem;
        width: 100%;
        padding: 2rem;
        border-radius: ${({ theme }) => theme.borderRadius1};
        border: ${({ theme }) => theme.border};
    }

    .anychart-credits {
        display: none !important;
    }

    @media (max-width: 700px) {
        > div:last-of-type {
            padding: 1rem;
        }
    }
`;

const FilterBarWrapper = styled.div`
  margin-top: 2rem
`

export {
    MetricWrapper,
    MetricTop,
    NumberWrapper,
    NumberValue,
    TrendWrapper,
    LineChartWrapper,
    BarChartWrapper,
    PieChartWrapper,
    MapChartWrapper,
    DoubleDoubleBarChartWrapper,
    BarChartNav,
    BarChartContainer,
    FilterBarWrapper
};
