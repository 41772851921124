import React from 'react';
import { TrendWrapper } from '../styled';
import ChartWrapper from './ChartWrapper';

interface Props {
    title: string;
    description: string;
    explanation: string;
    value: number;
}

const Trend: React.FC<Props> = (props) => {
    const { title, description, explanation, value } = props;

    const angle = ((value + 1) / 2) * 100;

    return (
        <ChartWrapper explanation={explanation} title={title} description={description}>
            <TrendWrapper angle={angle}>
                <div>
                    <span />
                </div>
            </TrendWrapper>
        </ChartWrapper>
    );
};

export default Trend;
