import { apiUrl } from './config';
import {Collaboration} from "../util/types";

const fetchCollaborations = async (
    artist_id: string
): Promise<{
    collaborations: Collaboration[];
}> => {
    try {
        const res = await fetch(apiUrl + '/artist/v1/artist/' + artist_id + '/collaborations', {
            method: 'get',
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            })
        });

        if (!res.ok) throw new Error();

        let collaborations = await res.json();

        return { collaborations };
    } catch (e) {
        throw new Error();
    }
};

export default fetchCollaborations;
