import React, {useEffect, useState} from 'react';
import { Bar } from 'react-chartjs-2';
import { ChevronDown, ChevronUp, Close } from 'react-ionicons';
import formatNumber from '../../util/formatNumber';
import { ChartStyles } from '../../util/types';
import { BarChartContainer, BarChartNav, BarChartWrapper } from '../styled';
import ChartWrapper from './ChartWrapper';
import FilterBar from "../misc/FilterBar";

interface Props {
    title?: string;
    description?: string;
    explanation?: string;
    unit: string;
    values: Array<Array<number>>;
    labels: Array<string>;
    horizontal?: boolean;
    noWrapper?: boolean;
    stacked?: boolean;
    roundTo?: number;
    fixedWidth?: boolean;
    comparison?: boolean;
    customColors?: string[];
    smallScreen?: boolean;
    chartStyles: ChartStyles;
    setFilter?: any;
    isNew?: boolean;
}

const BarChart: React.FC<Props> = (props) => {
    const {
        title,
        description,
        explanation,
        unit,
        labels,
        values,
        stacked,
        roundTo,
        horizontal,
        fixedWidth,
        comparison,
        customColors,
        smallScreen,
        noWrapper,
        chartStyles,
        setFilter,
        isNew
    } = props;

    const datasets = values.map((valuesArray) =>
        unit === '%' ? valuesArray.map((value) => value * 100) : valuesArray
    );
    const accentColor = chartStyles.brand[0];
    const maxLength = datasets[0].length;

    const [bars, expand] = useState<number>(maxLength < 5 ? maxLength : 5);

    useEffect(() => {
        expand(Math.min(values[0].length, 5))
    }, [values])

    const options = {
        indexAxis: horizontal ? 'y' : 'x',
        layout: {
            padding: {
                right: (() => {
                    if (!horizontal) return 0;
                    else {
                        if (window.innerWidth < 500) return 0;
                        else return stacked ? 160 : 80;
                    }
                })(),
                top: horizontal ? 0 : 30,
                bottom: 0
            }
        },
        plugins: {
            legend: {
                display: stacked !== undefined && window.innerWidth >= 500,
                position: 'top',
                align: 'center',
                labels: {
                    boxWidth: 12,
                    padding: 20,
                    font: {
                        family: chartStyles.fontFamily,
                        size: 14
                    },
                    color: chartStyles.white,
                    usePointStyle: true
                }
            },
            tooltip: {
                enabled: stacked !== undefined,
                backgroundColor: chartStyles.white,
                titleFont: {
                    family: chartStyles.fontFamily,
                    size: 14,
                    weight: 'bold'
                },
                titleColor: chartStyles.black,
                titleSpacing: 5,
                bodyFont: {
                    family: chartStyles.fontFamily,
                    size: 15,
                    weight: 'normal'
                },
                bodyColor: chartStyles.black,
                bodySpacing: 7.5,
                cornerRadius: 5,
                borderWidth: 0,
                padding: { x: 15, y: 10 },
                caretPadding: 20,
                displayColors: false,
                mode: 'y',
                callbacks: {
                    label: ({ raw, datasetIndex }: { raw: number; datasetIndex: number }) =>
                        (datasetIndex === 0 ? 'Artist: ' : 'Label: ') + raw.toFixed(5) + unit
                }
            },
            datalabels: {
                display: true,
                align: 'start',
                anchor: 'end',
                offset: (() => {
                    if (smallScreen) {
                        if (horizontal) return stacked ? -160 : -70;
                        else return -30;
                    } else {
                        if (horizontal) return stacked ? -180 : -80;
                        else return -30;
                    }
                })(),
                color: chartStyles.white,
                font: {
                    weight: 400,
                    size: (() => {
                        if (smallScreen) return 14;
                        else return stacked ? 15 : 16;
                    })()
                }
            },
        },
        title: {
            display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            bar: {
                backgroundColor: accentColor,
                borderRadius: 25,
                borderSkipped: false
            }
        },
        scales: {
            xAxis: {
                stacked: false,
                ticks: {
                    display: horizontal ? false : true,
                    maxTicksLimit: 10,
                    font: {
                        size: (() => {
                            if (!smallScreen) return 18;
                            else return window.innerWidth >= 600 ? 16 : 15;
                        })()
                    },
                    color: chartStyles.white,
                    padding: 10,
                    beginAtZero: true
                },
                grid: {
                    display: false,
                    drawBorder: false
                }
            },
            yAxis: {
                stacked,
                ticks: {
                    display: horizontal ? true : false,
                    beginAtZero: true,
                    font: {
                        size: (() => {
                            if (!smallScreen) return 18;
                            else return window.innerWidth >= 600 ? 16 : 15;
                        })()
                    },
                    color: chartStyles.white,
                    crossAlign: 'far',
                    padding: 10
                },
                grid: {
                    display: false,
                    drawBorder: false
                }
            }
        }
    };

    // Color bars in bar chart under map according to their values/ color in the map
    let colorsArray: string[] = [];
    if (customColors) {
        let maxValue = datasets[0][0];

        datasets[0]
            .filter((_v, index) => index < bars)
            .forEach((v) => {
                if (v > maxValue - maxValue / 5) colorsArray.push(customColors[4]);
                else if (v > maxValue - 2 * (maxValue / 5)) colorsArray.push(customColors[3]);
                else if (v > maxValue - 3 * (maxValue / 5)) colorsArray.push(customColors[2]);
                else if (v > maxValue - 4 * (maxValue / 5)) colorsArray.push(customColors[1]);
                else colorsArray.push(customColors[0]);
            });
    }

    const data = {
        labels: (() => {
            if (!horizontal) return labels;
            else {
                if (bars > 5) return labels.filter((_v, index) => index < bars);
                else return labels.filter((_v, index) => index <= 4);
            }
        })(),
        datasets: datasets.map((valuesArray, index) => ({
            label: (() => {
                if (!stacked) return undefined;
                else return index === 0 ? 'Artist' : 'Label';
            })(),
            data: (() => {
                if (!horizontal) return valuesArray;
                else
                    return bars > 5
                        ? valuesArray.filter((_v, i) => i < bars)
                        : valuesArray.filter((_v, i) => i <= 4);
            })(),
            backgroundColor: (() => {
                if (customColors) return colorsArray;
                else {
                    if (comparison) return [chartStyles.brand[0], chartStyles.brand[2]];
                    else return stacked && index % 2 === 0 ? chartStyles.brand[2] : accentColor;
                }
            })(),
            maxBarThickness: (() => {
                if (window.innerWidth >= 1100) return horizontal ? 25 : 30;
                else return horizontal ? 20 : 25;
            })(),
            datalabels: {
                display:
                    (stacked && index % 2 === 0) || (stacked && window.innerWidth < 500)
                        ? false
                        : true,
                formatter: (v: any, i: any) => {
                    if (stacked && index === 1) {
                        return (
                            datasets[0][i.dataIndex].toFixed(5) +
                            unit +
                            '  vs  ' +
                            v.toFixed(5) +
                            unit
                        );
                    } else {
                        return (roundTo ? v.toFixed(roundTo) : formatNumber(v)) + unit;
                    }
                }
            }
        }))
    };

    return (
        <ChartWrapper
            explanation={explanation}
            title={title}
            description={description}
            noWrapper={noWrapper}
            isNew={isNew}
        >
            <BarChartWrapper scroll={smallScreen && values[0].length > 2 && !horizontal}>

                {/* Adds a text input if necessary */}
                {setFilter && (
                    <FilterBar
                        setFilter={setFilter}
                    />
                )}

                <BarChartContainer
                    horizontal={horizontal}
                    bars={bars}
                    fixedWidth={
                        smallScreen && values[0].length > 2 && !horizontal
                            ? false
                            : fixedWidth || horizontal
                    }
                >
                    <Bar type="horizontal" data={data} options={options}/>
                </BarChartContainer>

                <BarChartNav>
                    {horizontal && maxLength > 5 && bars < maxLength && (
                        <p onClick={() => expand(bars + 5 <= maxLength ? bars + 5 : maxLength)}>
                            <ChevronDown color={chartStyles.accent} /> Show more
                        </p>
                    )}

                    {horizontal && maxLength > 5 && bars > 5 && (
                        <p
                            onClick={() =>
                                expand(
                                    bars === maxLength && maxLength % 5 !== 0
                                        ? maxLength - (maxLength % 5)
                                        : bars - 5
                                )
                            }
                        >
                            <ChevronUp color={chartStyles.accent} /> Show less
                        </p>
                    )}

                    {horizontal && maxLength > 5 && bars > 10 && (
                        <p onClick={() => expand(maxLength < 5 ? maxLength : 5)}>
                            <Close color={chartStyles.accent} /> Collapse
                        </p>
                    )}
                </BarChartNav>
            </BarChartWrapper>
        </ChartWrapper>
    );
};

export default BarChart;
