import React from 'react';
import { Link } from 'react-router-dom';
import ModalWrapper from './ModalWrapper';
import { Table, CloseIcon, FullscreenOverlay, MissingAudioModalWrapper, Heading4 } from '../styled';
import shortenLabel from '../../util/shortenLabel';
import CustomCloseIcon from '../../assets/close.svg';
import combineTitle from '../../util/combineTitle';
import { Release } from '../../util/types';

interface Props {
    releases: Release[];
    baseUrl: string;
    onClose: () => void;
}

const MissingAudioReleases: React.FC<Props> = ({ releases, baseUrl, onClose }) => {
    const relevantReleases = releases.filter(
        (release) =>
            ['MISCELLANEOUS', 'VIDEO', 'RINGTUNE'].indexOf(
                release.manual_category || release.category || ''
            ) === -1
    );

    const totalTracks = relevantReleases
        .map((release) =>
            release.tracks
                ? release.tracks
                : release.releases?.map((subRelease) => subRelease.release.tracks)
        )
        .flat()
        .flat()
        .filter(
            (track) =>
                track &&
                !track.track.no_audio &&
                ['audio', 'video'].includes(track.track.type.toLowerCase())
        )
        .filter(
            (v, i, a) => a.findIndex((t) => t?.track.recording_id === v?.track.recording_id) === i
        );

    const incompleteReleases = relevantReleases
        .map((release) => {
            const allTracks = release.tracks
                ? release.tracks
                : release.releases
                ? release.releases.map((subRelease) => subRelease.release.tracks).flat()
                : [];

            return {
                ...release,
                totalTracks: allTracks.length,
                missingTracks: allTracks.filter(
                    (track) =>
                        track &&
                        !track.track.audio_uploaded &&
                        !track.track.no_audio &&
                        ['audio', 'video'].includes(track.track.type.toLowerCase())
                ).length
            };
        })
        .filter((incompleteRelease) => incompleteRelease.missingTracks > 0);

    const tracksWithoutAudio = totalTracks.filter((track) => !track?.track.audio_uploaded);

    return (
        <FullscreenOverlay>
            <ModalWrapper onClose={onClose}>
                <MissingAudioModalWrapper wider>
                    <div>
                        <Heading4>Missing audio files</Heading4>

                        <CloseIcon onClick={onClose} customImage relative>
                            <img src={CustomCloseIcon} alt="Close modal" />
                        </CloseIcon>
                    </div>

                    <span />

                    <div>
                        There are still {tracksWithoutAudio.length} tracks without an audio file.
                        Please upload them as soon as possible.
                    </div>

                    <span />

                    <div>
                        <Table>
                            <tbody>
                                {incompleteReleases.map((release, index) => (
                                    <tr key={index}>
                                        <td>
                                            <Link
                                                to={`/${baseUrl.replace(/\//g, '')}/${
                                                    release.release_id
                                                }`}
                                            >
                                                {shortenLabel(
                                                    combineTitle(
                                                        release.manual_title || release.title,
                                                        release.title_addition
                                                    ),
                                                    window.innerWidth > 700 ? 60 : 45
                                                )}{' '}
                                            </Link>
                                        </td>
                                        <td>
                                            {capitalizeCategory(
                                                release.manual_category || release.category || ''
                                            )}
                                        </td>
                                        <td>({release.missingTracks})</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </MissingAudioModalWrapper>
            </ModalWrapper>
        </FullscreenOverlay>
    );
};

const capitalizeCategory = (category: string) => {
    switch (category) {
        case 'SINGLE/EP':
            return 'Single/EP';
        case 'DVD':
            return category;
        default:
            return category[0] + category.slice(1).toLocaleLowerCase();
    }
};

export default MissingAudioReleases;
