import React from 'react';
import combineTitle from '../../util/combineTitle';
import shortenLabel from '../../util/shortenLabel';
import { Release } from '../../util/types';
import { ReleasePreviewWrapper, Heading4, SubRelease, Table, Button, Select } from '../styled';
import CompleteIcon from '../../assets/complete.svg';
import WarningIcon from '../../assets/warning.svg';

interface Props {
    release: Release;
    category: string;
    onCategoryChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    onConfirmation: (value: boolean) => void;
}

const ReleasePreview: React.FC<Props> = ({
    release,
    category,
    onCategoryChange,
    onConfirmation
}) => {
    const categories = [
        'Album',
        'Single/EP',
        'DVD',
        'Compilation',
        'Vinyl',
        'Video',
        'Ringtune',
        'Miscellaneous'
    ];

    return (
        <ReleasePreviewWrapper>
            <div>
                <div>
                    <Heading4>
                        {combineTitle(
                            release.manual_title || release.title,
                            release.title_addition
                        )}
                    </Heading4>

                    <p>
                        <span>{release.type}</span>
                        <span>|</span>
                        <span>{release.manual_year || release.year}</span>
                        <span>|</span>
                        <span>
                            {release.tracks && release.tracks.length > 0
                                ? release.tracks.length +
                                  (release.tracks.length === 1 ? ' track' : ' tracks')
                                : 'Bundle'}
                        </span>
                    </p>
                </div>

                <Select value={category} onChange={onCategoryChange}>
                    {categories.map((categoryOption, index) => (
                        <option key={index} value={categoryOption.toUpperCase()}>
                            {categoryOption}
                        </option>
                    ))}
                </Select>
            </div>

            <div>
                {release.releases && release.releases.length > 0 ? (
                    release.releases
                        .sort((a, b) => a.number - b.number)
                        .map((subRelease, index) => (
                            <SubRelease key={index}>
                                <Heading4>
                                    {combineTitle(
                                        subRelease.release.manual_title || subRelease.release.title,
                                        subRelease.release.title_addition
                                    )}
                                </Heading4>
                                <Table>
                                    <tbody>
                                        {subRelease.release.tracks ? (
                                            subRelease.release.tracks.map(
                                                ({ track, number }, i) => (
                                                    <tr key={i}>
                                                        <td>{number}</td>
                                                        <td>
                                                            {shortenLabel(
                                                                combineTitle(
                                                                    track.title,
                                                                    track.title_addition
                                                                ),
                                                                60
                                                            )}
                                                        </td>
                                                        <td>{track.duration}</td>
                                                    </tr>
                                                )
                                            )
                                        ) : (
                                            <p>This release doesn't have any tracks.</p>
                                        )}
                                    </tbody>
                                </Table>
                            </SubRelease>
                        ))
                ) : (
                    <Table>
                        <tbody>
                            {release.tracks ? (
                                release.tracks.map(({ track, number }, index) => (
                                    <tr key={index}>
                                        <td>{number}</td>
                                        <td>
                                            {shortenLabel(
                                                combineTitle(track.title, track.title_addition),
                                                60
                                            )}
                                        </td>
                                        <td>{track.duration}</td>
                                    </tr>
                                ))
                            ) : (
                                <p>This release doesn't have any tracks.</p>
                            )}
                        </tbody>
                    </Table>
                )}
            </div>

            <div>
                <Button transparent onClick={() => onConfirmation(false)}>
                    <img src={WarningIcon} alt="Mark as incorrect" />
                    Incorrect
                </Button>
                <Button primary onClick={() => onConfirmation(true)}>
                    <img src={CompleteIcon} alt="Mark as correct" />
                    Looks good
                </Button>
            </div>
        </ReleasePreviewWrapper>
    );
};

export default ReleasePreview;
