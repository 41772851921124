import React from 'react';
import ModalWrapper from './ModalWrapper';
import { Button, RenameModal as RenameModalWrapper, Heading4 } from '../styled';

interface Props {
    title: string;
    description: string;
    loading: boolean;
    danger?: boolean;
    buttonText?: string;
    onClose: () => void;
    onSubmit: () => void;
}

const ConfirmationModal: React.FC<Props> = ({
    title,
    description,
    loading,
    danger,
    buttonText,
    onClose,
    onSubmit
}) => {
    return (
        <ModalWrapper onClose={onClose}>
            <RenameModalWrapper>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();

                        onSubmit();
                    }}
                >
                    <Heading4>{title}</Heading4>

                    <p>{description}</p>

                    <div>
                        <p onClick={onClose}>Cancel</p>
                        <Button type="submit" error={danger}>
                            {loading ? 'Loading...' : buttonText || 'Ok'}
                        </Button>
                    </div>
                </form>
            </RenameModalWrapper>
        </ModalWrapper>
    );
};

export default ConfirmationModal;
