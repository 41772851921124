import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { SizeMe } from 'react-sizeme';
import ModalWrapper from '../modals/ModalWrapper';
import { FullscreenOverlay, PDFWrapper, PDFControls } from '../styled';
import ArrowLeft from '../../assets/chevron-right.svg';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
    file?: File | string;
    fullscreen?: boolean;
    onClose?: () => void;
}

const PDFViewer: React.FC<Props> = ({ file, fullscreen, onClose }) => {
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [numPages, setNumPages] = useState<number>(0);

    return fullscreen && onClose ? (
        <FullscreenOverlay>
            <ModalWrapper onClose={onClose}>
                <PDFWrapper>
                    <SizeMe
                        monitorHeight
                        refreshRate={64}
                        refreshMode={'debounce'}
                        children={({ size }) => (
                            <Document
                                file={file}
                                onLoadSuccess={(doc) => setNumPages(doc.numPages)}
                            >
                                <Page width={size.width || 0} pageNumber={pageNumber} />
                            </Document>
                        )}
                    />

                    <PDFControls>
                        <div
                            onClick={() =>
                                setPageNumber(pageNumber > 1 ? pageNumber - 1 : numPages)
                            }
                        >
                            <img src={ArrowLeft} alt="Scroll backward" />
                        </div>
                        <div
                            onClick={() =>
                                setPageNumber(pageNumber < numPages ? pageNumber + 1 : 1)
                            }
                        >
                            <img src={ArrowLeft} alt="Scroll forward" />
                        </div>
                    </PDFControls>
                </PDFWrapper>
            </ModalWrapper>
        </FullscreenOverlay>
    ) : (
        <SizeMe
            monitorHeight
            refreshRate={64}
            refreshMode={'debounce'}
            children={({ size }) => (
                <Document file={file}>
                    <Page width={size.width || 0} pageNumber={pageNumber} />
                </Document>
            )}
        />
    );
};

export default PDFViewer;
