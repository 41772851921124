import { ProductInfo } from './types';

const products: ProductInfo[] = [
    {
        name: 'MONITOR',
        display_name: 'Monitor',
        category: 1,
        description: `
        <p>Digital footprint</p>
        <span>Inside view of the actual music consumption in the digital domain.</span>
        <p>Tracking</p>
        <span>Automated aggregation of daily usage data from leading DSPs including YouTube.</span>
        <p>Data Hub</p>
        <span>Visualisation based on key metrics in one central space.</span>`,
        upcoming_features: `
        <p>We are committed to constantly improving our products and services. There are some exciting new features in the making:</p>
        <ul>
            <li><b>Playlists:</b> Find out on which playlists your music is featured.</li>
            <li><b>Map View:</b> See where in the world your music is played.</li>
            <li><b>Followers:</b> Keep track of true fans who follow your artist profiles in addition to listeners.</li>
        </ul>
        <p>Your feedback is important to us. If there is anything you’d like to see or something that does not work for you, please drop us a note.</p>
        <br/>
        <p>Recently added based on our customers' requests:</p>
        <ul>
            <li><b>Top Titles:</b> Find out which titles perform strongest on Spotify, Apple Music, Amazon Music</li>
        </ul>
        `,
        product_description: `Every minute billions of songs are streamed worldwide. We keep track of an artist's repertoire in the digital domain monitoring the world's leading DSPs, including Spotify, Apple and YouTube. The reported play counts serve as a benchmark for settled units.`
    },
    {
        name: 'INSIGHT',
        display_name: 'Insight',
        category: 2,
        description: `
            <p>Visualisation</p>
            <span>Automated input and processing of royalty statements.</span>
            <p>Readability</p>
            <span>Standard analysis of settlement data based on key metrics.</span>
            <p>Analysis</p>
            <span>Individual evaluation across different statement periods.</span>`,
        product_description: `Royalty statements come as hundreds of thousands of rows of Excel. We
            use Data Science to make them understandable and reviewable. This means
            we import, process and visualise settlement data on the basis of key
            metrics for individual analysis across the data set or single settlement
            periods.`
    },
    {
        name: 'CONTROL',
        display_name: 'Control',
        category: 2,
        description: `
            <p>Technology-supported</p>
            <span>Full-fledged audit based on individual controlling framework.</span>
            <p>Actual units</p>
            <span>Benchmark against actual streaming data from major DSPs.</span>
            <p>Fast & comprehensive</p>
            <span>Complete analysis of the data set for potentially larger deviations.</span>`
    },
    {
        name: 'SERVICE',
        display_name: 'Service Statements',
        category: 2,
        description: `
            <p>Backoffice as a service</p>
            <span>Provision of accounting related services to right owners.</span>
            <p>Automated</p>
            <span>Transfer of license shares according to individual contractual terms.</span>
            <p>Statistics</p>
            <span>Detailed analysis of billings, advanced payments and open balances.</span>`,
        product_description: `Master right owners are obliged to forward payments to artists who have contributed to certain recordings. We fully automatise the process and provide Service Statements according to individual contractual terms, which can be downloaded or sent via email. The respective account balance will be forwarded to the next settlement period.`,
        coming_soon: true
    },
    {
        name: 'APPROVALS',
        display_name: 'Approvals',
        category: 3,
        description: `
            <p>Central space</p>
            <span>Shared reference for price campaigns or sync approvals.</span>
            <p>Traceability</p>
            <span>Standardised record of individual requests outside of emails.</span>
            <p>Automation</p>
            <span>Individual rule set for automated approvals.</span>`,
        coming_soon: true
    },
    {
        name: 'DISCOGRAPHY',
        display_name: 'Discography',
        category: 3,
        description: `
            <p>Complete & current</p>
            <span>Full record of an artist's repertoire.</span>
            <p>Enriched data set</p>
            <span>Single entries per title including product numbers, version, and release date.</span>
            <p>Shared reference</p>
            <span>Unique link to royalty statements and approvals for campaigns or sync.</span>`,
        coming_soon: false,
        product_description: `A complete and correct record of an artist’s catalogue is key for appropriate remuneration. We aggregate release data from different sources, match custom identifiers for de-duplication, and make sure the repertoire is available for mechanical royalty collection as well as copyright exploitation.`
    },
    {
        name: 'INDIVIDUAL',
        display_name: 'Individual Services',
        category: 3,
        description: `
            <p>Project work</p>
            <span>Customised projects based on individual needs and requirements.</span>
            <p>Research</p>
            <span>Quantitative and qualitative market research with access to a test pool of 70k+ customers.</span>
            <p>Interdisciplinary</p>
            <span>Deep knowledge of tech and music domain with a proven track record in business innovations.</span>`
    },
    {
        name: 'FILES',
        display_name: 'Files',
        category: 3,
        description: `
            <p>Deposit</p>
            <span>Central file storage for contracts and settlement data.</span>
            <p>Reference</p>
            <span>Logical link to releases and audit relevant billing periods.</span>
            <p>Secure</p>
            <span>Encrypted upload for secure transfer of sensitive data.</span>`,
        product_description: `The review and processing of royalty statements is subject to individual contractual terms as well as settlement data. We connect the relevant information uploaded to provide services such as Control or Service Statements in an automatic way.`
    }
];

export default products;
