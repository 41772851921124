import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { DefaultTheme, withTheme } from 'styled-components';
import fetchMonitorData from '../../api/fetchMonitorData';
import products from '../../util/products';
import { Artist, MonitorData, MonitorFilter, User } from '../../util/types';
import ProductHeader from '../navigation/ProductHeader';
import ErrorComponent from '../misc/Error';
import Loading from '../misc/Loading';
import TimeFilter from '../misc/MonitorTimeFilter';
import usePrevious from '../../util/usePrevious';
import { ButtonSmall, Container, ProductWrapper } from '../styled';
import MonitorCharts from './MonitorCharts';
import UpcomingFeaturesModal from '../modals/UpcomingFeaturesModal';
import intervalToDateBoundaries from "../../util/intervalToDate";

interface Props {
    user: User | null;
    artist: Artist | null;
    smallScreen: boolean;
    theme: DefaultTheme;
}

const Monitor: React.FC<Props> = ({ user, artist, smallScreen, theme }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<MonitorData | null>();
    const [filter, setFilter] = useState<MonitorFilter>(
        JSON.parse(localStorage.getItem('monitor_time_filter') || '"Last 30 days"')
    );
    const [showUpcomingFeatures, setShowUpcomingFeatures] = useState<boolean>(false);

    const fetchData = async (artist_id: string, interval: MonitorFilter): Promise<void> => {
        try {
            setLoading(true);

            let [start_interval, end_interval] = intervalToDateBoundaries(interval)

            setData(await fetchMonitorData(artist_id, start_interval, end_interval));
            setFilter(interval);

            localStorage.setItem('monitor_time_filter', JSON.stringify(interval));
        } catch (e) {
            setError(
                'An unexpected error occurred, please try reloading the page or contacting us.'
            );
        } finally {
            setLoading(false);
        }
    };

    const prevArtist = usePrevious(artist);
    const prevFilter = usePrevious(filter);

    // Fetch data on first load / when artist changes / when filter changes
    useEffect(() => {
        const artistChanged =
            prevArtist !== undefined && prevArtist?.artist_id !== artist?.artist_id;
        const filterChanged = prevFilter !== undefined && prevFilter !== filter;

        if (
            user &&
            artist &&
            (!data || artistChanged || filterChanged) &&
            artist.services.indexOf('MONITOR') !== -1
        ) {
            fetchData(artist.artist_id, filter);
        }
    }, [user, artist, data, filter, prevArtist, prevFilter]);

    if (!user || !artist) return <Redirect to="/error" />;

    if (artist.services.indexOf('MONITOR') === -1) return <Redirect to="/" />;

    return (
        <ProductWrapper>
            <ProductHeader
                category={1}
                title="Monitor"
                description={products.filter((p) => p.name === 'MONITOR')[0].product_description}
                anchors={['youtube', 'spotify', 'apple', 'amazon', 'deezer']}
                customButtons={() => (
                    <ButtonSmall onClick={() => setShowUpcomingFeatures(true)}>
                        Show upcoming features
                    </ButtonSmall>
                )}
            >
                <TimeFilter filter={filter} setFilter={setFilter} />
            </ProductHeader>

            {showUpcomingFeatures && (
                <UpcomingFeaturesModal
                    content={
                        products.filter((p) => p.name === 'MONITOR')[0].upcoming_features || ''
                    }
                    onClose={() => setShowUpcomingFeatures(false)}
                />
            )}

            <Container>
                {loading ? (
                    <Loading color={theme.colors.brandOne} />
                ) : error || !data || !artist ? (
                    <ErrorComponent message={error} />
                ) : (
                    <MonitorCharts
                        data={data} filter={filter} smallScreen={smallScreen} artist={artist}
                    />
                )}
            </Container>
        </ProductWrapper>
    );
};

export default withTheme(Monitor);
