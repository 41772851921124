import React, {useEffect, useState} from 'react';
import {Link, Redirect, useHistory} from 'react-router-dom';
import {Artist, ServiceStatement, User} from '../../util/types';
import {
    BackButton,
    Container,
    Heading1,
    Heading4,
    ServiceStatementHeader,
    ProgressBar,
    FeaturedArtistCard,
    FeaturedArtistsGrid,
    StatusLabel
} from '../styled';
import ChevronRight from '../../assets/chevron-right.svg';
import PayoutIcon from '../../assets/payout.svg';
import CreditIcon from '../../assets/credit.svg';
import formatNumber from '../../util/formatNumber';
// Test data, delete file later
import {useParams} from "react-router";
import fetchCollaborations from "../../api/fetchCollaborations";
import usePrevious from "../../util/usePrevious";

interface Props {
    user: User | null;
    artist: Artist | null;
}

const ServiceStatementOverview: React.FC<Props> = ({user, artist}) => {
    const {period} = useParams<{ period: string }>();

    const {length: historyLength, goBack, push: redirectTo} = useHistory();

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<{ statements: ServiceStatement[] }>();

    const fetchData = async (artist_id: string) => {
        try {
            setLoading(true);

            const {collaborations} = await fetchCollaborations(artist_id)
            let stmts: ServiceStatement[] = []
            collaborations.forEach(c => {
                let tmp = c.service_statements.filter(x => {
                    return x.meta_data.settlement_period.toUpperCase() === period.toUpperCase()
                })
                stmts = stmts.concat(tmp)
            })

            setData({statements: stmts});
        } catch (e) {
            setError(
                'An unexpected error occurred, please try reloading the page or contacting us.'
            );
        } finally {
            setLoading(false);
        }
    };

    const prevArtist = usePrevious(artist);

    // Fetch data on first load + when artist changes
    useEffect(() => {
        const artistChanged =
            prevArtist !== undefined && prevArtist?.artist_id !== artist?.artist_id;

        if (
            user &&
            artist &&
            (!data || artistChanged) &&
            artist.services.indexOf('INSIGHT') !== -1
        ) {
            fetchData(artist.artist_id);
        }
    });

    if (!user || !artist) return <Redirect to="/error"/>;

    if (artist.services.indexOf('SERVICE') === -1) return <Redirect to="/"/>;

    return (
        <>{
            !error && !loading &&
            <Container>
                <BackButton
                    onClick={() => (historyLength <= 2 ? redirectTo('/service') : goBack())}>
                    <img src={ChevronRight} alt="Go back"/>
                    <p>Go back</p>
                </BackButton>

                <ServiceStatementHeader>
                    <div>
                        <Heading1>{period.toUpperCase()}</Heading1>
                        <Heading4>All service statements available
                            for {period.toUpperCase()}</Heading4>
                    </div>
                    <div>
                        {/*<img src={CalendarIcon} alt="Date" />*/}
                        {/*<span>Generated:</span>*/}
                        {/*<p>{statements[0].date}</p>*/}
                    </div>
                </ServiceStatementHeader>

                <ProgressBar>
                    {data && data.statements && data.statements
                        .filter(({meta_data}) => meta_data.status === "ACCEPTED")
                        .map((a, i) => (
                            <span key={i}/>
                        ))}
                    {data && data.statements && data.statements
                        .filter(({meta_data}) => !(meta_data.status === "ACCEPTED"))
                        .map((a, i) => (
                            <i key={i}/>
                        ))}
                </ProgressBar>

                <FeaturedArtistsGrid>
                    {data && data.statements && data.statements.map(
                        ({service_statement_id, featured_artist, meta_data, total}, index) => (
                            <Link
                                to={`/service/statement/${service_statement_id}`}
                                key={index}
                            >
                                <FeaturedArtistCard>
                                    <StatusLabel success={meta_data.status === "ACCEPTED"}
                                                 primary={!(meta_data.status === "ACCEPTED")}>
                                        {(meta_data.status === "ACCEPTED") ? 'approved' : 'open'}
                                    </StatusLabel>
                                    <Heading4>
                                        { !featured_artist.is_company &&
                                            `${featured_artist.first_name} ${featured_artist.last_name}`
                                        }
                                        { featured_artist.is_company &&
                                            featured_artist.company_name
                                        }
                                    </Heading4>

                                    <div>
                                        <div>
                                            <div>
                                                <img src={CreditIcon} alt="Credit"/>
                                            </div>
                                            <p>{formatNumber(total.new_balance)}€</p>
                                        </div>
                                        <div>
                                            <div>
                                                <img src={PayoutIcon} alt="Payout"/>
                                            </div>
                                            <p>{formatNumber(total.net_payout)}€</p>
                                        </div>
                                    </div>
                                </FeaturedArtistCard>
                            </Link>
                        )
                    )}
                </FeaturedArtistsGrid>
            </Container>
        }
        </>
    );
};

export default ServiceStatementOverview;
