import { Release } from '../util/types';
import { apiUrl } from './config';

const fetchRelease = async (
    artist_id: string,
    release_id: string
): Promise<
    | {
          release: Release;
          versions: Release[];
          cover: { release_id: string; thumbnail_url: string; regular_url: string };
      }
    | number
> => {
    try {
        const res = await fetch(
            apiUrl + '/artist/v1/artist/' + artist_id + '/discography/release/' + release_id,
            {
                method: 'get',
                headers: new Headers({
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                })
            }
        );

        if (!res.ok) return res.status;

        return await res.json();
    } catch (e) {
        return 500;
    }
};

export default fetchRelease;
