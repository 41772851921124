import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { DefaultTheme, withTheme } from 'styled-components';
import fetchInsightData from '../../api/fetchInsightData';
import { Artist, CachedSettlementData, SettlementData, User } from '../../util/types';
import ProductHeader from '../navigation/ProductHeader';
import ErrorComponent from '../misc/Error';
import TimeFilter from '../misc/InsightTimeFilter';
import Loading from '../misc/Loading';
import { Container, ProductWrapper } from '../styled';
import products from '../../util/products';
import usePrevious from '../../util/usePrevious';
import InsightCharts from './InsightCharts';

interface Props {
    user: User | null;
    artist: Artist | null;
    smallScreen: boolean;
    theme: DefaultTheme;
}

const Insight: React.FC<Props> = ({ user, artist, smallScreen, theme }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<SettlementData | CachedSettlementData | null>();

    const fetchData = async (artist_id: string, settlements?: string[], filter?: boolean) => {
        try {
            setLoading(true);

            setData(await fetchInsightData(artist_id, settlements, filter));
        } catch (e) {
            setError(
                'An unexpected error occurred, please try reloading the page or contacting us.'
            );
        } finally {
            setLoading(false);
        }
    };

    const prevArtist = usePrevious(artist);

    // Fetch data on first load + when artist changes
    useEffect(() => {
        const artistChanged =
            prevArtist !== undefined && prevArtist?.artist_id !== artist?.artist_id;

        if (
            user &&
            artist &&
            (!data || artistChanged) &&
            artist.services.indexOf('INSIGHT') !== -1
        ) {
            fetchData(artist.artist_id);
        }
    }, [user, artist, data, prevArtist]);

    if (!user || !artist) return <Redirect to="/error" />;

    if (artist.services.indexOf('INSIGHT') === -1) return <Redirect to="/" />;

    return (
        <ProductWrapper>
            <ProductHeader
                category={2}
                title="Insight"
                description={products.filter((p) => p.name === 'INSIGHT')[0].product_description}
                anchors={['sales', 'top-selling', 'pricing', 'streaming']}
            >
                {data && (
                    <TimeFilter
                        active_settlements={data.active_settlements || data.all_settlements}
                        all_settlements={data.all_settlements}
                        fetchData={(settlements?: string[], filter?: boolean) =>
                            fetchData(artist.artist_id, settlements, filter)
                        }
                    />
                )}
            </ProductHeader>

            <Container>
                {loading ? (
                    <Loading color={theme.colors.brandTwo} />
                ) : error || !data || !artist ? (
                    <ErrorComponent message={error} />
                ) : (
                    <InsightCharts data={data} smallScreen={smallScreen} />
                )}
            </Container>
        </ProductWrapper>
    );
};

export default withTheme(Insight);
