import React, { useEffect, useState } from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { DefaultTheme, withTheme } from 'styled-components';
import fetchMasterReleases from '../../api/fetchMasterReleases';
import DiscographyReleases from './DiscographyReleases';
import ProductHeader from '../navigation/ProductHeader';
import ErrorComponent from '../misc/Error';
import Loading from '../misc/Loading';
import { ButtonSmall, Container, FlexWrapper, Select } from '../styled';
import products from '../../util/products';
import usePrevious from '../../util/usePrevious';
import { Artist, Release, User } from '../../util/types';
import NewReleaseModal from '../modals/NewReleaseModal';
import MissingAudioReleases from '../modals/MissingAudioReleases';

interface Props {
    user: User | null;
    artist: Artist | null;
    theme: DefaultTheme;
}

const Discography: React.FC<Props> = ({ user, artist, theme }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [releases, setReleases] = useState<Release[] | null>();
    const [covers, setCovers] = useState<
        { release_id: string; thumbnail_url: string; regular_url: string }[]
    >([]);
    const [category, setCategory] = useState<string>(
        localStorage.getItem('discography_category') || 'ALBUM'
    );
    const [showNewReleaseModal, setShowNewReleaseModal] = useState<boolean>(false);
    const [showMissingAudioModal, setShowMissingAudioModal] = useState<boolean>(false);

    const { url } = useRouteMatch();

    const fetchData = async (artist_id: string) => {
        try {
            setLoading(true);

            const res = await fetchMasterReleases(artist_id);

            setReleases(res.releases);
            setCovers(res.covers);
        } catch (e) {
            setError(
                'An unexpected error occurred, please try reloading the page or contacting us.'
            );
        } finally {
            setLoading(false);
        }
    };

    const prevArtist = usePrevious(artist);

    useEffect(() => {
        localStorage.setItem('discography_category', category);
    }, [category]);

    // Fetch data on first load + when artist changes
    useEffect(() => {
        const artistChanged =
            prevArtist !== undefined && prevArtist?.artist_id !== artist?.artist_id;

        if (
            user &&
            artist &&
            (!releases || artistChanged) &&
            artist.services.indexOf('DISCOGRAPHY') !== -1
        )
            fetchData(artist.artist_id);
    }, [user, artist, releases, prevArtist]);

    if (!user || !artist) return <Redirect to="/error" />;

    if (artist.services.indexOf('DISCOGRAPHY') === -1) return <Redirect to="/" />;

    const categories = [
        'Album',
        'Single/EP',
        'DVD',
        'Compilation',
        'Vinyl',
        'Video',
        'Ringtune',
        'Miscellaneous'
    ];

    const tracksWithoutAudio = !releases
        ? 0
        : releases
              .filter(
                  (release) =>
                      ['MISCELLANEOUS', 'VIDEO', 'RINGTUNE'].indexOf(
                          release.manual_category || release.category || ''
                      ) === -1
              )
              .map((release) =>
                  release.tracks
                      ? release.tracks
                      : release.releases?.map((subRelease) => subRelease.release.tracks)
              )
              .flat()
              .flat()
              .filter((track) => typeof track !== 'undefined')
              .filter(
                  (track) =>
                      track &&
                      !track.track.no_audio &&
                      !track.track.audio_uploaded &&
                      ['audio', 'video'].includes(track.track.type.toLowerCase())
              )
              .filter(
                  (v, i, a) =>
                      a.findIndex((t) => t?.track.recording_id === v?.track.recording_id) === i
              ).length;

    return (
        <>
            <ProductHeader
                title="Discography"
                category={3}
                description={
                    products.filter((p) => p.name === 'DISCOGRAPHY')[0].product_description
                }
                customButtons={() => (
                    <FlexWrapper>
                        <ButtonSmall onClick={() => setShowNewReleaseModal(true)}>
                            Add new release
                        </ButtonSmall>
                    </FlexWrapper>
                )}
                notification={() =>
                    tracksWithoutAudio ? (
                        <span id="icon" onClick={() => setShowMissingAudioModal(true)}>
                            {tracksWithoutAudio}
                        </span>
                    ) : null
                }
            >
                {!error && releases && (
                    <div>
                        <Select value={category} onChange={(e) => setCategory(e.target.value)} dark>
                            {categories.map((categoryOption, index) => (
                                <option key={index} value={categoryOption.toUpperCase()}>
                                    {categoryOption}
                                </option>
                            ))}
                        </Select>
                    </div>
                )}
            </ProductHeader>

            {showNewReleaseModal && (
                <NewReleaseModal
                    artist_id={artist.artist_id}
                    baseUrl={url}
                    onClose={() => setShowNewReleaseModal(false)}
                />
            )}

            {showMissingAudioModal && releases && (
                <MissingAudioReleases
                    releases={releases}
                    baseUrl={url}
                    onClose={() => setShowMissingAudioModal(false)}
                />
            )}

            <Container>
                {loading ? (
                    <Loading color={theme.colors.brandThree} />
                ) : error || !releases || !artist ? (
                    <ErrorComponent message={error} />
                ) : (
                    <DiscographyReleases
                        releases={releases}
                        covers={covers}
                        category={category}
                        baseUrl={url}
                    />
                )}
            </Container>
        </>
    );
};

export default withTheme(Discography);
