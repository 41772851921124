import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
    fontFamily:
        '-apple-system, system-ui, BlinkMacSystemFont, "Inter", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    fontFamily2:
        'Source Sans Pro, "Inter", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',

    border: '1px solid rgba(255, 255, 255, 0.1)',
    borderRadius1: '8px',
    borderRadius2: '16px',

    boxShadow1: '0 0 10px rgba(80, 80, 80, 0.1)',

    colors: {
        brandOne: '#aa5077',
        brandOneAccent1: '#B93A71',
        brandOneAccent2: '#DEA7BF',
        brandOneAccent3: '#ffffff',
        brandOneAccent4: '#ffffff',

        brandTwo: '#6e52af',
        brandTwoAccent1: '#7E3AB9',
        brandTwoAccent2: '#9d7aeb',
        brandTwoAccent3: '#b6a6ea',
        brandTwoAccent4: '#d3d3d3',

        brandThree: '#f7b853',
        brandThreeAccent1: '#E8A53E',
        brandThreeAccent2: '#ffffff',
        brandThreeAccent3: '#ffffff',
        brandThreeAccent4: '#ffffff',

        red: '#FF4848',
        green: '#45b258',
        black: '#181818',
        darkGrey: '#282a2f',
        lightGrey: '#969697',
        white: '#ebeef2'
    }
};

export default theme;
