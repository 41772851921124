import React, { useState } from 'react';
import { ExplanationWrapper } from '../styled';
import ModalWrapper from '../modals/ModalWrapper';

interface Props {
    text: string;
}

const Explanation: React.FC<Props> = ({ text }) => {
    const [opened, open] = useState<boolean>(false);

    return (
        <ExplanationWrapper>
            <p onMouseUp={() => !opened && open(true)}>?</p>
            {opened && <ModalWrapper onClose={() => open(false)}>{text}</ModalWrapper>}
        </ExplanationWrapper>
    );
};

export default Explanation;
