import React, { useState } from 'react';
import DayPicker, { DateUtils, Modifiers, RangeModifier } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { Button, FakeSelect, MonitorTimeFilterWrapper, MonitorTimeFilterButton } from '../styled';
import ModalWrapper from '../modals/ModalWrapper';
import { MonitorFilter } from '../../util/types';

interface Props {
    filter: MonitorFilter;
    setFilter: (filter: MonitorFilter) => void;
}

const MonitorTimeFilter: React.FC<Props> = (props) => {
    const { filter, setFilter } = props;

    const [open, setOpen] = useState<boolean>(false);
    const [custom, setCustom] = useState<boolean>(false);
    const [date, setDate] = useState<RangeModifier>({ from: undefined, to: undefined });

    const handleDayClick = (day: Date) => {
        const range = DateUtils.addDayToRange(day, date);
        setDate(range);
    };

    const { from, to } = date;
    const modifiers: Partial<Modifiers> = { start: from || undefined, end: to || undefined };

    return (
        <ModalWrapper onClose={() => setOpen(false)}>
            <MonitorTimeFilterWrapper open={open}>
                <FakeSelect open={open} onClick={() => setOpen(!open)}>
                    {typeof filter === 'string'
                        ? filter
                        : `${new Date(filter.start_interval).toLocaleDateString()} - ${new Date(
                              filter.end_interval
                          ).toLocaleDateString()}`}
                </FakeSelect>

                <div>
                    <p>Select a date range</p>

                    <div>
                        {['Last 7 days', 'Last 30 days', 'Last year', 'All time'].map((item) => (
                            <MonitorTimeFilterButton
                                key={item}
                                active={filter === item && !custom}
                                onClick={() => {
                                    // @ts-ignore
                                    setFilter(item);
                                    setOpen(false);
                                    setCustom(false);
                                }}
                            >
                                {item}
                            </MonitorTimeFilterButton>
                        ))}

                        <MonitorTimeFilterButton
                            active={typeof filter !== 'string' || custom}
                            onClick={() => {
                                setCustom(true);
                            }}
                        >
                            Custom
                        </MonitorTimeFilterButton>
                    </div>

                    {custom && (
                        <>
                            <DayPicker
                                numberOfMonths={2}
                                selectedDays={[from || undefined, { from, to }]}
                                modifiers={modifiers}
                                modifiersStyles={{ color: 'red', backgroundColor: 'blue' }}
                                onDayClick={handleDayClick}
                                firstDayOfWeek={1}
                            />
                            <Button
                                onClick={() => {
                                    if (from && to) {
                                        setFilter({
                                            start_interval: from,
                                            end_interval: to
                                        });
                                        setCustom(false);
                                        setOpen(false);
                                    }
                                }}
                            >
                                Filter
                            </Button>
                        </>
                    )}
                </div>
            </MonitorTimeFilterWrapper>
        </ModalWrapper>
    );
};

export default MonitorTimeFilter;
