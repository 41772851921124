import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import Cookies from 'universal-cookie';
import Loading from '../misc/Loading';

const TokenHandler: React.FC = () => {
    // @ts-ignore
    const { token, base64redirect } = useParams();
    const history = useHistory();

    localStorage.setItem('token', token);

    const cookies = new Cookies();
    cookies.set('authenticated', 'true', { domain: '.takkt.io', httpOnly: false, path: '/' });

    useEffect(() => {
        localStorage.setItem('token', token);

        if (!base64redirect) {
            history.push(`/`);
        } else {
            const redirect = Buffer.from(base64redirect, 'base64').toString('utf-8');

            history.push(`${redirect}`);
        }
    }, [history, token, base64redirect]);

    return <Loading fullScreen />;
};

export default TokenHandler;
