const scrollHandler = (
    title: string,
    scroll: boolean,
    currentSection: string,
    setScroll: (value: boolean) => void,
    setCurrentSection: (value: string) => void
) => {
    if (window.innerWidth >= 800) {
        if (!scroll && window.pageYOffset >= 370) setScroll(true);
        else if (scroll && window.pageYOffset < 370) setScroll(false);
    }

    if (title === 'Insight') {
        const sectionSales = document.getElementById('sales');
        const sectionTopSelling = document.getElementById('top-selling');
        const sectionPricing = document.getElementById('pricing');

        if (
            sectionPricing !== null &&
            window.scrollY > sectionPricing.offsetTop + sectionPricing.offsetHeight
        ) {
            if (currentSection !== 'streaming') setCurrentSection('streaming');
        } else if (
            sectionTopSelling !== null &&
            window.scrollY > sectionTopSelling.offsetTop + sectionTopSelling.offsetHeight
        ) {
            if (currentSection !== 'pricing') setCurrentSection('pricing');
        } else if (
            sectionSales !== null &&
            window.scrollY > sectionSales.offsetTop + sectionSales.offsetHeight
        ) {
            if (currentSection !== 'top-selling') setCurrentSection('top-selling');
        } else {
            if (currentSection !== 'sales') setCurrentSection('sales');
        }
    } else if (title === 'Monitor') {
        const youtube = document.getElementById('youtube');
        const spotify = document.getElementById('spotify');
        const apple = document.getElementById('apple');
        const amazon = document.getElementById('amazon');

        if (amazon !== null && window.scrollY > amazon.offsetTop + amazon.offsetHeight) {
            if (currentSection !== 'deezer') setCurrentSection('deezer');
        } else if (apple !== null && window.scrollY > apple.offsetTop + apple.offsetHeight) {
            if (currentSection !== 'amazon') setCurrentSection('amazon');
        } else if (spotify !== null && window.scrollY > spotify.offsetTop + spotify.offsetHeight) {
            if (currentSection !== 'apple') setCurrentSection('apple');
        } else if (youtube !== null && window.scrollY > youtube.offsetTop + youtube.offsetHeight) {
            if (currentSection !== 'spotify') setCurrentSection('spotify');
        } else {
            if (currentSection !== 'youtube') setCurrentSection('youtube');
        }
    }
};

export default scrollHandler;
