// Combine title and title_addition
// with the only exception being: title_addition is a note that the release is not available anymore
const combineTitle = (title: string, title_addition?: string): string => {
    if (title_addition && !title_addition.includes('not available anymore'))
        title += ` ${title_addition}`;

    return title || '';
};

export default combineTitle;
