import React from 'react';
import { Redirect } from 'react-router-dom';
import { Artist, User } from '../../util/types';
import { Container, ContentWrapper, Heading1, Heading4, DashboardHeader } from '../styled';
import Products from './Products';

interface Props {
    user: User | null;
    artist: Artist | null;
}

const Dashboard: React.FC<Props> = (props) => {
    const { user, artist } = props;

    if (!user || !artist) return <Redirect to="/error" />;

    return (
        <Container>
            <ContentWrapper>
                <DashboardHeader artistImg={artist.profile_image_url || ''}>
                    <Heading4>Dashboard</Heading4>
                    <Heading1>
                        <div />
                        {artist.pseudonym}
                    </Heading1>
                </DashboardHeader>

                <Products user={user} artist={artist} />
            </ContentWrapper>
        </Container>
    );
};

export default Dashboard;
