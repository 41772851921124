import { apiUrl } from './config';

const downloadServiceStatementZip = async (
    service_statement_id: string
): Promise<Blob> => {
    try {
        const res = await fetch(apiUrl + '/contract/v1/auth/service_statements/' + service_statement_id + '/zip', {
            method: 'get',
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            })
        });

        if (!res.ok) {
            console.log("error downloading")
            throw new Error();
        }

        return await res.blob();
    } catch (e) {
        console.log(e)
        throw new Error();
    }
};

export default downloadServiceStatementZip;
