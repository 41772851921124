import { apiUrl } from './config';

const fetchPreprocessingStatus = async (
    artist_id: string
): Promise<{
    processing: boolean,
    range: string
}> => {
    try {
        const res = await fetch(
            apiUrl + '/music/v1/auth/pipelines/preprocessing/status?' +
            new URLSearchParams({
                artist_id
            }),
            {
                method: 'get',
                headers: new Headers({
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                })
            }
        );
        if (!res.ok) throw new Error();
        return await res.json();
    } catch (e) {
        throw new Error();
    }
};

const startPreprocessingPipeline = async (
    artist_id: string,
    year?: string,
    period?: string
): Promise<boolean> => {
    try {
        let urlParams: URLSearchParams = new URLSearchParams({
            artist_id
        })
        if (year && period) {
            urlParams = new URLSearchParams({
                artist_id,
                year,
                period
            })
        } else if (year) {
            new URLSearchParams({
                artist_id,
                year
            })
        }

        const res = await fetch(
            apiUrl + '/music/v1/auth/pipelines/preprocessing/start?' +
            urlParams,
            {
                method: 'get',
                headers: new Headers({
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                })
            }
        );

        if (!res.ok) return false;// throw new Error();

        return true;
    } catch (e) {
        throw new Error();
    }
}

export {
    fetchPreprocessingStatus,
    startPreprocessingPipeline
};
