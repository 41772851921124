import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

 html {
   font-family: ${({ theme }) => theme.fontFamily};
   font-size: 10px;
   scroll-behavior: smooth;
  }
 
 body {
    
    font-size: 1.6rem;
  }

  #root {
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.lightGrey};
  }
  
  img {
    max-width: 100%;
  }

  a {
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
  }

  p {
    line-height: 1.5;
  }

  input[type=date], textarea, button {
    font-family: ${({ theme }) => theme.fontFamily};
  }
`;

export default GlobalStyle;
