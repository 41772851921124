import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory, Redirect } from 'react-router-dom';
import { DefaultTheme, withTheme } from 'styled-components';

import TrackDetailsModal from '../modals/TrackDetailsModal';
import UploadReleaseCover from '../modals/UploadReleaseCover';
import ChangeRequestModal from '../modals/ChangeRequestModal';
import ErrorComponent from '../misc/Error';
import Loading from '../misc/Loading';

import {
    DiscographyList,
    Heading1,
    Heading3,
    Heading4,
    ReleaseVersion,
    ReleaseHeader,
    SubRelease,
    DiscographyDetailsTable,
    Container,
    ContentWrapper,
    BackButton,
    ButtonSmall
} from '../styled';

import fetchRelease from '../../api/fetchRelease';

import combineTitle from '../../util/combineTitle';
import { Release, Artist, User } from '../../util/types';
import usePrevious from '../../util/usePrevious';

import ChevronRight from '../../assets/chevron-right.svg';
import UploadIcon from '../../assets/upload.svg';
import CompleteIcon from '../../assets/check.svg';
import NoIcon from '../../assets/no.svg';
import AlbumIcon from '../../assets/album.svg';
import VinylIcon from '../../assets/vinyl.svg';
import SingleIcon from '../../assets/single.svg';
import RingtuneIcon from '../../assets/ringtune.svg';
import DVDIcon from '../../assets/dvd.svg';
import VideoIcon from '../../assets/video.svg';
import CompilationIcon from '../../assets/compilation.svg';
import MiscellaneousIcon from '../../assets/miscellaneous.svg';
import DiscIcon from '../../assets/disc.svg';

interface Props {
    user: User | null;
    artist: Artist | null;
    theme: DefaultTheme;
}

const DiscographyDetails: React.FC<Props> = ({ user, artist, theme }) => {
    const { release_id } = useParams<{ release_id: string }>();
    const { length: historyLength, goBack, push: redirectTo } = useHistory();

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<{
        release: Release;
        versions: Release[];
        cover: { release_id: string; thumbnail_url: string; regular_url: string };
    }>();
    const [showVersions, setShowVersions] = useState<boolean>(false);
    const [trackDetails, setTrackDetails] = useState<string | null>(null);
    const [showCoverUpload, setShowCoverUpload] = useState<boolean>(false);
    const [showChangeRequest, setShowChangeRequest] = useState<boolean>(false);

    const fetchData = async (artist_id: string, release_id: string) => {
        try {
            setLoading(true);

            const res = await fetchRelease(artist_id, release_id);
            console.log(res)

            if (typeof res === 'number') {
                switch (res) {
                    case 404:
                        redirectTo('/discography');
                        break;
                    default:
                        setError(
                            'An unexpected error occurred, please try reloading the page or contacting us.'
                        );
                        break;
                }
            } else {
                setData(res);
                setLoading(false);
            }
        } catch (e) {
            setError(
                'An unexpected error occurred, please try reloading the page or contacting us.'
            );
            setLoading(false);
        }
    };

    const prevArtist = usePrevious(artist);
    const prevReleaseId = usePrevious(release_id);

    // Fetch data on first load + when artist changes
    useEffect(() => {
        const artistChanged =
            prevArtist !== undefined && prevArtist?.artist_id !== artist?.artist_id;
        const releaseIdChanged = prevReleaseId !== undefined && prevReleaseId !== release_id;

        if (
            user &&
            artist &&
            (!data || artistChanged || releaseIdChanged) &&
            artist.services.indexOf('DISCOGRAPHY') !== -1
        )
            fetchData(artist.artist_id, release_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, artist, release_id, data, prevArtist, prevReleaseId]);

    if (!user || !artist) return <Redirect to="/error" />;

    if (artist.services.indexOf('DISCOGRAPHY') === -1) return <Redirect to="/" />;

    if (loading) return <Loading color={theme.colors.brandThree} fullScreen />;

    if (error || !data) return <ErrorComponent message={error} fullScreen />;

    const { release, versions, cover } = data;

    const subReleases = release.releases || [];

    const highlightedTrack = [release, ...subReleases.map((subRelease) => subRelease.release)]
        .map((rel) => rel.tracks)
        .flat()
        .filter((track) => track && track.track.recording_id === trackDetails)[0];

    let categoryIcon: string;

    switch (release.manual_category || release.category) {
        case 'ALBUM':
            categoryIcon = AlbumIcon;
            break;
        case 'SINGLE/EP':
            categoryIcon = SingleIcon;
            break;
        case 'DVD':
            categoryIcon = DVDIcon;
            break;
        case 'VINYL':
            categoryIcon = VinylIcon;
            break;
        case 'VIDEO':
            categoryIcon = VideoIcon;
            break;
        case 'RINGTUNE':
            categoryIcon = RingtuneIcon;
            break;
        case 'COMPILATION':
            categoryIcon = CompilationIcon;
            break;
        default:
            categoryIcon = MiscellaneousIcon;
            break;
    }

    return (
        <Container>
            <ContentWrapper>
                <BackButton
                    onClick={() => (historyLength <= 2 ? redirectTo('/discography') : goBack())}
                >
                    <img src={ChevronRight} alt="Go back" />
                    <p>Go back</p>
                </BackButton>

                <ReleaseHeader coverUrl={cover ? cover.regular_url : ''}>
                    <div onClick={() => setShowCoverUpload(true)}>
                        <div />
                        <img src={categoryIcon} alt="Cover placeholder" />
                    </div>

                    <div>
                        <Heading1>
                            {combineTitle(
                                release.manual_title || release.title,
                                release.title_addition
                            )}
                        </Heading1>

                        <p>
                            <span>{release.type}</span>
                            <span>|</span>
                            <span>{release.manual_year || release.year}</span>
                            <span>|</span>
                            <span>
                                {release.tracks && release.tracks.length > 0
                                    ? release.tracks.length +
                                      (release.tracks.length === 1 ? ' track' : ' tracks')
                                    : 'Bundle'}
                            </span>
                            <span>|</span>
                            <span>
                                UPC: {release.upc || 'no upc available'}
                            </span>
                        </p>

                        <ButtonSmall onClick={() => setShowChangeRequest(true)}>
                            Report mistake
                        </ButtonSmall>
                    </div>
                </ReleaseHeader>

                {subReleases.length === 0 ? (
                    <>
                        <DiscographyDetailsTable>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Title</th>
                                    <th>Duration</th>
                                </tr>
                            </thead>
                            <tbody>
                                {release.tracks &&
                                    release.tracks.map(({ track, number, disc }) => {
                                        const hasDiscs =
                                            release.tracks &&
                                            release.tracks.filter(({ disc }) => disc > 1).length >
                                                0;

                                        return [
                                            <tr key={disc} className="disc-row">
                                                {hasDiscs && number === 1 && (
                                                    <td>
                                                        <img src={DiscIcon} alt={`Disc ${disc}`} />
                                                        <p>Disc {disc}</p>
                                                    </td>
                                                )}
                                            </tr>,
                                            <tr
                                                key={track.recording_id}
                                                onClick={() => setTrackDetails(track.recording_id)}
                                            >
                                                <td>{number}</td>
                                                <td>
                                                    {combineTitle(
                                                        track.title,
                                                        track.title_addition
                                                    )}
                                                </td>
                                                <td>{track.duration}</td>
                                                <td>
                                                    {track.audio_uploaded ? (
                                                        <img
                                                            src={CompleteIcon}
                                                            alt="Audio file uploaded"
                                                        />
                                                    ) : track.no_audio ? (
                                                        <img src={NoIcon} alt="No audio file" />
                                                    ) : (
                                                        <img
                                                            src={UploadIcon}
                                                            alt="Upload audio file"
                                                        />
                                                    )}
                                                </td>
                                            </tr>
                                        ];
                                    })}
                            </tbody>
                        </DiscographyDetailsTable>
                    </>
                ) : (
                    subReleases
                        .sort((a, b) => a.number - b.number)
                        .map((subRelease, index) => (
                            <SubRelease key={index}>
                                <Heading3 margin>
                                    {combineTitle(
                                        subRelease.release.manual_title || subRelease.release.title,
                                        subRelease.release.title_addition
                                    )}
                                </Heading3>
                                <DiscographyDetailsTable>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Title</th>
                                            <th>Duration</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {subRelease.release.tracks &&
                                            subRelease.release.tracks.map(
                                                ({ track, number, disc }) => {
                                                    const hasDiscs =
                                                        subRelease.release.tracks &&
                                                        subRelease.release.tracks.filter(
                                                            ({ disc }) => disc > 1
                                                        ).length > 0;

                                                    return [
                                                        <tr key={disc} className="disc-row">
                                                            {hasDiscs && number === 1 && (
                                                                <td>
                                                                    <img
                                                                        src={DiscIcon}
                                                                        alt={`Disc ${disc}`}
                                                                    />
                                                                    <p>Disc {disc}</p>
                                                                </td>
                                                            )}
                                                        </tr>,
                                                        <tr
                                                            key={track.recording_id}
                                                            onClick={() =>
                                                                setTrackDetails(track.recording_id)
                                                            }
                                                        >
                                                            <td>{number}</td>
                                                            <td>
                                                                {combineTitle(
                                                                    track.title,
                                                                    track.title_addition
                                                                )}
                                                            </td>
                                                            <td>{track.duration}</td>
                                                            <td>
                                                                {track.audio_uploaded ? (
                                                                    <img
                                                                        src={CompleteIcon}
                                                                        alt="Audio file uploaded"
                                                                    />
                                                                ) : track.no_audio ? (
                                                                    <img
                                                                        src={NoIcon}
                                                                        alt="No audio file"
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src={UploadIcon}
                                                                        alt="Upload audio file"
                                                                    />
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ];
                                                }
                                            )}
                                    </tbody>
                                </DiscographyDetailsTable>
                            </SubRelease>
                        ))
                )}

                {versions && versions.length > 0 && (
                    <>
                        <Heading3 expand onClick={() => setShowVersions(!showVersions)}>
                            Versions ({versions.length})
                            <img src={ChevronRight} alt="Expand release versions" />
                        </Heading3>

                        <DiscographyList open={showVersions}>
                            {versions
                                .sort((a, b) =>
                                    combineTitle(
                                        a.manual_title || a.title,
                                        a.title_addition
                                    ).localeCompare(
                                        combineTitle(b.manual_title || b.title, b.title_addition)
                                    )
                                )
                                .sort(
                                    (a, b) => (b.manual_year || b.year) - (a.manual_year || a.year)
                                )
                                .map((release, index) => (
                                    <ReleaseVersion key={index}>
                                        <Link to={`/discography/${release.release_id}`}>
                                            <Heading4>
                                                {combineTitle(
                                                    release.manual_title || release.title,
                                                    release.title_addition
                                                )}
                                            </Heading4>
                                            <p>{release.manual_year || release.year}</p>
                                        </Link>
                                    </ReleaseVersion>
                                ))}
                        </DiscographyList>
                    </>
                )}

                {trackDetails && release.tracks && highlightedTrack && (
                    <TrackDetailsModal
                        artistId={artist.artist_id}
                        track={highlightedTrack.track}
                        onClose={() => setTrackDetails(null)}
                        refetch={() => fetchData(artist.artist_id, release_id)}
                    />
                )}

                {showCoverUpload && (
                    <UploadReleaseCover
                        artistId={artist.artist_id}
                        currentCoverUrl={cover.regular_url}
                        release={release}
                        onClose={() => setShowCoverUpload(false)}
                        refetch={() => fetchData(artist.artist_id, release_id)}
                    />
                )}

                {showChangeRequest && (
                    <ChangeRequestModal
                        artistId={artist.artist_id}
                        userId={user.user_id}
                        release={release}
                        onClose={() => setShowChangeRequest(false)}
                    />
                )}
            </ContentWrapper>
        </Container>
    );
};

export default withTheme(DiscographyDetails);
