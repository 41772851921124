import styled from 'styled-components';
import ChevronRight from '../../assets/chevron-right.svg';
import DownIcon from '../../assets/down.svg';

interface TimeFilterWrapperProps {
    open?: boolean;
}

const TimeFilterWrapper = styled.div<TimeFilterWrapperProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    max-width: 60rem;
    min-height: 10rem;
    margin: 5rem auto;
    transition: all 0.2s;

    button {
        border-radius: ${({ theme }) => theme.borderRadius1};
    }

    > div:last-of-type {
        display: none;

        grid-template-columns: repeat(4, auto);
        gap: 1.5rem;
        align-items: center;
        background-color: ${({ theme }) => theme.colors.darkGrey};
        border-radius: ${({ theme }) => theme.borderRadius1};
        padding: 1.5rem;

        p {
            font-size: 1.8rem;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.white};
        }
    }

    > span {
        background-color: ${({ theme }) => theme.colors.darkGrey};
        border-radius: ${({ theme }) => theme.borderRadius1};
        padding: 1.5rem;
    }

    ${(props) =>
        props.open &&
        `
        > div:last-of-type {
            display: grid;
        }
    `}

    @media (max-width: 500px) {
        align-items: stretch;

        > div:last-of-type {
            grid-template-columns: auto auto auto;
            gap: 2rem 0;

            p {
                text-align: center;
            }

            button {
                grid-area: 2 / 1 / span 1 / span 3;
            }
        }
    }
`;

interface MonitorTimeFilterWrapperProps {
    open?: boolean;
}

const MonitorTimeFilterWrapper = styled.div<MonitorTimeFilterWrapperProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    max-width: 60rem;
    min-height: 10rem;
    margin: 5rem auto;
    transition: all 0.2s;

    button {
        border-radius: ${({ theme }) => theme.borderRadius1};
    }

    > div:last-of-type {
        display: none;

        max-width: 100%;
        grid-template-columns: 1fr;
        gap: 1.5rem;
        align-items: center;
        background-color: ${({ theme }) => theme.colors.darkGrey};
        border-radius: ${({ theme }) => theme.borderRadius1};
        padding: 2rem;

        > div:first-of-type {
            display: grid;
            grid-template-columns: repeat(5, auto);
            gap: 1rem;
            padding: 0.5rem 0 1rem 0;
        }

        p {
            font-size: 1.6rem;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.white};
        }

        > span {
            text-align: center;
            padding-top: 0.5rem;
            cursor: pointer;
        }

        .DayPicker {
            color: ${({ theme }) => theme.colors.lightGrey} !important;
            font-family: ${({ theme }) => theme.fontFamily} !important;

            &-Caption {
                color: ${({ theme }) => theme.colors.white} !important;
                text-align: center;
            }

            &-Month {
                font-size: 1.2rem !important;
            }

            &-Weekday {
                font-size: 1.4rem !important;
            }

            &-NavBar {
                display: flex;
                justify-content: space-between;
                margin-bottom: -3rem;
            }

            &-NavButton {
                position: relative;
                top: auto;
                right: auto;
                margin-top: 0;
                border: ${({ theme }) => theme.border};
                padding: 1rem;
                border-radius: 8px;
                background-image: none;
                background-color: ${({ theme }) => theme.colors.white};

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    color: white;
                    background-image: url(${ChevronRight});
                    background-repeat: no-repeat;
                    background-size: 40%;
                    background-position: 50%;
                }

                &--prev {
                    &::after {
                        transform: scaleX(-1);
                    }
                }
            }

            &-Day {
                font-size: 1.4rem !important;
                border-radius: 0 !important;

                &--selected {
                    &:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                        background-color: ${({ theme }) => theme.colors.brandOne}90 !important;
                        color: ${({ theme }) => theme.colors.white};
                    }
                }

                &--today {
                    color: ${({ theme }) => theme.colors.white};
                }

                &--start,
                &--end  {
                    background-color: ${({ theme }) => theme.colors.brandOne} !important;
                    color: ${({ theme }) => theme.colors.white};
                }
            }
        }
    }

    > span {
        background-color: ${({ theme }) => theme.colors.darkGrey};
        border-radius: ${({ theme }) => theme.borderRadius1};
        padding: 1.5rem;
    }

    ${(props) =>
        props.open &&
        `
        > div:last-of-type {
            display: grid;
        }
    `}

    @media (max-width: 700px) {
        > div:last-of-type {
            max-width: 90vw;

            > div:first-of-type {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 0.75rem;
                max-width: 100%;
            }
        }
    }

    @media (max-width: 500px) {
        align-items: stretch;
    }
`;

interface MonitorTimeFilterButtonProps {
    active?: boolean;
}

const MonitorTimeFilterButton = styled.div<MonitorTimeFilterButtonProps>`
    display: inline-block;
    background-color: ${({ theme }) => theme.colors.white}20;
    border-radius: ${({ theme }) => theme.borderRadius1};
    padding: 1rem;
    text-align: center;
    white-space: nowrap;
    transition: all 0.1s;
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.colors.white}40;
        color: ${({ theme }) => theme.colors.white};
    }

    ${({ active, theme }) =>
        active &&
        `
            background-color: ${theme.colors.white}40;
            color: ${theme.colors.white};
    `}

    @media (max-width: 700px) {
        padding: 0.75rem;
        font-size: 1.5rem;
        border-radius: 5px;
    }
`;

interface SelectProps {
    dark?: boolean;
}

const Select = styled.select<SelectProps>`
    padding: 1.4rem 1.5rem;
    padding-right: 4rem;
    border: none;
    border-radius: ${({ theme }) => theme.borderRadius1};
    width: auto;
    outline: none;
    background-color: ${({ theme }) => theme.colors.white}10;
    box-shadow: ${({ theme }) => theme.boxShadow1};
    font-size: 1.6rem;
    line-height: 1;
    color: ${({ theme }) => theme.colors.white};
    display: table-row;
    cursor: pointer;

    option {
        display: table-cell;
    }

    -o-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(${DownIcon});
    background-size: 1.2rem;
    background-repeat: no-repeat;
    background-position-x: calc(100% - 1.5rem);
    background-position-y: 50%;

    &::placeholder {
        color: ${({ theme }) => theme.colors.lightGrey};
    }

    ${(props) =>
        props.dark &&
        `
        background-color: ${props.theme.colors.darkGrey};
    `}
`;

interface FakeSelectProps {
    open?: boolean;
}

const FakeSelect = styled.div<FakeSelectProps>`
    padding: 1.4rem 1.5rem;
    padding-right: 4rem;
    border-radius: ${({ theme }) => theme.borderRadius1};
    width: auto;
    background-color: ${({ theme }) => theme.colors.darkGrey};
    box-shadow: ${({ theme }) => theme.boxShadow1};
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 1.5rem;

    background-image: url(${DownIcon});
    background-size: 1.2rem;
    background-repeat: no-repeat;
    background-position-x: calc(100% - 1.5rem);
    background-position-y: 50%;
    cursor: pointer;
`;

interface InputProps {
    narrow?: boolean;
    transparent?: boolean;
}

const Input = styled.input<InputProps>`
    appearance: none;
    outline: none;
    border: none;
    background-color: ${({ theme }) => theme.colors.darkGrey};
    border-radius: ${({ theme }) => theme.borderRadius1};
    padding: 1.5rem;
    font-size: 1.6rem;
    line-height: 1;
    color: ${({ theme }) => theme.colors.white};
    width: 100%;
    max-width: 30rem;

    &[type='number'] {
        width: 10rem;
    }

    ${({ narrow }) =>
        narrow &&
        `
        width: 15rem;
    `}

    ${({ theme, transparent }) =>
        transparent &&
        `
        background-color: ${theme.colors.lightGrey}20;
    `}
`;

const TextArea = styled.textarea`
    appearance: none;
    outline: none;
    border: none;
    background-color: ${({ theme }) => theme.colors.lightGrey}20;
    border-radius: ${({ theme }) => theme.borderRadius1};
    padding: 1.5rem;
    font-size: 1.6rem;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.white};
    width: 100%;
    height: 15rem;
    resize: none;
`;

const DateInput = styled.input`
    padding: 1rem 1.5rem;
    border: none;
    border-radius: ${({ theme }) => theme.borderRadius1};
    width: auto;
    outline: none;
    background-color: ${({ theme }) => theme.colors.white}10;
    box-shadow: ${({ theme }) => theme.boxShadow1};
    font-size: 1.6rem;
    line-height: 1;
    color: ${({ theme }) => theme.colors.white};

    &::placeholder {
        color: ${({ theme }) => theme.colors.lightGrey};
    }
`;

const HorizontalInputWrapper = styled.div`
    display: grid !important;
    grid-template-columns: 1.5fr 1fr 1fr;
    gap: 1.5rem;

    input,
    select {
        width: 100% !important;
    }
`;

export {
    TimeFilterWrapper,
    MonitorTimeFilterWrapper,
    MonitorTimeFilterButton,
    Select,
    FakeSelect,
    Input,
    TextArea,
    DateInput,
    HorizontalInputWrapper
};
