import {apiUrl} from "./config";


const fetchServiceStatementPreSignedUrl = async (
    service_statement_id: string
): Promise<string> => {

    try {
        console.log(service_statement_id)

        const res = await fetch(apiUrl + '/contract/v1/auth/service_statements/' + service_statement_id + '/pre_signed_url', {
            method: 'get',
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            })
        });

        if (!res.ok) throw new Error();

        return await res.text() ;
    } catch (e) {
        throw new Error();
    }
}

export default fetchServiceStatementPreSignedUrl
