import { apiUrl } from './config';

const uploadDocuments = async (
    artistId: string,
    type: 'CONTRACT' | 'STATEMENT',
    files: File[],
    settlementInfo?: { cycle: 'HALF' | 'QUARTER'; year: number; period: string }
): Promise<void> => {
    try {
        const formData = new FormData();
        formData.append('type', type);
        if (settlementInfo) formData.append('settlement_info', JSON.stringify(settlementInfo));

        for (const file of files) formData.append('documents[]', file);

        const res = await fetch(apiUrl + '/artist/v1/artist/' + artistId + '/documents/upload', {
            method: 'post',
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }),
            body: formData
        });

        if (!res.ok) throw new Error();

        return;
    } catch (e) {
        throw new Error();
    }
};

export default uploadDocuments;
