import { Document } from '../util/types';
import {apiUrl} from "./config";

const fetchDocuments = async (artist_id: string): Promise<Document[]> => {
    try {
        const res = await fetch(apiUrl + '/artist/v1/artist/' + artist_id + '/files', {
            method: 'get',
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            })
        });

        if (!res.ok) throw new Error();

        return await res.json();
    } catch (e) {
        throw new Error();
    }
};

export default fetchDocuments;
