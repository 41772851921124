import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import formatNumber from '../../util/formatNumber';
import { ChartStyles } from '../../util/types';
import { LineChartWrapper, Select } from '../styled';
import ChartWrapper from './ChartWrapper';

interface Props {
    labels: Array<string>;
    explanation?: string;
    title1: string;
    description1: string;
    unit1: string;
    unit2: string;
    values1: Array<Array<number>>;
    values2: Array<Array<number>>;
    title2: string;
    description2: string;
    customKeys?: Array<string>;
    invalidUntil?: string;
    chartExplanation?: string;
    chartStyles: ChartStyles;
    enableTooltips?: boolean;
}

const LineChart: React.FC<Props> = (props: Props) => {
    const {
        labels,
        explanation,
        title1,
        description1,
        unit1,
        title2,
        description2,
        unit2,
        values1,
        values2,
        customKeys,
        invalidUntil,
        chartExplanation,
        chartStyles,
        enableTooltips
    } = props;

    const [state, setState] = useState<number>(1);

    const title = state === 1 ? title1 : title2;
    const description = state === 1 ? description1 : description2;
    const unit = state === 1 ? unit1 : unit2;
    const values = state === 1 ? values1 : values2;
    const accentColor = state === 1 ? chartStyles.brand[0] : chartStyles.brand[2];

    let filterOutIndex = 0;
    const datasets: any[] = [];

    // Get max & min values for sales outside of period "graph" + y-axis boundaries
    let maxValue = 0,
        minValue = 0;
    values.forEach((valuesArray) => {
        valuesArray.forEach((v) => {
            if (v > maxValue) {
                maxValue = v;
            } else if (v < minValue) {
                minValue = v;
            }
        });
    });
    maxValue *= 1.05;
    minValue -= maxValue * 0.05;

    // Mark sales outside of sales period block by drawing a horizontal line for each max & min value with a background
    if (invalidUntil) {
        const matchingLabel = `${invalidUntil.substr(0, 4)}-${
            invalidUntil.slice(-1) === '1' ? '01' : '07'
        }`;
        labels.forEach((l, i) => {
            if (l === matchingLabel) filterOutIndex = i;
        });

        const maxGreyData: number[] = [],
            minGreyData: number[] = [];
        for (let i = 0; i < filterOutIndex; i++) maxGreyData.push(maxValue);
        for (let i = 0; i < filterOutIndex; i++) minGreyData.push(minValue);

        datasets.push({
            title,
            label: 'maxGreyData',
            data: maxGreyData,
            borderColor: 'transparent',
            backgroundColor: `${chartStyles.lightGrey}20`,
            pointHitRadius: 0,
            pointHoverRadius: 0
        });

        datasets.push({
            title,
            label: 'minGreyData',
            data: minGreyData,
            borderColor: 'transparent',
            backgroundColor: `${chartStyles.lightGrey}20`,
            pointHitRadius: 0,
            pointHoverRadius: 0
        });
    }

    values.forEach((valuesArray, index) => {
        datasets.push({
            label: customKeys ? customKeys[index] : undefined,
            title,
            data: valuesArray.map((v: number) => (isNaN(v) ? 0 : v)),
            borderColor: values.length > 1 ? chartStyles.brand[index] : undefined,
            pointBackgroundColor: values.length > 1 ? chartStyles.brand[index] : undefined,
            fill: false
        });
    });

    const options = {
        plugins: {
            datalabels: { display: false },
            legend: {
                display: values1.length > 1,
                position: 'bottom',
                labels: {
                    boxWidth: 9,
                    padding: 50,
                    font: {
                        family: chartStyles.fontFamily,
                        size: 14
                    },
                    color: chartStyles.white,
                    usePointStyle: true
                }
            },
            tooltip: {
                intersect: false,
                mode: customKeys ? 'index' : 'nearest',
                backgroundColor: chartStyles.white,
                titleFont: {
                    family: chartStyles.fontFamily,
                    size: 14,
                    weight: 'bold'
                },
                titleColor: chartStyles.black,
                titleSpacing: 5,
                bodyFont: {
                    family: chartStyles.fontFamily,
                    size: 15,
                    weight: 'normal'
                },
                bodyColor: chartStyles.black,
                bodySpacing: 7.5,
                cornerRadius: 5,
                borderWidth: 0,
                padding: { x: 15, y: 10 },
                caretPadding: 20,
                displayColors: false,
                callbacks: {
                    label: ({
                        datasetIndex,
                        dataIndex,
                        raw
                    }: {
                        raw: number;
                        dataIndex: number;
                        datasetIndex: number;
                    }) => {
                        if (customKeys && values.length === 4) {
                            return `${customKeys[datasetIndex]}: ${formatNumber(
                                datasets[datasetIndex].data[dataIndex]
                            )}${unit}`;
                        } else {
                            return `${formatNumber(raw)}${unit}`;
                        }
                    }
                },
                filter: ({ datasetIndex }: any) =>
                    enableTooltips || customKeys || datasetIndex === 2
            }
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 30,
                bottom: 0
            }
        },
        title: {
            display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            point: {
                radius: 0,
                borderWidth: 5,
                backgroundColor: chartStyles.darkGrey,
                borderColor: accentColor,
                hoverRadius: 0,
                hitRadius: 5,
                hoverBorderWidth: 5
            },
            line: {
                tension: 0.4,
                borderWidth: (() => {
                    if (values[0].length > 1000) return 1;
                    else if (values[0].length > 600) return 2;
                    else if (values[0].length > 300) return 3;

                    return 4;
                })(),
                borderColor: accentColor,
                fill: true,
                borderCapStyle: 'round'
            }
        },
        scales: {
            xAxis: {
                ticks: {
                    display: true,
                    maxTicksLimit: window.innerWidth >= 1100 ? 15 : 10,
                    font: {
                        size: 16
                    },
                    color: chartStyles.lightGrey,
                    padding: 10
                },
                grid: {
                    display: true,
                    drawBorder: true,
                    color: `${chartStyles.lightGrey}33`,
                    lineWidth: 2
                }
            },
            yAxis: {
                max: invalidUntil && values.length === 1 ? maxValue : undefined,
                min: invalidUntil && values.length === 1 ? minValue : undefined,
                beginAtZero: !invalidUntil,
                ticks: {
                    display: true,
                    maxTicksLimit: 8,
                    font: {
                        size: 14
                    },
                    color: chartStyles.lightGrey,
                    padding: 5,
                    callback: (value: any, index: number, allValues: any) =>
                        invalidUntil && (index === allValues.length - 1 || index === 0)
                            ? undefined
                            : formatNumber(value) + unit
                },
                grid: {
                    display: false,
                    drawBorder: true
                }
            }
        }
    };

    const data = {
        labels,
        datasets
    };

    return (
        <ChartWrapper
            explanation={explanation}
            title={title}
            description={description}
            customTitle={
                <Select value={state} onChange={(e) => setState(parseFloat(e.target.value))}>
                    <option value={1}>{title1}</option>
                    <option value={2}>{title2}</option>
                </Select>
            }
        >
            <LineChartWrapper legend={values1.length > 1}>
                {chartExplanation && <p>{chartExplanation}</p>}

                <div>
                    <Line type="line" data={data} options={options} />
                </div>
            </LineChartWrapper>
        </ChartWrapper>
    );
};

export default LineChart;
