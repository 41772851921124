import React, { useState } from 'react';
import ModalWrapper from './ModalWrapper';
import {
    Button,
    ChangeRequest,
    FullscreenOverlay,
    Heading4,
    InfoMessage,
    TextArea
} from '../styled';
import createChangeRequest from '../../api/createChangeRequest';
import { Release } from '../../util/types';

interface Props {
    artistId: string;
    userId: string;
    release: Release;
    onClose: () => void;
}

const ChangeRequestModal: React.FC<Props> = ({ artistId, userId, release, onClose }) => {
    const [message, setMessage] = useState<string>('');
    const [success, setSuccess] = useState<boolean | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const onSubmit = async () => {
        if (message.length > 0) {
            try {
                setLoading(true);

                await createChangeRequest(artistId, userId, release.release_id, message);

                setSuccess(true);
                setLoading(false);
            } catch (e) {
                setSuccess(false);
                setLoading(false);
            }
        }
    };

    return (
        <FullscreenOverlay>
            <ModalWrapper onClose={onClose}>
                <ChangeRequest>
                    <div>
                        <Heading4>Report a mistake</Heading4>
                        {success === true ? (
                            <InfoMessage success marginTop>
                                Thanks for letting us know, we'll look into it.
                            </InfoMessage>
                        ) : (
                            <>
                                <TextArea
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    placeholder="What about this release is wrong?"
                                />
                                <div>
                                    <p onClick={onClose}>Cancel</p>
                                    <Button onClick={onSubmit}>
                                        {loading ? 'Loading...' : 'Send'}
                                    </Button>
                                </div>
                            </>
                        )}
                        {success === false && (
                            <InfoMessage error marginTop>
                                Something went wrong, please try again.
                            </InfoMessage>
                        )}
                    </div>
                </ChangeRequest>
            </ModalWrapper>
        </FullscreenOverlay>
    );
};

export default ChangeRequestModal;
