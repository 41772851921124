import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import fetchUser from '../../api/fetchUser';
import { Artist, User } from '../../util/types';
import Loading from '../misc/Loading';

interface Props extends RouteProps {
    user: User | null;
    setUser: Dispatch<SetStateAction<User | null>>;
    setArtist: Dispatch<SetStateAction<Artist | null>>;
}

const ProtectedRoute: React.FC<Props> = (props) => {
    const { user, setUser, setArtist } = props;

    const [loading, setLoading] = useState<boolean>(true);
    const [authenticated, setAuthenticated] = useState<boolean>(false);

    useEffect(() => {
        if (localStorage.getItem('token')) {
            if (!user) {
                fetchUser()
                    .then((fetchedUser: User) => {
                        try {
                            if (
                                !fetchedUser ||
                                !fetchedUser.artists ||
                                fetchedUser.artists.length === 0
                            )
                                throw new Error('User does not have any artists');

                            const currentArtistId = localStorage.getItem('artist_id');

                            setUser(fetchedUser);
                            setArtist(
                                fetchedUser.artists.filter(
                                    (artist) => artist.artist_id === currentArtistId
                                )[0] || fetchedUser.artists[0]
                            );
                            setAuthenticated(true);
                            setLoading(false);
                        } catch (e) {
                            setAuthenticated(false);
                            setLoading(false);
                        }
                    })
                    .catch(() => {
                        setAuthenticated(false);
                        setLoading(false);
                    });
            }
        } else {
            setAuthenticated(false);
            setLoading(false);
        }
    }, [user, setUser, setArtist]);

    if (loading) return <Loading fullScreen />;
    if (!authenticated) return <Redirect to="/error" />;

    return <Route {...props}>{props.children}</Route>;
};

export default ProtectedRoute;
