import React from 'react';
import { DefaultTheme, withTheme } from 'styled-components';
import countries from 'i18n-iso-countries';
import BarChart from '../charts/BarChart';
import LineChart from '../charts/LineChart';
import Number from '../charts/Number';
import DoubleBarChart from '../charts/DoubleBarChart';
import DoubleDoubleBarChart from '../charts/DoubleDoubleBarChart';
import MapChart from '../charts/MapChart';
import PieChart from '../charts/PieChart';
import {
    ChartGrid,
    ContentWrapper,
    FullWidth,
    Heading2,
    TwoNumbers,
    TwoPieCharts
} from '../styled';
import shortenLabel from '../../util/shortenLabel';
import { ChartStyles, SettlementData, CachedSettlementData } from '../../util/types';
import { toTitleCase } from '../../util/string';

// For conversion of ISO code to country name in map chart
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

interface InsightChartsProps {
    data: SettlementData | CachedSettlementData;
    theme: DefaultTheme;
    smallScreen: boolean;
}

const InsightCharts: React.FC<InsightChartsProps> = ({ data, theme, smallScreen }) => {
    const chartStyles: ChartStyles = {
        fontFamily: theme.fontFamily,
        black: theme.colors.black,
        white: theme.colors.white,
        lightGrey: theme.colors.lightGrey,
        darkGrey: theme.colors.darkGrey,
        accent: theme.colors.brandThree,
        brand: [
            theme.colors.brandTwo,
            theme.colors.brandTwoAccent2,
            theme.colors.brandTwoAccent3,
            theme.colors.brandTwoAccent4
        ]
    };

    const {
        all_settlements,
        active_settlements,
        total_royalty_amount,
        total_sales_units,
        monthly_sales,
        royalty_amount_per_sales_group,
        sales_units_per_sales_group,
        sales_per_royalty_statement,
        monthly_royalty_amount_per_sales_group,
        monthly_sales_units_per_sales_group,
        top_selling_titles,
        top_selling_dsps,
        royalty_rate_per_product_group,
        sales_by_price_level,
        subsequent_sales_royalty_amount,
        accounting_errors,
        digital_allocations,
        pkg_rate_per_product_group,
        total_streaming_royalty_amount,
        total_streaming_sales_units,
        average_per_stream_rate,
        top_selling_countries,
        stream_rate_per_dsp,
        subscription_streaming,
        ad_funded_streaming,
        sales_per_sales_group_and_type,
        top_selling_distributors,
        streaming_per_dsp
    } = data;

    return (
        <ContentWrapper>
            <ChartGrid id='sales'>
                <FullWidth>
                    <Heading2>Sales</Heading2>
                </FullWidth>

                <TwoNumbers>
                    <Number
                        title='Total Sales in €'
                        description='Total revenue generated'
                        explanation='The sum of your net earnings across the entire catalogue, which includes returns.'
                        value={total_royalty_amount}
                        unit='€'
                    />

                    <Number
                        title='Total Sales in Units'
                        description='Total units sold'
                        explanation='Each title sold or streamed - whether an album, a single or a compilation - less returns.'
                        value={total_sales_units}
                        unit=''
                        secondary
                    />
                </TwoNumbers>

                <PieChart
                    title='Sales by Sales Type in €'
                    description='Share of revenue by their source'
                    explanation='How much you have earned per sales type. Hint: a click on an agenda item disables streaming.'
                    unit='€'
                    values={Object.values(royalty_amount_per_sales_group['royalty_amount'])}
                    labels={Object.keys(royalty_amount_per_sales_group['royalty_amount']).map(l => l ? toTitleCase(l) : '')}
                    sales_data={Object.values(sales_per_sales_group_and_type['royalty_amount'])}
                    chartStyles={chartStyles}
                />

                <PieChart
                    title='Sales by Sales Type in Units'
                    description='Share of units sold by their source'
                    explanation='How many units you have sold per sales type. Hint: a click on an agenda item disables streaming.'
                    unit=''
                    values={Object.values(sales_units_per_sales_group['sales_units'])}
                    labels={Object.keys(sales_units_per_sales_group['sales_units']).map(l => l ? toTitleCase(l) : '')}
                    sales_data={Object.values(sales_per_sales_group_and_type['sales_units'])}
                    chartStyles={chartStyles}
                />

                <FullWidth>
                    <LineChart
                        title1='Monthly Sales in €'
                        description1='Total revenue per month'
                        unit1='€'
                        title2='Monthly Sales in Units'
                        description2='Total units sold per month'
                        unit2='  '
                        explanation='Your net sales per month including those that originated before the selected sales period.'
                        labels={Object.keys(monthly_sales['royalty_amount'])}
                        values1={[Object.values(monthly_sales['royalty_amount'])]}
                        values2={[Object.values(monthly_sales['sales_units'])]}
                        invalidUntil={
                            active_settlements !== null
                                ? // @ts-ignore
                                active_settlements.sort()[0]
                                : all_settlements.sort()[0]
                        }
                        chartExplanation='Sales outside of sales period'
                        chartStyles={chartStyles}
                    />
                </FullWidth>

                <FullWidth>
                    <DoubleBarChart
                        title='Sales by Royalty Statement'
                        description='Revenue and units per settlement'
                        explanation='The distributed profits per settlement period, typically half-yearly or quarterly.'
                        labels={Object.keys(sales_per_royalty_statement['royalty_amount'])}
                        unit1='€'
                        values1={Object.values(sales_per_royalty_statement['royalty_amount'])}
                        unit2=''
                        values2={Object.values(sales_per_royalty_statement['sales_units'])}
                        chartStyles={chartStyles}
                    />
                </FullWidth>

                <FullWidth>
                    <LineChart
                        title1='Monthly Sales by Sales Type in €'
                        description1='Total revenue per sales type and month'
                        unit1='€'
                        title2='Monthly Sales by Sales type in Units'
                        description2='Total units sold by sales type and month'
                        unit2='  '
                        explanation='Your physical vs non-physical sales over time. Hover over a line to dig into the numbers.'
                        labels={Object.keys(monthly_royalty_amount_per_sales_group['streaming']).map(l => l ? toTitleCase(l) : '')}
                        values1={[
                            Object.values(monthly_royalty_amount_per_sales_group['physical']),
                            Object.values(monthly_royalty_amount_per_sales_group['streaming']),
                            Object.values(monthly_royalty_amount_per_sales_group['download']),
                            Object.values(monthly_royalty_amount_per_sales_group['synch']),
                            Object.values(monthly_royalty_amount_per_sales_group['allocations'])
                        ]}
                        values2={[
                            Object.values(monthly_sales_units_per_sales_group['physical']),
                            Object.values(monthly_sales_units_per_sales_group['streaming']),
                            Object.values(monthly_sales_units_per_sales_group['download']),
                            Object.values(monthly_sales_units_per_sales_group['synch']),
                            Object.values(monthly_royalty_amount_per_sales_group['allocations'])
                        ]}
                        customKeys={['Physical', 'Streaming', 'Download', 'Synch', 'Allocations']}
                        chartStyles={chartStyles}
                    />
                </FullWidth>
            </ChartGrid>

            <ChartGrid id='top-selling'>
                <FullWidth>
                    <Heading2>Top-selling</Heading2>
                </FullWidth>

                <FullWidth>
                    <BarChart
                        title='Top-selling Titles in €'
                        description='Total revenue generated per track or album'
                        explanation='Which album, single or compilation has sold the most within the selected time period.'
                        values={[Object.values(top_selling_titles['royalty_amount'])]}
                        unit='€'
                        labels={Object.keys(top_selling_titles['royalty_amount']).map((label) =>
                            shortenLabel(label)
                        )}
                        horizontal
                        smallScreen={smallScreen}
                        chartStyles={chartStyles}
                    />
                </FullWidth>
                <FullWidth>
                    <BarChart
                        title='Top-selling Distributors in €'
                        description='Total revenue generated per channel'
                        explanation='Your most important sales channels - regardless of physical or digital.'
                        values={[Object.values(top_selling_distributors['royalty_amount'])]}
                        unit='€'
                        labels={Object.keys(top_selling_distributors['royalty_amount']).map(
                            (label) => shortenLabel(label)
                        )}
                        horizontal
                        smallScreen={smallScreen}
                        chartStyles={chartStyles}
                    />
                </FullWidth>

                <FullWidth>
                    <MapChart
                        title='Top-selling Countries in €'
                        description='Total revenue generated per country'
                        explanation='How much money you have earned per country. Hover over the map to get the details.'
                        unit='€'
                        data={Object.keys(top_selling_countries['royalty_amount']).map((key) => ({
                            id: key,
                            value: top_selling_countries['royalty_amount'][key]
                        }))}
                        barValues={[Object.values(top_selling_countries['royalty_amount'])]}
                        barLabels={Object.keys(top_selling_countries['royalty_amount']).map(
                            (isoCode) => countries.getName(isoCode, 'en')
                        )}
                        chartStyles={chartStyles}
                    />
                </FullWidth>
            </ChartGrid>

            <ChartGrid id='pricing'>
                <FullWidth>
                    <Heading2>Pricing</Heading2>
                </FullWidth>

                <FullWidth>
                    <BarChart
                        title='Average Royalty Rate per Product Type in %'
                        description='Royalty Rate per product type weighted by sales_units'
                        explanation='The average license rate per product type. This may differ per country or title.'
                        values={[
                            Object.values(royalty_rate_per_product_group['royalty_rate']).map(
                                (v: any) => (isNaN(v) ? 0 : v / 100)
                            )
                        ]}
                        unit='%'
                        labels={Object.keys(royalty_rate_per_product_group['royalty_rate'])
                            .map(l => toTitleCase(l))
                            .map(l => (l == 'Cd') ? 'CD' : l)}
                        fixedWidth
                        smallScreen={smallScreen}
                        chartStyles={chartStyles}
                    />
                </FullWidth>

                <TwoPieCharts>
                    <PieChart
                        title='Sales by Price Level in €'
                        description='Share of revenue by price level'
                        explanation='How much of your income was generated from top- to low-price segments.'
                        unit='€'
                        values={Object.values(sales_by_price_level['royalty_amount'])}
                        labels={Object.keys(sales_by_price_level['royalty_amount'])
                            .map(l => {
                                switch (parseInt(l)) {
                                    case 0:
                                        return 'Compilation';
                                    case 1:
                                        return 'Low';
                                    case 2:
                                        return 'Mid';
                                    case 3:
                                        return 'High';
                                    default:
                                        return l;
                                }
                            })}
                        chartStyles={chartStyles}
                    />

                    <PieChart
                        title='Sales by Price Level in Units'
                        description='Share of units sold by price level'
                        explanation='How much top- vs low-priced products have contributed to your total sales.'
                        unit=''
                        values={Object.values(sales_by_price_level['sales_units'])}
                        labels={Object.keys(sales_by_price_level['sales_units'])
                            .map(l => {
                                switch (parseInt(l)) {
                                    case 0:
                                        return 'Compilation';
                                    case 1:
                                        return 'Low';
                                    case 2:
                                        return 'Mid';
                                    case 3:
                                        return 'High';
                                    default:
                                        return l;
                                }
                            })}
                        chartStyles={chartStyles}
                    />
                </TwoPieCharts>

                <Number
                    title='Subsequent Sales in €'
                    description='Sum of delayed payments'
                    explanation='The sum of your licensing income that was distributed after the actual sales period.'
                    value={subsequent_sales_royalty_amount}
                    unit='€'
                    percentage={parseFloat(
                        ((subsequent_sales_royalty_amount / total_royalty_amount) * 100).toFixed(2)
                    )}
                />

                <Number
                    title='Accounting Errors'
                    description='Subsequently corrected payments'
                    explanation='How many times a digital distributor reports negative sales_units.'
                    value={accounting_errors.rows}
                    unit=' '
                    percentage={accounting_errors.percentage * 100}
                />

                <Number
                    title='digital Allocations in €'
                    description='Share of minimum lump sum payouts'
                    explanation='Your share of usage-independent minimum payouts your label received as advanced payments from DSPs.'
                    value={digital_allocations}
                    unit='€'
                    percentage={parseFloat(
                        ((digital_allocations / total_royalty_amount) * 100).toFixed(2)
                    )}
                    secondary
                />

                <FullWidth>
                    <BarChart
                        title='Average Package Deduction per Product Type in %'
                        description='Package deduction per product type weighted by sales_units'
                        explanation='Provisioning costs per product weighted by sales_units.'
                        values={[
                            Object.values(pkg_rate_per_product_group['package_rate']).map(
                                (v: any) => (isNaN(v) ? 0 : 1 - v)
                            )
                        ]}
                        unit='%'
                        labels={Object.keys(pkg_rate_per_product_group['package_rate'])
                            .map(l => toTitleCase(l))
                            .map(l => (l == 'Cd') ? 'CD' : l)}
                        fixedWidth
                        smallScreen={smallScreen}
                        chartStyles={chartStyles}
                    />
                </FullWidth>
            </ChartGrid>

            <ChartGrid id='streaming'>
                <FullWidth>
                    <Heading2>Streaming</Heading2>
                </FullWidth>
                <Number
                    title='Total streaming Sales in €'
                    description='Revenue generated from streaming'
                    explanation='Your total income generated from streaming, excluding Downloads or other digital formats.'
                    value={total_streaming_royalty_amount}
                    unit='€'
                />
                <Number
                    title='Total streaming Sales in Units'
                    description='Number of titles streamed'
                    explanation='How often a title or one you have contributed to was streamed.'
                    value={total_streaming_sales_units}
                    unit=''
                />
                <Number
                    title='Average Per-stream_rate in €'
                    description='Amount received per stream'
                    explanation='What you have earned for a single stream - whether generated from a subscription or an ad.'
                    value={average_per_stream_rate}
                    unit='€'
                    roundTo={5}
                />

                <FullWidth>
                    <DoubleBarChart
                        title='Top-selling DSPs in € and Units'
                        description='Sales per digital streaming platform sorted by €'
                        explanation='Your main distributors filtered by those who offer streaming. The order is based on the value generated.'
                        labels={Object.keys(top_selling_dsps['royalty_amount']).map((label) =>
                            shortenLabel(label)
                        )}
                        unit1='€'
                        values1={Object.values(top_selling_dsps['royalty_amount'])}
                        unit2=''
                        values2={Object.values(top_selling_dsps['sales_units'])}
                        horizontal
                        chartStyles={chartStyles}
                    />
                </FullWidth>

                <FullWidth>
                    <BarChart
                        title='Average Artist vs Label Per-stream_rate in €'
                        description='Amount received per stream on top-selling DSPs'
                        explanation='What you earn vs what the Label receives from the provider. The difference should correspond to your royalty rate.'
                        values={[
                            Object.values(stream_rate_per_dsp['royalty_amount']),
                            Object.values(stream_rate_per_dsp['unit_price'])
                        ]}
                        unit='€'
                        labels={Object.keys(stream_rate_per_dsp['royalty_amount']).map((label) =>
                            shortenLabel(label)
                        )}
                        horizontal
                        stacked
                        smallScreen={smallScreen}
                        chartStyles={chartStyles}
                    />
                </FullWidth>

                <BarChart
                    title='streaming Sales in €'
                    description='Subscription vs ad-funded streaming sales'
                    explanation='The share of your income generated from subscription vs ad-funded streams.'
                    unit='€'
                    values={[
                        [
                            subscription_streaming['royalty_amount'],
                            ad_funded_streaming['royalty_amount']
                        ]
                    ]}
                    labels={['Subscription', 'Ad-funded']}
                    fixedWidth
                    comparison
                    smallScreen={smallScreen}
                    chartStyles={chartStyles}
                />

                <BarChart
                    title='streaming Sales in Units'
                    description='Subscription vs ad-funded streaming sales'
                    explanation='How many subscription vs ad-funded streaming units you have generated.'
                    unit=''
                    values={[
                        [subscription_streaming['sales_units'], ad_funded_streaming['sales_units']]
                    ]}
                    labels={['Subscription', 'Ad-funded']}
                    fixedWidth
                    comparison
                    smallScreen={smallScreen}
                    chartStyles={chartStyles}
                />
                <BarChart
                    title='Per-stream_rate'
                    description='Subscription vs ad-funded per-stream_rate'
                    explanation='The average amount you receive for a unique subscription vs ad-funded stream.'
                    unit='€'
                    values={[
                        [subscription_streaming['stream_rate'], ad_funded_streaming['stream_rate']]
                    ]}
                    labels={['Subscription', 'Ad-funded']}
                    roundTo={5}
                    fixedWidth
                    comparison
                    smallScreen={smallScreen}
                    chartStyles={chartStyles}
                />
                <FullWidth>
                    <DoubleDoubleBarChart
                        title='Share of Subscription vs Ad-Funded streaming per DSP in € and Unit'
                        description='Type of sales generated per top-selling DSP'
                        explanation='How many subscription vs ad-funded streams have been generated per DSP and how much this means in actual money.'
                        labels={Object.keys(streaming_per_dsp['subscription_royalty_amount']).map(
                            (label) => shortenLabel(label)
                        )}
                        units={['€', '', '€', '']}
                        values={[
                            Object.values(streaming_per_dsp['subscription_royalty_amount']),
                            Object.values(streaming_per_dsp['subscription_sales_units']),
                            Object.values(streaming_per_dsp['ad_funded_royalty_amount']),
                            Object.values(streaming_per_dsp['ad_funded_sales_units'])
                        ]}
                        chartStyles={chartStyles}
                    />
                </FullWidth>
            </ChartGrid>
        </ContentWrapper>
    );
};

export default withTheme(InsightCharts);
