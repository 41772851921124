//@ts-ignore
import anychart from 'anychart';
//@ts-ignore
import AnyChart from 'anychart-react';
import React from 'react';
import formatNumber from '../../util/formatNumber';
import { ChartStyles } from '../../util/types';
import { MapChartWrapper } from '../styled';
import BarChart from './BarChart';
import ChartWrapper from './ChartWrapper';

interface Props {
    title?: string;
    description?: string;
    explanation?: string;
    unit: string;
    data: Array<{ id: string; value: number }>;
    barValues: Array<Array<number>>;
    barLabels: Array<string>;
    chartStyles: ChartStyles;
}

const MapChart: React.FC<Props> = (props) => {
    const { title, description, explanation, unit, data, barValues, barLabels, chartStyles } =
        props;

    const map = anychart.map();
    map.geoData(anychart.maps.world);
    map.background().fill('transparent');

    const series = map.choropleth(data);
    series.stroke('#000 .1');
    series.colorScale(anychart.scales.linearColor());
    const colors = anychart.color.singleHueProgression(chartStyles.brand[0], 5, 0.9, 0.5);
    series.colorScale().colors(colors);
    series.tooltip().format((e: any) => formatNumber(e.value) + unit);

    return (
        <ChartWrapper explanation={explanation} title={title} description={description}>
            <MapChartWrapper>
                <AnyChart instance={map} height={window.innerWidth >= 800 ? 500 : 300} />
            </MapChartWrapper>

            <BarChart
                noWrapper
                values={barValues}
                unit={unit}
                labels={barLabels}
                horizontal
                customColors={colors}
                chartStyles={chartStyles}
            />
        </ChartWrapper>
    );
};

export default MapChart;
