import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  width: 95%;
  height: 100%;
  margin: 0 auto;
  max-width: 1400px;

  @media (max-width: 1300px) {
    width: 90%;
  }
`;

const ContentWrapper = styled.div`
  padding-bottom: 5rem;
`;

const ProductWrapper = styled.div`
  margin-bottom: 5rem;

  @media (max-width: 700px) {
    margin-bottom: 0;
  }
`;

interface FlexWrapperProps {
    orientation?: string;
}

const FlexWrapper = styled.div<FlexWrapperProps>`
  display: flex;
  align-items: center;
  gap: 2rem;
  flex-direction: ${(props) => props.orientation || 'row'};
`;

const NoData = styled.p`
  padding: 3rem;
  background-color: ${({theme}) => theme.colors.darkGrey};
  border-radius: ${({theme}) => theme.borderRadius1};
  text-align: center;
`;

const ChartGrid = styled.section`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 3rem;

  h2 {
    padding: 10rem 0 8rem 0;
  }

  @media (max-width: 1300px) {
    gap: 2rem;

    h2 {
      padding: 8rem 0 6rem 0;
    }
  }

  @media (max-width: 1100px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const TwoNumbers = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;

  @media (max-width: 1300px) {
    gap: 2rem;
  }

  @media (max-width: 1100px) {
    grid-area: auto / auto / span 1 / span 2;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 700px) {
    grid-area: auto / auto / span 1 / span 1;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const TwoPieCharts = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 3rem;
  grid-area: auto / auto / span 1 / span 3;

  @media (max-width: 1100px) {
    grid-area: auto / auto / span 1 / span 2;
    gap: 2rem;
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-area: auto / auto / span 1 / span 1;
  }
`;

interface FullWidthProps {
    columns?: number;
}

const FullWidth = styled.div<FullWidthProps>`
  grid-area: auto / auto / span 1 / span ${(props) => props.columns || 3};

  @media (max-width: 1100px) {
    grid-area: auto / auto / span 1 / span ${(props) => 2};
  }

  @media (max-width: 700px) {
    grid-area: auto / auto / span 1 / span 1;
  }
`;

const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 2.5rem;

  @media (max-width: 1250px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 650px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 2rem;
  }
`;

const FolderGrid = styled(ProductsGrid)`
  margin: 8rem 0;

  @media (max-width: 800px) {
    margin: 5rem 0;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 8rem;

  tr {
    display: grid;
    grid-template-columns: 5rem auto 8rem;
    transition: all 0.1s;
  }

  thead {
    tr {
      border-bottom: ${({theme}) => theme.border};
    }

    th {
      font-size: 1.6rem;
      font-weight: 500;
      text-align: left;
      color: ${({theme}) => theme.colors.white};
      padding: 2rem 0;
      display: flex;

      &:first-of-type {
        padding-left: 1.5rem;
      }
    }
  }

  tbody {
    tr {
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: ${({theme}) => theme.colors.darkGrey};
      }

      td {
        height: 6.5rem;
        line-height: 1.5;
        display: flex;
        align-items: center;

        &:first-of-type {
          padding-left: 1.5rem;
        }

        &:nth-of-type(2) {
          color: ${({theme}) => theme.colors.white};
          padding-right: 1.5rem;
        }
      }
    }
  }

  @media (max-width: 800px) {
    margin-bottom: 5rem;

    tbody tr:hover {
      background-color: transparent;
    }
  }
`;

interface TableHeadingProps {
    active?: boolean;
    asc?: boolean;
}

const TableHeading = styled.div<TableHeadingProps>`
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    margin-left: 0.75rem;
    height: 0.5rem;
    transition: all 0.2s;

    display: ${(props) => (props.active ? 'block' : 'none')};
    ${(props) => !props.asc && `transform: rotateX(180deg);`}
  }
`;

const DiscographyDetailsTable = styled(Table)`
  tr {
    grid-template-columns: 5rem auto 8rem 4rem;

    td {
      img {
        width: 1.5rem;
        margin: 0 2rem 0 auto;
      }
    }

    &.disc-row {
      grid-template-columns: auto;
      cursor: default;

      td {
        padding-top: 1.5rem;
        gap: 1rem;

        img {
          width: 2rem;
          margin: 0;
        }

        p {
          font-weight: 500;
        }
      }

      &:hover {
        background-color: transparent;
      }
    }
  }

  tbody > p {
    padding: 2rem;
  }

  @media (max-width: 550px) {
    tr {
      grid-template-columns: 5rem auto 4rem;

      td:nth-of-type(3),
      th:nth-of-type(3) {
        display: none;
      }
    }
  }
`;

interface DiscographyGridProps {
    margin?: boolean;
}

const DiscographyGrid = styled.div<DiscographyGridProps>`
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 2.5rem;
  align-items: stretch;
  justify-items: stretch;
  margin: 2.5rem 0;

  ${(props) =>
          props.margin &&
          `
        margin-top: 12.5rem;
    `} @media (max-width: 1450 px) {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

  @media (max-width: 1250px) {
    gap: 2.5rem;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media (max-width: 950px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 2rem;
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 400px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const FeaturedArtistsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 2.5rem;
  align-items: stretch;
  justify-items: stretch;
  margin: 8rem 0;

  @media (max-width: 1350px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 650px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const List = styled.ul`
  display: grid;
  grid-template-columns: auto;
  gap: 1.5rem;
  margin: 2.5rem 0;
  list-style: none;

  li {
    display: grid;
    grid-template-columns: 3fr 3fr 2fr 10rem;
    align-items: center;
    align-content: center;
    height: 8rem;
    padding: 0 2.5rem;
    background-color: ${({theme}) => theme.colors.darkGrey};
    border-radius: ${({theme}) => theme.borderRadius1};

    > div {
      display: flex;
      align-items: center;
      gap: 1.5rem;

      h4 {
        margin: 0;
      }

      > b {
        color: ${({theme}) => theme.colors.white};
        font-weight: 600;
        line-height: 1.25;
      }

      > p {
        color: ${({theme}) => theme.colors.lightGrey};
      }

      &:last-of-type {
        justify-self: end;
      }
    }
  }

  @media (max-width: 1300px) {
    li {
      grid-template-columns: 2fr 3fr 1fr 10rem;
    }
  }

  @media (max-width: 900px) {
    li {
      padding: 2.5rem;
      grid-template-columns: 1fr 13rem 10rem;

      div:nth-of-type(2) {
        display: none;
      }
    }
  }

  @media (max-width: 500px) {
    li {
      grid-template-columns: 1fr 10rem;

      div:nth-of-type(3) {
        display: none;
      }
    }
  }
`;

interface DiscographyListProps {
    open?: boolean;
}

const DiscographyList = styled(List)<DiscographyListProps>`
  height: 0;
  opacity: 0;
  transition: all 0.3s;
  overflow: hidden;

  ${(props) =>
          props.open &&
          `
        opacity: 1;
        height: auto;
    `}
`;

const ServiceStatementsList = styled(List)`
  margin: 8rem 0;

  @media (max-width: 800px) {
    margin: 5rem 0;
  }
`;


const FilesGroup = styled.div`
  background-color: ${({theme}) => theme.colors.darkGrey};
  border-radius: ${({theme}) => theme.borderRadius1};
  padding: 3rem;
  margin: 2rem 0;

  > div {
    display: flex;
    justify-content: space-between;

    > div:first-child {
      width: 30%;
      display: flex;
      flex-direction: row;
      align-items: center;

      > img {

      }

      > h4 {
        padding-top: 1rem;
        margin-left: 2rem;
      }
    }

    > img {
      margin-right: 1rem;
      margin-top: 0.8rem;
      height: 0.8rem;
    }
  }

  > div:nth-child(2) {
    margin-top: 2rem;
    padding: 0.5rem 1rem;
    border-top: 1px solid ${({theme}) => theme.colors.lightGrey}20;
  }

  li {
    background-color: ${({theme}) => theme.colors.black}80;
  }

  p {
    color: ${({theme}) => theme.colors.lightGrey};
  }

  @media (max-width: 1200px) {
    padding: 2.5rem;
  }
`

const FilesList = styled(List)`
  margin: 2rem 0 0 0;

  li {
    position: relative;
    grid-template-columns: auto 13rem 8rem 5rem;
    
    > div:first-child {
      overflow: hidden;
      white-space: nowrap;
    }

    > div:last-of-type > img {
      cursor: pointer;
      padding: 0 1rem;
    }
  }

  @media (max-width: 1300px) {
    li {
      grid-template-columns: auto 13rem 8rem 5rem;
    }
  }

  @media (max-width: 900px) {
    li {
      grid-template-columns: auto 5rem;

      > div:first-child {
        overflow: hidden;
        white-space: nowrap;
      }
      
      div:nth-of-type(3) {
        display: none;
      }
    }
  }

  @media (max-width: 500px) {
    li {
      div:nth-of-type(4) {
        display: block;
      }
    }
  }
`;

const SubRelease = styled.div`
  h3 {
    margin-top: 3rem;
  }
`;

const ReleaseVersion = styled.div`
  background-color: ${({theme}) => theme.colors.darkGrey};
  border-radius: ${({theme}) => theme.borderRadius1};
  padding: 2.5rem;
  cursor: pointer;

  a {
    display: flex;
    align-items: center;

    h4 {
      font-size: 1.6rem;
      font-weight: 500;
      margin: 0 1rem 0 0;
    }

    p {
      color: ${({theme}) => theme.colors.lightGrey};
    }
  }
`;

const StatementPreview = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4rem 0;

  > div {
    &:first-of-type {
      > div:first-of-type {
        margin: 2rem 0;

        span {
          display: block;
          font-weight: 500;
          margin-bottom: 1rem;
        }

        p {
          color: ${({theme}) => theme.colors.white};
          font-size: 1.8rem;
          font-weight: 500;
        }

        > div > div {
          display: flex;
          align-items: center;
          gap: 1rem;
          margin-bottom: 3rem;

          div {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: ${({theme}) => theme.colors.white}20;
            height: 4rem;
            width: 4rem;
            border-radius: 20rem;

            img {
              width: 40%;
              margin: 0 auto;
            }
          }
        }
      }

      > div:last-of-type {
        display: flex;
        gap: 1.5rem;
      }
    }

    &:last-of-type {
      width: 50%;
      height: 70vh;
      background-color: ${({theme}) => theme.colors.white};
      border-radius: 5px;
      overflow-y: scroll;
    }
  }

  @media (max-width: 1600px) {
    flex-direction: column;
    gap: 4rem;

    > div {
      position: relative;
      width: 100%;

      &:first-of-type {
        > div:first-of-type {
          display: flex;
          gap: 8rem;
        }

        > div:last-of-type {
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      &:last-of-type {
        width: 90vw;
        max-width: 100rem;
        height: 120vw;
        max-height: 150rem;
      }
    }
  }

  @media (max-width: 700px) {
    > div {
      &:first-of-type {
        > div:first-of-type {
          display: flex;
          gap: 4rem;
        }

        > div:last-of-type {
          position: relative;
          top: auto;
          right: auto;
        }
      }
    }
  }
`;

export {
    Container,
    ContentWrapper,
    FlexWrapper,
    ProductWrapper,
    NoData,
    ChartGrid,
    TwoNumbers,
    TwoPieCharts,
    FullWidth,
    ProductsGrid,
    FolderGrid,
    Table,
    TableHeading,
    DiscographyDetailsTable,
    DiscographyGrid,
    List,
    DiscographyList,
    ServiceStatementsList,
    SubRelease,
    ReleaseVersion,
    FeaturedArtistsGrid,
    StatementPreview,
    FilesList,
    FilesGroup
};
