import React, { useState, useRef, useEffect } from 'react';

import ModalWrapper from './ModalWrapper';

import {
    Button,
    CloseIcon,
    FullscreenOverlay,
    NewDocumentModal as NewDocumentModalWrapper,
    Heading4,
    Select,
    Input,
    HorizontalInputWrapper,
    InfoMessage
} from '../styled';

import CustomCloseIcon from '../../assets/close.svg';
import UploadIcon from '../../assets/upload.svg';

import uploadDocuments from '../../api/uploadDocuments';

import shortenLabel from '../../util/shortenLabel';

interface Props {
    artist_id: string;
    type: 'CONTRACT' | 'STATEMENT';
    refetch: () => void;
    onClose: () => void;
}

const NewDocumentModal: React.FC<Props> = ({ artist_id, type, refetch, onClose }) => {
    const [files, setFiles] = useState<File[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [cycle, setCycle] = useState<'HALF' | 'QUARTER'>('HALF');
    const [year, setYear] = useState<number>(new Date().getFullYear());
    const [period, setPeriod] = useState<string>('H1');

    const inputComponent = useRef<HTMLInputElement | null>(null);

    const fileUploadButton = () => {
        if (inputComponent.current) {
            inputComponent.current.click();
            inputComponent.current.onchange = (e) =>
                // @ts-ignore
                setFiles([...e.target?.files]);
        }
    };

    const uploadFiles = async () => {
        if (files.length > 0) {
            try {
                setLoading(true);

                const settlementInfo =
                    type === 'STATEMENT'
                        ? {
                            cycle,
                            year,
                            period
                        }
                        : undefined;

                await uploadDocuments(artist_id, type, files, settlementInfo);

                setLoading(false);
                onClose();
                refetch();
            } catch (e) {
                setError('An unexpected error occurred, please try again or contact us directly.');
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (files.length > 0) {
            const file = files[0];
            // if file.name like 'DS_{year}'
            if (file.name.startsWith('DS_')) {
                const file_year = parseInt(file.name.slice(3, 7));
                if (file_year > 1900 && file_year < new Date().getFullYear()) {
                    setYear(file_year);

                    const file_freq = file.name.slice(7, 9);
                    if (file_freq === 'Q2') {
                        setPeriod('H1');
                    }
                    if (file_freq === 'Q4') {
                        setPeriod('H2');
                    }
                }
            }
        }
    }, [files]);


    return (
        <FullscreenOverlay>
            <ModalWrapper onClose={onClose}>
                <NewDocumentModalWrapper>
                    <div>
                        <Heading4>
                            Upload new {type === 'CONTRACT' ? 'contracts' : 'statements'}
                        </Heading4>

                        <CloseIcon onClick={onClose} customImage relative>
                            <img src={CustomCloseIcon} alt='Close modal' />
                        </CloseIcon>
                    </div>

                    <span />

                    <input
                        type='file'
                        accept={type === 'CONTRACT' ? '.pdf' : '.xls,.xlsx'}
                        multiple
                        hidden
                        ref={inputComponent}
                    />

                    {type === 'STATEMENT' && files.length > 0 && (
                        <>
                            <div>
                                <p>
                                    Please specify the settlement cycle and period of the statements
                                    you're about to upload.
                                </p>
                            </div>
                            <HorizontalInputWrapper>
                                <Select
                                    value={cycle}
                                    onChange={(e) => {
                                        setCycle(e.target.value === 'HALF' ? 'HALF' : 'QUARTER');
                                        setPeriod(e.target.value === 'HALF' ? 'H1' : 'Q1');
                                    }
                                    }
                                >
                                    <option value='HALF'>Half-yearly</option>
                                    <option value='QUARTER'>Quarterly</option>
                                </Select>

                                <Input
                                    transparent
                                    type='number'
                                    min={1900}
                                    max={new Date().getFullYear()}
                                    value={year}
                                    step={1}
                                    placeholder='Year'
                                    onChange={(e) => setYear(parseInt(e.target.value))}
                                />

                                <Select value={period} onChange={(e) => setPeriod(e.target.value)}>
                                    {(cycle === 'HALF'
                                            ? ['H1', 'H2']
                                            : ['Q1', 'Q2', 'Q3', 'Q4']
                                    ).map((k) => (
                                        <option value={k} key={k}>
                                            {k}
                                        </option>
                                    ))}
                                </Select>
                            </HorizontalInputWrapper>
                            <span />
                        </>
                    )}

                    <div>
                        {files.length > 0 ? (
                            <>
                                {files.map((file, index) => (
                                    <p key={index}>
                                        <img
                                            src={CustomCloseIcon}
                                            alt='Select new file'
                                            onClick={() =>
                                                setFiles(
                                                    files.filter(
                                                        (f) =>
                                                            f.name !== file.name &&
                                                            f.lastModified !== file.lastModified &&
                                                            f.size !== file.size
                                                    )
                                                )
                                            }
                                        />
                                        {shortenLabel(file.name, 35)}
                                    </p>
                                ))}
                            </>
                        ) : (
                            <p>
                                {type === 'CONTRACT'
                                    ? 'Please make sure all of your contracts are PDF files.'
                                    : 'Please make sure all of your statements are .xls or .xlsx files.'}
                            </p>
                        )}
                    </div>

                    <span />

                    <div>
                        {files.length === 0 ? (
                            <Button transparent onClick={fileUploadButton}>
                                <img src={UploadIcon} alt='Choose files' />
                                Choose files
                            </Button>
                        ) : (
                            <Button transparent onClick={() => uploadFiles()}>
                                <img src={UploadIcon} alt='Submit' />
                                {loading ? 'Loading...' : 'Upload'}
                            </Button>
                        )}
                    </div>

                    {error && (
                        <InfoMessage error marginTop>
                            {error}
                        </InfoMessage>
                    )}
                </NewDocumentModalWrapper>
            </ModalWrapper>
        </FullscreenOverlay>
    );
};

export default NewDocumentModal;
