import { CachedSettlementData } from './types';

function validateSettlementData(data: CachedSettlementData, version: string, artist_id: string) {
    return (
        data.metadata.version === version &&
        data.metadata.artist_id === artist_id &&
        data.all_settlements !== undefined &&
        data.active_settlements !== undefined &&
        data.total_royalty_amount !== undefined &&
        data.total_sales_units !== undefined &&
        data.monthly_sales !== undefined &&
        data.royalty_amount_per_sales_group !== undefined &&
        data.sales_units_per_sales_group !== undefined &&
        data.sales_per_royalty_statement !== undefined &&
        data.monthly_royalty_amount_per_sales_group !== undefined &&
        data.monthly_sales_units_per_sales_group !== undefined &&
        data.top_selling_titles !== undefined &&
        data.top_selling_dsps !== undefined &&
        data.royalty_rate_per_product_group !== undefined &&
        data.sales_by_price_level !== undefined &&
        data.subsequent_sales_royalty_amount !== undefined &&
        data.accounting_errors !== undefined &&
        data.digital_allocations !== undefined &&
        data.pkg_rate_per_product_group !== undefined &&
        data.total_streaming_royalty_amount !== undefined &&
        data.total_streaming_sales_units !== undefined &&
        data.average_per_stream_rate !== undefined &&
        data.top_selling_countries !== undefined &&
        data.stream_rate_per_dsp !== undefined &&
        data.subscription_streaming !== undefined &&
        data.ad_funded_streaming !== undefined &&
        data.sales_per_sales_group_and_type !== undefined &&
        data.top_selling_distributors !== undefined &&
        data.streaming_per_dsp !== undefined
    );
}

export default validateSettlementData;
