import React, {useEffect, useState} from 'react';
import {Link, Route, useLocation, useParams} from 'react-router-dom';

import {
    ContentWrapper,
    Heading4,
    Breadcrumb,
    FilesList,
    FilesGroup,
    ListItemOptions,
    NoData,
    StatusLabel,
    Button
} from '../styled';

import ChevronRight from '../../assets/chevron-right.svg';
import PDFIcon from '../../assets/pdf.svg';
import ThreeDotsIcon from '../../assets/dots.svg';
import EditIcon from '../../assets/edit.svg';
import DeleteIcon from '../../assets/delete.svg';
import DownloadIcon from '../../assets/download.svg';
import DownIcon from '../../assets/down.svg';
import Spinner from '../../assets/spinner.svg'

import {Artist, Document} from '../../util/types';

import ModalWrapper from '../modals/ModalWrapper';
import RenameModal from '../modals/RenameModal';

import renameDocument from '../../api/renameDocument';
import deleteDocument from '../../api/deleteDocument';
import PDFViewer from '../misc/PDFViewer';
import ConfirmationModal from '../modals/ConfirmationModal';
import FolderIcon from "../../assets/folder-filled.svg";
import {
    fetchPreprocessingStatus,
    startPreprocessingPipeline
} from "../../api/preprocessingPipeline";
import UploadIcon from "../../assets/upload.svg";
import NewDocumentModal from "../modals/NewDocumentModal";


interface Props {
    artist: Artist;
    files: Document[];
    loading: boolean;
    setLoading: (value: boolean) => void;
    refetch: () => void;
    setGlobalProcessing: (processing: boolean) => void;
}


const Files: React.FC<Props> = ({
                                    artist,
                                    files,
                                    loading,
                                    setLoading,
                                    refetch,
                                    setGlobalProcessing
                                }) => {
    const {folder} = useParams<{ folder: string }>();

    // Value of the following 3 states = index of selected file within files
    const [fileOptions, setFileOptions] = useState<number>(-1);
    const [showFilePreview, setShowFilePreview] = useState<string>("");
    const [showRenameModal, setShowRenameModal] = useState<[string, string]>(["", ""]);
    const [showDeleteModal, setShowDeleteModal] = useState<string>("");
    const [fileGroups, setFileGroups] = useState<Map<string, Document[]>>();
    const [status, setStatus] = useState<{ processing: boolean; range: string }>({
        processing: false,
        range: ""
    })

    const renameFile = async (artist_id: string, document_id: string, filename: string) => {
        if (filename.length > 0) {
            try {
                setLoading(true);

                await renameDocument(artist_id, document_id, filename);

                refetch();
            } catch (e) {
                setLoading(false);
            }
        }
    };

    const deleteFile = async (artist_id: string, document_id: string) => {
        console.log("deleeting file: " + document_id)
        try {
            setLoading(true);

            await deleteDocument(artist_id, document_id);

            refetch();
        } catch (e) {
            console.log(e)
            setLoading(false);
        }
    };

    const documents = files.filter(
        (doc) => doc.type === (folder === 'contracts' ? 'CONTRACT' : 'STATEMENT')
    );

    // Check pipeline status
    const checkStatus = async () => {
        try {
            let status = await fetchPreprocessingStatus(artist.artist_id)
            setGlobalProcessing(status.processing)
            setStatus(status)
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        const interval = setInterval(async () => {
            await checkStatus()
        }, 5e3);
        return () => clearInterval(interval);
    }, [])


    useEffect(() => {
        let groups = new Map<string, Document[]>()
        if (folder === 'statements') {
            documents.forEach((d) => {
                let key = d.settlement_info.year + "-" + d.settlement_info.period
                groups.set(key, [])
            })
            documents.forEach((d) => {
                let key = d.settlement_info.year + "-" + d.settlement_info.period
                let tmp = groups.get(key)
                if (tmp) {
                    tmp.push(d)
                    groups.set(key, tmp)
                }
            })
        }
        if (groups) {
            const iter = Array.from(groups)
            const sorted = iter.sort((a, b) => a[0].localeCompare(b[0]))
            setFileGroups(new Map(sorted))
        }
    }, documents)


    return (
        <ContentWrapper>
            <Breadcrumb>
                <Heading4>
                    <Link to="/files">All files</Link>
                    <img src={ChevronRight} alt="Right"/>
                    <span>{folder[0].toUpperCase() + folder.slice(1)}</span>
                </Heading4>
            </Breadcrumb>


            {folder === 'statements' &&
                <>
                    {documents.length > 0 ? (
                        <>
                            {
                                fileGroups && Array.from(fileGroups).map((g, index) => {
                                    return (
                                        <FilesGroupC artist={artist} period={g[0]} docs={g[1]}
                                                     status={status}
                                                     checkStatus={checkStatus} key={index}
                                                     showRenameModal={showRenameModal}
                                                     setShowRenameModal={setShowRenameModal}
                                                     showDeleteModal={showDeleteModal}
                                                     setShowDeleteModal={setShowDeleteModal}
                                                     fileGroupIndex={index}
                                        />
                                    )
                                })
                            }
                        </>
                    ) : (
                        <NoData>You haven't uploaded any files to this folder yet.</NoData>
                    )}
                </>

            }

            {folder === 'contracts' &&
                <>
                    {documents.length > 0 ? (
                        <FilesList>
                            {documents
                                .sort((a, b) => b.created_at - a.created_at)
                                .map((file, index) => (
                                    <li key={index}>
                                        <div>
                                            <img src={PDFIcon} alt="File"/>
                                            <b
                                                onClick={() => setShowFilePreview(file.url)}
                                                style={{
                                                    cursor: folder === 'contracts' ? 'pointer' : 'default'
                                                }}
                                            >
                                                {file.filename}
                                                {file.settlement_info &&
                                                    ` (${
                                                        file.settlement_info.year +
                                                        file.settlement_info.period
                                                    })`}
                                            </b>
                                        </div>

                                        <div>
                                            <StatusLabel success={file.reviewed}>
                                                {file.reviewed ? 'Reviewed' : 'Processing'}
                                            </StatusLabel>
                                        </div>

                                        <div>
                                            <p>{new Date(file.created_at).toLocaleDateString()}</p>
                                        </div>

                                        <div>
                                            <img
                                                src={ThreeDotsIcon}
                                                alt="Options"
                                                onClick={() => setFileOptions(index)}
                                            />

                                            {fileOptions === index && (
                                                <ModalWrapper onClose={() => setFileOptions(-1)}>
                                                    <ListItemOptions>
                                                        <div onClick={() => setFileOptions(-1)}>
                                                            <img src={DownloadIcon} alt="Download"/>
                                                            <p>
                                                                <a href={documents[index].url}
                                                                   download>
                                                                    Download
                                                                </a>
                                                            </p>
                                                        </div>
                                                        <div
                                                            onClick={() => {
                                                                setFileOptions(-1);
                                                                setShowRenameModal([file.document_id, file.filename]);
                                                            }}
                                                        >
                                                            <img src={EditIcon} alt="Rename"/>
                                                            <p>Rename</p>
                                                        </div>
                                                        <div
                                                            onClick={() => {
                                                                setFileOptions(-1);
                                                                setShowDeleteModal(file.document_id);
                                                            }}
                                                        >
                                                            <img src={DeleteIcon} alt="Delete"/>
                                                            <p>Delete</p>
                                                        </div>
                                                    </ListItemOptions>
                                                </ModalWrapper>
                                            )}
                                        </div>
                                    </li>
                                ))}
                        </FilesList>
                    ) : (
                        <NoData>You haven't uploaded any files to this folder yet.</NoData>
                    )}
                </>
            }

            {showRenameModal[0].length > 0 && (
                <RenameModal
                    title="Rename file"
                    initialValue={showRenameModal[1]}
                    loading={loading}
                    onClose={() => setShowRenameModal(["", ""])}
                    onSubmit={(newTitle: string) => {
                        renameFile(
                            artist.artist_id,
                            showRenameModal[0],
                            newTitle
                        );
                    }}
                />
            )}

            {showDeleteModal.length > 0 && (
                <ConfirmationModal
                    title={"Delete file: " + ""}
                    description={`Are you sure you want to delete this file permanently?
                                This action can't be undone.`}
                    loading={loading}
                    danger
                    buttonText="Delete"
                    onClose={() => setShowDeleteModal("")}
                    onSubmit={() => {
                        console.log(showDeleteModal)
                        console.log(documents)
                        deleteFile(artist.artist_id, showDeleteModal)
                    }
                    }
                />
            )}

            {showFilePreview.length > 0 && folder === 'contracts' && (
                <PDFViewer
                    file={showFilePreview}
                    fullscreen
                    onClose={() => setShowFilePreview("")}
                />
            )}
        </ContentWrapper>
    );
};


interface FGCProps {
    artist: Artist;
    period: string;
    docs: Document[];
    status: {
        processing: boolean;
        range: string;
    };
    checkStatus: () => void;
    setShowRenameModal: React.Dispatch<React.SetStateAction<[string, string]>>;
    showRenameModal: [string, string];
    setShowDeleteModal: React.Dispatch<React.SetStateAction<string>>;
    showDeleteModal: string;
    fileGroupIndex: number;
}


const FilesGroupC: React.FC<FGCProps> = (
    {
        artist,
        period,
        docs,
        status,
        checkStatus,
        setShowRenameModal,
        setShowDeleteModal,
        fileGroupIndex
    }) => {
    const [open, setOpen] = useState<boolean>(false)
    const [fileOptions, setFileOptions] = useState<number>(-1);
    const [showFilePreview, setShowFilePreview] = useState<number>(-1);

    const startPipeline = async (period: string) => {
        let parts = period.split("-")
        await startPreprocessingPipeline(
            artist.artist_id,
            parts[0],
            parts[1]
        )
        checkStatus();
    }

    function truncate(str: string, maxlength: number) {
        return str.length > maxlength ? str.substring(0, maxlength - 3) + "..." : str;
    }

    return (
        <FilesGroup key={period}>
            <div>
                <div>
                    <img src={FolderIcon} alt="Folder"/>
                    <Heading4>{period}</Heading4>
                </div>
                <div style={{display: "flex", flexDirection: "row", height: "40px"}}>
                    <Button
                        transparent={true}
                        onClick={() => {
                            startPipeline(period)
                        }}
                        disabled={status.processing}
                    >
                        {!status.processing && "Process"}
                        {status.processing && (status.range === "ALL" || period.includes(status.range)) &&
                            <>
                                <img
                                    style={{
                                        height: '40px',
                                        marginRight: '-0.2rem',
                                        marginLeft: '-2rem'
                                    }}
                                    src={Spinner} alt={"loading..."}
                                />
                                Processing</>
                        }
                        {(status.processing && !(status.range === "ALL" || period.includes(status.range))) && "Waiting"}
                    </Button>
                </div>
            </div>
            <div>
                <div>
                    {docs.length} files
                </div>
                <div>
                    <a style={{padding: "0.5rem 0.5rem", cursor: "pointer"}}
                       onClick={() => {
                           setOpen(!open)
                       }}
                    >
                        {!open && <img src={DownIcon} alt="Open"/>}
                        {open &&
                            <img src={DownIcon} style={{transform: 'rotate(180deg)'}} alt="Open"/>}
                    </a>
                </div>
            </div>

            {
                open &&
                <FilesList>
                    {docs
                        .sort((a, b) => b.created_at - a.created_at)
                        .map((file, index) => (
                            <li key={index}>
                                <div>
                                    <img src={PDFIcon} alt="File"/>
                                    <b
                                        style={{
                                            cursor: 'default'
                                        }}
                                    >

                                        {file.filename} {file.settlement_info &&
                                        ` (${
                                            file.settlement_info.year +
                                            file.settlement_info.period
                                        })`}
                                    </b>
                                    {file.document_id}
                                </div>

                                <div>
                                    <StatusLabel success={file.processed}>
                                        {file.processed ? 'Processed' : 'Open'}
                                    </StatusLabel>
                                </div>

                                <div>
                                    <p>{new Date(file.created_at).toLocaleDateString()}</p>
                                </div>

                                <div>
                                    <img
                                        src={ThreeDotsIcon}
                                        alt="Options"
                                        onClick={() => setFileOptions(index)}
                                    />

                                    {fileOptions === index && (
                                        <ModalWrapper onClose={() => setFileOptions(-1)}>
                                            <ListItemOptions>
                                                <div onClick={() => setFileOptions(-1)}>
                                                    <img src={DownloadIcon} alt="Download"/>
                                                    <p>
                                                        <a href={docs[index].url} download>
                                                            Download
                                                        </a>
                                                    </p>
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        setFileOptions(-1);
                                                        setShowRenameModal([file.document_id, file.filename]);
                                                    }}
                                                >
                                                    <img src={EditIcon} alt="Rename"/>
                                                    <p>Rename</p>
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        setFileOptions(-1);
                                                        setShowDeleteModal(file.document_id);
                                                    }}
                                                >
                                                    <img src={DeleteIcon} alt="Delete"/>
                                                    <p>Delete</p>
                                                </div>
                                            </ListItemOptions>
                                        </ModalWrapper>
                                    )}
                                </div>
                            </li>
                        ))}
                </FilesList>
            }
        </FilesGroup>
    )
}


export default Files;
