import { Release } from '../util/types';
import { apiUrl } from './config';

const fetchDiscography = async (
    artist_id: string
): Promise<{
    releases: Release[];
    covers: { release_id: string; thumbnail_url: string; regular_url: string }[];
}> => {
    try {
        const res = await fetch(apiUrl + '/artist/v1/artist/' + artist_id + '/discography/master', {
            method: 'get',
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            })
        });

        if (!res.ok) throw new Error();

        const { releases, covers } = await res.json();

        return { releases, covers };
    } catch (e) {
        throw new Error();
    }
};

export default fetchDiscography;
