import React, { useEffect, useState } from 'react';

import { Container, HeaderBottom, HeaderMain, HeaderWrapper, Heading1, NavLink } from '../styled';

import scrollHandler from '../../util/scrollHandler';

interface Props {
    category: number;
    title: string;
    description?: string;
    anchors?: string[];
    customButtons?: React.FC;
    notification?: React.FC;
}

const ProductHeader: React.FC<Props> = (props) => {
    const {
        category,
        title,
        description,
        anchors,
        customButtons: CustomButtons,
        notification: Notification,
        children
    } = props;

    const [scroll, setScroll] = useState<boolean>(false);
    const [currentSection, setCurrentSection] = useState<string>('');

    useEffect(() => {
        const handleScroll = () =>
            scrollHandler(title, scroll, currentSection, setScroll, setCurrentSection);

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    });

    return (
        <>
            <HeaderWrapper category={category} scroll={scroll}>
                <HeaderMain>
                    <Container>
                        <div>
                            <Heading1>
                                {title}
                                {Notification && <Notification />}
                            </Heading1>
                            <p>{description || '...'}</p>
                            {CustomButtons && <CustomButtons />}
                        </div>

                        {children}
                    </Container>
                </HeaderMain>

                {anchors && (
                    <HeaderBottom navbar={scroll}>
                        {anchors.map((anchor: string) => (
                            <NavLink
                                key={anchor}
                                href={`#${anchor}`}
                                active={currentSection === anchor && scroll}
                            >
                                {anchor.substr(0, 1).toUpperCase() + anchor.substr(1)}
                            </NavLink>
                        ))}
                    </HeaderBottom>
                )}
            </HeaderWrapper>
        </>
    );
};

export default ProductHeader;
