import React, { useState } from 'react';
import { Button, FakeSelect, Select, TimeFilterWrapper } from '../styled';
import ModalWrapper from '../modals/ModalWrapper';

interface Props {
    all_settlements: string[];
    active_settlements: string[];
    fetchData: (settlements?: string[], filter?: boolean) => void;
}

const InsightTimeFilter: React.FC<Props> = (props) => {
    const { active_settlements, all_settlements, fetchData } = props;

    const [open, setOpen] = useState<boolean>(false);
    const [start, setStart] = useState<string>(active_settlements[0]);
    const [end, setEnd] = useState<string>(active_settlements[active_settlements.length - 1]);
    const [startOptions, setStartOptions] = useState<string[]>(all_settlements);
    const [endOptions, setEndOptions] = useState<string[]>(all_settlements);

    const generateOption = (s: string) => (
        <option value={s} key={s}>
            {s}
        </option>
    );

    return (
        <ModalWrapper onClose={() => setOpen(false)}>
            <TimeFilterWrapper open={open}>
                <FakeSelect open={open} onClick={() => setOpen(!open)}>
                    {active_settlements.length === all_settlements.length
                        ? 'All time'
                        : `${active_settlements[0]} - ${
                              active_settlements[active_settlements.length - 1]
                          }`}
                </FakeSelect>

                <div>
                    <Select
                        value={start}
                        onChange={(e) => {
                            setStart(e.target.value);
                            setEndOptions(
                                all_settlements.filter((s: string) => s >= e.target.value)
                            );
                        }}
                    >
                        {startOptions.map(generateOption)}
                    </Select>
                    <p>-</p>
                    <Select
                        value={end}
                        onChange={(e) => {
                            setEnd(e.target.value);
                            setStartOptions(
                                all_settlements.filter((s: string) => s <= e.target.value)
                            );
                        }}
                    >
                        {endOptions.map(generateOption)}
                    </Select>

                    <Button
                        onClick={() => {
                            let settlements: any = [];

                            all_settlements.forEach((s: string) => {
                                if (s >= start && s <= end) settlements.push(s);
                            });

                            if (settlements.length > 0) {
                                fetchData(
                                    settlements.length === all_settlements.length
                                        ? undefined
                                        : settlements,
                                    true
                                );

                                setOpen(false);
                            }
                        }}
                    >
                        Filter
                    </Button>
                </div>
            </TimeFilterWrapper>
        </ModalWrapper>
    );
};

export default InsightTimeFilter;
