import { apiUrl } from './config';

const createChangeRequest = async (
    artist_id: string,
    user_id: string,
    release_id: string,
    message: string
): Promise<void> => {
    try {
        const res = await fetch(apiUrl + '/artist/v1/artist/' + artist_id + '/changerequest', {
            method: 'post',
            body: JSON.stringify({
                user_id,
                release_id,
                message
            }),
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            })
        });

        if (!res.ok) throw new Error();

        return;
    } catch (e) {
        throw new Error();
    }
};

export default createChangeRequest;
