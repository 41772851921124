import { apiUrl } from './config';

const setNoAudio = async (
    artist_id: string,
    recording_id: string,
    no_audio: boolean
): Promise<void> => {
    try {
        const res = await fetch(apiUrl + '/artist/v1/artist/' + artist_id + '/recording/no_audio', {
            method: 'post',
            body: JSON.stringify({
                recording_id,
                no_audio
            }),
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            })
        });

        if (!res.ok) throw new Error();

        return;
    } catch (e) {
        throw new Error();
    }
};

export default setNoAudio;
