import styled from 'styled-components';
import ApprovalsIcon from '../../assets/approvals.svg';
import ControlIcon from '../../assets/control.svg';
import DiscographyIcon from '../../assets/discography.svg';
import FilesIcon from '../../assets/files.svg';
import InsightIcon from '../../assets/insight.svg';
import MonitorIcon from '../../assets/monitor.svg';
import ServiceIcon from '../../assets/service.svg';
import brandColorByCategory from '../../util/brandColorByCategory';
import { ProductInfo } from '../../util/types';

interface ProductCardProps {
    category: number;
    product: ProductInfo['name'];
}

const ProductCard = styled.div<ProductCardProps>`
    position: relative;
    display: flex;
    align-items: center;
    height: 9rem;
    background-color: ${({ theme }) => theme.colors.darkGrey};
    border-radius: ${({ theme }) => theme.borderRadius1};
    padding: 1.5rem;
    cursor: pointer;

    > div {
        position: absolute;
        top: 1.5rem;
        left: 1.5rem;
        width: 6rem;
        height: 6rem;
        display: flex;
        align-items: center;
        border-radius: ${({ theme }) => theme.borderRadius1};
        background-color: ${({ category, theme }) => brandColorByCategory(category, theme)};
        background-image: linear-gradient(
            to right bottom,
            ${({ category, theme }) => brandColorByCategory(category, theme)},
            ${({ category, theme }) => brandColorByCategory(category, theme, true)}
        );
        transition: all 0.3s;
        overflow: hidden;

        > div {
            width: 2.5rem;
            height: 2.5rem;
            margin-left: 1.75rem;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(${({ product }) => {
                switch (product) {
                    case 'APPROVALS':
                        return ApprovalsIcon;
                    case 'CONTROL':
                        return ControlIcon;
                    case 'DISCOGRAPHY':
                        return DiscographyIcon;
                    case 'INSIGHT':
                        return InsightIcon;
                    case 'MONITOR':
                        return MonitorIcon;
                    case 'SERVICE':
                        return ServiceIcon;
                    case 'FILES':
                        return FilesIcon;
                    default:
                        return '';
                }
            }});
            transition: all 0.3s;
        }
    }

    p {
        font-size: 1.8rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.white};
        margin-left: 7.5rem;
        z-index: 1;
        transition: all 0.4s;
    }

    span {
        position: absolute;
        top: -6px;
        right: -6px;
        font-size: 1.2rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.black};
        background-color: ${({ theme }) => theme.colors.white};
        padding: 6px;
        border-radius: ${({ theme }) => theme.borderRadius1};
        box-shadow: ${({ theme }) => theme.boxShadow1};
    }

    &:hover {
        p {
            margin-left: 1.5rem;
        }

        > div {
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;

            > div {
                visibility: hidden;
                opacity: 0;
                margin-left: -1.75rem;
            }
        }
    }
`;

interface ReleaseCardProps {
    coverUrl?: string;
}

const ReleaseCard = styled.div<ReleaseCardProps>`
    background-color: ${({ theme }) => theme.colors.darkGrey};
    border-radius: ${({ theme }) => theme.borderRadius1};
    padding: 1.75rem;
    cursor: pointer;

    div {
        position: relative;
        border-radius: ${({ theme }) => theme.borderRadius1};
        background-color: ${({ theme }) => theme.colors.white}10;
        width: 100%;
        height: 17.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        overflow: hidden;

        ${({ coverUrl }) =>
            coverUrl &&
            `
            > div {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: url(${coverUrl});
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                z-index: 1;
            }
        `}

        img {
            z-index: 0;
            width: 4rem;
            max-height: 4rem;
            opacity: 0.45;
        }
    }

    h4 {
        font-size: 1.6rem;
        font-weight: 500;
        margin: 1.5rem 0 0.5rem 0;
    }

    p {
        color: ${({ theme }) => theme.colors.lightGrey};
    }
`;

const NoReleases = styled.div`
    grid-area: 1 / 1 / span 1 / span 6;
    text-align: center;
    padding: 4rem 0;
`;

const FeaturedArtistCard = styled.div`
    position: relative;
    background-color: ${({ theme }) => theme.colors.darkGrey};
    border-radius: ${({ theme }) => theme.borderRadius1};
    padding: 3rem;

    > span {
        position: absolute;
        top: 2rem;
        right: 2rem;
    }

    > div {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        div {
            display: flex;
            align-items: center;
            gap: 1rem;

            div {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: ${({ theme }) => theme.colors.white}20;
                height: 3.5rem;
                width: 3.5rem;
                border-radius: 20rem;
            }
        }
    }

    @media (max-width: 1200px) {
        padding: 2.5rem;
    }
`;

const Folder = styled.div`
    background-color: ${({ theme }) => theme.colors.darkGrey};
    border-radius: ${({ theme }) => theme.borderRadius1};
    padding: 3rem;
    img {
        height: 4rem;
        margin-bottom: 4rem;
    }
    p {
        color: ${({ theme }) => theme.colors.lightGrey};
    }

    @media (max-width: 1200px) {
        padding: 2.5rem;
    }
`;

export { ProductCard, ReleaseCard, NoReleases, FeaturedArtistCard, Folder };
