import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChevronDown, ChevronUp, Close } from 'react-ionicons';
import formatNumber from '../../util/formatNumber';
import { ChartStyles } from '../../util/types';
import { BarChartContainer, BarChartNav, BarChartWrapper } from '../styled';
import ChartWrapper from './ChartWrapper';

interface Props {
    title: string;
    description: string;
    explanation: string;
    labels: Array<string>;
    unit1: string;
    values1: Array<number>;
    unit2: string;
    values2: Array<number>;
    horizontal?: boolean;
    fixedWidth?: boolean;
    waiting?: boolean;
    chartStyles: ChartStyles;
}

const DoubleBarChart: React.FC<Props> = (props) => {
    const {
        title,
        description,
        explanation,
        labels,
        unit1,
        values1,
        unit2,
        values2,
        horizontal,
        fixedWidth,
        waiting,
        chartStyles
    } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [maxLength, setMaxLength] = useState<number>(values1.length);
    const [bars, expand] = useState<number>(maxLength < 5 ? maxLength : 5);

    useEffect(() => {
        setMaxLength(values1.length);

        if (!waiting) {
            setLoading(true);
            setTimeout(() => setLoading(false), 1000);
        }
    }, [values1, waiting]);

    const options = {
        indexAxis: horizontal ? 'y' : 'x',
        layout: {
            padding: {
                right: horizontal ? 70 : 0,
                top: horizontal ? 0 : 30,
                bottom: 0
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            }
        },
        title: {
            display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            bar: {
                backgroundColor: chartStyles.brand[0],
                borderRadius: 25,
                borderSkipped: false
            }
        },
        scales: {
            x1: horizontal
                ? {
                      type: 'linear',
                      display: false,
                      ticks: {
                          padding: 10,
                          beginAtZero: true
                      }
                  }
                : {
                      ticks: {
                          display: horizontal ? false : true,
                          font: {
                              size: (() => {
                                  if (window.innerWidth >= 800) return 17;
                                  else return window.innerWidth >= 600 ? 16 : 15;
                              })()
                          },
                          color: chartStyles.lightGrey,
                          padding: 20,
                          beginAtZero: true
                      },
                      grid: {
                          display: false,
                          drawBorder: false
                      }
                  },
            x2: {
                type: 'linear',
                display: false,
                ticks: {
                    beginAtZero: true
                }
            },
            y1: {
                ticks: {
                    display: horizontal ? true : false,
                    beginAtZero: true,
                    font: {
                        size: (() => {
                            if (window.innerWidth >= 800) return 17;
                            else return window.innerWidth >= 600 ? 16 : 15;
                        })()
                    },
                    color: chartStyles.white,
                    crossAlign: 'far',
                    padding: 10
                },
                grid: {
                    display: false,
                    drawBorder: false
                }
            },
            y2: {
                display: false,
                ticks: {
                    beginAtZero: true,
                    crossAlign: 'far',
                    padding: 10
                }
            }
        }
    };

    const data = {
        labels: (() => {
            if (!horizontal) return labels;
            else {
                if (bars > 5) return labels.filter((_v, index) => index < bars);
                else return labels.filter((_v, index) => index <= 4);
            }
        })(),
        datasets: [
            {
                label: 0,
                data: (() => {
                    if (!horizontal) return values1;
                    else {
                        if (bars > 5) return values1.filter((_v, index) => index < bars);
                        else return values1.filter((_v, index) => index <= 4);
                    }
                })(),
                yAxisID: horizontal ? undefined : 'y1',
                xAxisID: horizontal ? 'x1' : undefined,
                maxBarThickness: (() => {
                    if (window.innerWidth >= 1100) return horizontal ? 25 : 30;
                    else return horizontal ? 20 : 25;
                })(),
                datalabels: {
                    align: 'start',
                    anchor: 'end',
                    offset: (() => {
                        if (window.innerWidth >= 700) return horizontal ? -80 : -30;
                        else return horizontal ? -70 : -30;
                    })(),
                    color: chartStyles.white,
                    font: { weight: 400, size: window.innerWidth >= 700 ? 16 : 14 },
                    formatter: (v: any) =>
                        unit1 === '%' ? v.toFixed(1) + '%' : formatNumber(v) + unit1
                }
            },
            {
                label: 1,
                data: (() => {
                    if (!horizontal) return values2;
                    else {
                        if (bars > 5) return values2.filter((_v, index) => index < bars);
                        else return values2.filter((_v, index) => index <= 4);
                    }
                })(),
                yAxisID: horizontal ? undefined : 'y2',
                xAxisID: horizontal ? 'x2' : undefined,
                backgroundColor: chartStyles.brand[2],
                maxBarThickness: (() => {
                    if (window.innerWidth >= 1100) return horizontal ? 25 : 30;
                    else return horizontal ? 20 : 25;
                })(),
                datalabels: {
                    align: 'start',
                    anchor: 'end',
                    offset: horizontal ? -80 : -30,
                    color: chartStyles.white,
                    font: { weight: 400, size: window.innerWidth >= 700 ? 16 : 14 },
                    formatter: (v: any) =>
                        unit2 === '%' ? v.toFixed(1) + '%' : formatNumber(v) + unit2
                }
            }
        ]
    };

    return (
        <ChartWrapper explanation={explanation} title={title} description={description}>
            <BarChartWrapper scroll={!horizontal && !fixedWidth}>
                <BarChartContainer
                    horizontal={horizontal}
                    bars={(horizontal ? bars : maxLength) * 1.5}
                    fixedWidth={horizontal || fixedWidth}
                >
                    {!loading ? (
                        <Bar type="horizontal" data={data} options={options} />
                    ) : (
                        <p>Loading...</p>
                    )}
                </BarChartContainer>

                <BarChartNav>
                    {horizontal && maxLength > 5 && bars < maxLength && (
                        <p onClick={() => expand(bars + 5 <= maxLength ? bars + 5 : maxLength)}>
                            <ChevronDown color={chartStyles.accent} /> Show more
                        </p>
                    )}

                    {horizontal && maxLength > 5 && bars > 5 && (
                        <p
                            onClick={() =>
                                expand(
                                    bars === maxLength && maxLength % 5 !== 0
                                        ? maxLength - (maxLength % 5)
                                        : bars - 5
                                )
                            }
                        >
                            <ChevronUp color={chartStyles.accent} /> Show less
                        </p>
                    )}

                    {horizontal && maxLength > 5 && bars > 10 && (
                        <p onClick={() => expand(maxLength < 5 ? maxLength : 5)}>
                            <Close color={chartStyles.accent} /> Collapse
                        </p>
                    )}
                </BarChartNav>
            </BarChartWrapper>
        </ChartWrapper>
    );
};

export default DoubleBarChart;
