import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import products from '../../util/products';
import { Artist, User } from '../../util/types';
import { Heading4, NoData, ProductCard, ProductsGrid } from '../styled';
import InfoModal from '../modals/InfoModal';

interface Props {
    user: User;
    artist: Artist;
}

const Products: React.FC<Props> = ({ user, artist }) => {
    const { services } = artist;

    const [showInfo, openInfo] = useState<string | null>(null);

    const activeProducts = products.filter((product) => services.indexOf(product.name) !== -1);

    return (
        <>
            <Heading4 dashboard>Active services</Heading4>
            <ProductsGrid>
                {activeProducts.length > 0 ? (
                    activeProducts.map((product) => (
                        <Link to={`/${product.name.toLowerCase()}`} key={product.name}>
                            <ProductCard category={product.category} product={product.name}>
                                <div>
                                    <div />
                                </div>
                                <p>{product.display_name}</p>
                            </ProductCard>
                        </Link>
                    ))
                ) : (
                    <NoData>No products have been activated yet.</NoData>
                )}
            </ProductsGrid>

            <Heading4 dashboard>Discover more</Heading4>
            <ProductsGrid>
                {products
                    .filter(
                        (product) =>
                            services.indexOf(product.name) === -1 && product.name !== 'INDIVIDUAL'
                    )
                    .map((product) => (
                        <div onClick={() => !showInfo && openInfo(product.name)} key={product.name}>
                            <ProductCard category={product.category} product={product.name}>
                                <div>
                                    <div />
                                </div>
                                <p>{product.display_name}</p>
                                {product.coming_soon && <span>Coming soon</span>}
                            </ProductCard>
                        </div>
                    ))}
            </ProductsGrid>

            {showInfo && (
                <InfoModal showInfo={showInfo} onClose={() => openInfo(null)} user={user} />
            )}
        </>
    );
};

export default Products;
