import React, { useState, useRef } from 'react';
import ModalWrapper from './ModalWrapper';
import { Button, FullscreenOverlay, InfoMessage, UploadRelease } from '../styled';
import { Release } from '../../util/types';
import UploadIcon from '../../assets/upload.svg';
import DeleteIcon from '../../assets/close.svg';
import uploadCoverArt from '../../api/uploadCoverArt';
import convertFileToBase64 from '../../util/convertFileToBase64';
import shortenLabel from '../../util/shortenLabel';

interface Props {
    artistId: string;
    currentCoverUrl?: string;
    release: Release;
    onClose: () => void;
    refetch: () => void;
}

const UploadReleaseCover: React.FC<Props> = ({
    artistId,
    currentCoverUrl,
    release,
    onClose,
    refetch
}) => {
    const [file, setFile] = useState<File | null>();
    const [base64Image, setBase64Image] = useState<string | undefined>();
    const [success, setSuccess] = useState<boolean | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const inputComponent = useRef<HTMLInputElement | null>(null);

    const fileUploadButton = () => {
        if (inputComponent.current) {
            inputComponent.current.click();
            inputComponent.current.onchange = async (e) => {
                // @ts-ignore
                setFile(e.target?.files[0]);
                // @ts-ignore
                setBase64Image(await convertFileToBase64(e.target?.files[0]));
            };
        }
    };

    // Convert file to base64String, upload the file to AWS, show success message, refetch data
    const onSubmit = async () => {
        if (file && base64Image) {
            try {
                setLoading(true);

                await uploadCoverArt(
                    artistId,
                    release.release_id,
                    base64Image.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
                );

                setSuccess(true);
                setLoading(false);
                refetch();
                onClose();
            } catch (e) {
                setSuccess(false);
                setLoading(false);
            }
        }
    };

    return (
        <FullscreenOverlay>
            <ModalWrapper onClose={onClose}>
                <UploadRelease coverUrl={base64Image || currentCoverUrl}>
                    <div />
                    <span />
                    <input type="file" accept="image/*" hidden ref={inputComponent} />
                    {success ? (
                        <InfoMessage success>Your file has been uploaded successfully.</InfoMessage>
                    ) : !file ? (
                        <Button transparent onClick={fileUploadButton}>
                            <img src={UploadIcon} alt="Upload cover art" />
                            Upload new cover art
                        </Button>
                    ) : (
                        <div>
                            <p>
                                <img
                                    src={DeleteIcon}
                                    alt="Select new file"
                                    onClick={() => {
                                        setFile(null);
                                        setBase64Image(undefined);
                                    }}
                                />
                                {shortenLabel(file.name, 21)}
                            </p>
                            <Button transparent onClick={onSubmit}>
                                <img src={UploadIcon} alt="Submit" />
                                {loading ? 'Loading...' : 'Submit'}
                            </Button>
                        </div>
                    )}

                    {success === false && (
                        <InfoMessage error marginTop>
                            Oops, something went wrong, please try again.
                        </InfoMessage>
                    )}
                </UploadRelease>
            </ModalWrapper>
        </FullscreenOverlay>
    );
};

export default UploadReleaseCover;
