import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import ModalWrapper from './ModalWrapper';
import ReleasePreview from '../misc/ReleasePreview';
import { Button, CloseIcon, FullscreenOverlay, TrackDetails, Heading4 } from '../styled';
import shortenLabel from '../../util/shortenLabel';
import { Release } from '../../util/types';
import uploadLabelCopy from '../../api/uploadLabelCopy';
import confirmNewRelease from '../../api/confirmNewRelease';
import CustomCloseIcon from '../../assets/close.svg';
import UploadIcon from '../../assets/upload.svg';

interface Props {
    artist_id: string;
    baseUrl: string;
    onClose: () => void;
}

const NewReleaseModal: React.FC<Props> = ({ artist_id, baseUrl, onClose }) => {
    const [file, setFile] = useState<File | null>();
    const [error, setError] = useState<string | undefined>();
    const [success, setSuccess] = useState<boolean | undefined>();
    const [saving, setSaving] = useState<boolean>(false);
    const [processing, setProcessing] = useState<boolean>(false);
    const [release, setRelease] = useState<Release | null>(null);
    const [category, setCategory] = useState<string | undefined>();

    const inputComponent = useRef<HTMLInputElement | null>(null);

    const fileUploadButton = () => {
        if (inputComponent.current) {
            inputComponent.current.click();
            // @ts-ignore
            inputComponent.current.onchange = (e) => setFile(e.target?.files[0]);
        }
    };

    const onFileUpload = async () => {
        if (file) {
            try {
                setProcessing(true);

                const result = await uploadLabelCopy(artist_id, file);

                setRelease(result);
                setCategory(result.category);
                setProcessing(false);
            } catch (e) {
                setError(
                    "Your file couldn't be processed successfully, please try again or get in touch with us."
                );
                setProcessing(false);
            }
        }
    };

    const onConfirmation = async (correct: boolean) => {
        if (file && release) {
            try {
                setSaving(true);

                // Add manual_category if category has been changed
                const releaseData = release;
                if (category && category !== releaseData.category)
                    releaseData.manual_category = category;

                const newRelease = await confirmNewRelease(
                    artist_id,
                    correct ? releaseData : null,
                    file
                );

                setRelease(newRelease);
                setSaving(false);
                setSuccess(correct);
            } catch (e) {
                setError(
                    'An error occurred while trying to save the release, please try again or get in touch with us.'
                );
                setSaving(false);
            }
        }
    };

    return (
        <FullscreenOverlay>
            <ModalWrapper onClose={onClose}>
                <TrackDetails wider={release !== null}>
                    <div>
                        <Heading4>New release</Heading4>

                        <CloseIcon onClick={onClose} customImage relative>
                            <img src={CustomCloseIcon} alt="Close modal" />
                        </CloseIcon>
                    </div>

                    <span />

                    {error ? (
                        <div>{error}</div>
                    ) : success !== undefined && release ? (
                        !success ? (
                            <div>
                                Your label copy has been flagged as incorrect - we will review and
                                parse it as soon as possible.
                            </div>
                        ) : (
                            <>
                                <div>
                                    The release has been saved successfully. Please go ahead and
                                    upload the audio files for each track and a cover image.
                                </div>
                                <span />

                                <div>
                                    <Button transparent>
                                        <Link
                                            to={`/${baseUrl.replace(/\//g, '')}/${
                                                release.release_id
                                            }`}
                                        >
                                            Go to release
                                        </Link>
                                    </Button>
                                </div>
                            </>
                        )
                    ) : saving ? (
                        <div>A new release is being created...</div>
                    ) : processing ? (
                        <div>Your file is being processed...</div>
                    ) : release && category ? (
                        <>
                            <div>
                                Please confirm if this looks correct and adjust the category if
                                necessary:
                            </div>
                            <span />

                            <ReleasePreview
                                release={release}
                                onConfirmation={onConfirmation}
                                category={category}
                                onCategoryChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                    setCategory(e.target.value)
                                }
                            />
                        </>
                    ) : (
                        <>
                            <div>Please upload a label copy (.rtf) below.</div>
                            <span />

                            <div>
                                <input type="file" accept=".rtf" hidden ref={inputComponent} />

                                {!file ? (
                                    <Button transparent onClick={fileUploadButton}>
                                        <img src={UploadIcon} alt="Upload file" />
                                        Upload label copy
                                    </Button>
                                ) : (
                                    <div>
                                        <p>
                                            <img
                                                src={CustomCloseIcon}
                                                alt="Select new file"
                                                onClick={() => setFile(null)}
                                            />
                                            {shortenLabel(file.name, 24)}
                                        </p>
                                        <Button transparent onClick={onFileUpload}>
                                            <img src={UploadIcon} alt="Submit" />
                                            Submit
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </TrackDetails>
            </ModalWrapper>
        </FullscreenOverlay>
    );
};

export default NewReleaseModal;
