import { apiUrl } from './config';

const uploadCoverArt = async (
    artistId: string,
    releaseId: string,
    imageBase64String: string
): Promise<void> => {
    try {
        const res = await fetch(apiUrl + '/artist/v1/artist/' + artistId + '/coverart/upload', {
            method: 'post',
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                filename: releaseId,
                image_b64: imageBase64String
            })
        });

        if (!res.ok) throw new Error();

        return;
    } catch (e) {
        throw new Error();
    }
};

export default uploadCoverArt;
