import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import 'typeface-inter';
import 'typeface-source-sans-pro';

import GlobalStyle from '../style/GlobalStyle';
import theme from '../style/theme';

import { Artist, User } from '../util/types';

import BadLogin from './auth/BadLogin';
import ProtectedRoute from './auth/ProtectedRoute';
import TokenHandler from './auth/TokenHandler';
import Dashboard from './dashboard/Dashboard';
import ErrorBoundary from './misc/ErrorBoundary';
import ScrollToTop from './misc/ScrollToTop';
import Footer from './navigation/Footer';
import Navbar from './navigation/Navbar';
import DiscographyDetails from './products/DiscographyDetails';
import Discography from './products/Discography';
import Insight from './products/Insight';
import Monitor from './products/Monitor';
import ServiceStatements from './products/ServiceStatements';
import ServiceStatementOverview from './products/ServiceStatementOverview';
import ServiceStatementPreview from './products/ServiceStatementPreview';
import Folders from './products/Folders';

Chart.register(ChartDataLabels);

const App = () => {
    const [user, setUser] = useState<User | null>(null);
    const [artist, setArtist] = useState<Artist | null>(null);
    const [smallScreen, setSmallScreen] = useState<boolean>(window.innerWidth < 800);

    useEffect(() => {
        if (user && artist) localStorage.setItem('artist_id', artist?.artist_id);
    }, [user, artist]);

    window.addEventListener('resize', () => {
        if (smallScreen && window.innerWidth >= 800) setSmallScreen(false);
        else if (!smallScreen && window.innerWidth < 800) setSmallScreen(true);
    });

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />

            <ErrorBoundary>
                <Router>
                    <ScrollToTop />

                    <Navbar user={user} artist={artist} setArtist={setArtist} />

                    <Switch>
                        <Route path="/blackmagic/:token/:base64redirect">
                            <TokenHandler />
                        </Route>

                        <Route path="/blackmagic/:token">
                            <TokenHandler />
                        </Route>

                        <Route path="/error">
                            <BadLogin />
                        </Route>

                        <ProtectedRoute
                            path="/files"
                            user={user}
                            setUser={setUser}
                            setArtist={setArtist}
                        >
                            <Helmet title="Takkt | Files" />
                            <Folders user={user} artist={artist} />
                        </ProtectedRoute>

                        <ProtectedRoute
                            path="/discography/:release_id"
                            user={user}
                            setUser={setUser}
                            setArtist={setArtist}
                        >
                            <Helmet title="Takkt | Discography" />
                            <DiscographyDetails user={user} artist={artist} />
                        </ProtectedRoute>

                        <ProtectedRoute
                            path="/discography"
                            user={user}
                            setUser={setUser}
                            setArtist={setArtist}
                        >
                            <Helmet title="Takkt | Discography" />
                            <Discography user={user} artist={artist} />
                        </ProtectedRoute>

                        <ProtectedRoute
                            path="/service/statement/:service_statement_id"
                            user={user}
                            setUser={setUser}
                            setArtist={setArtist}
                        >
                            <Helmet title="Takkt | Service Statement Preview" />
                            <ServiceStatementPreview user={user} artist={artist} />
                        </ProtectedRoute>

                        <ProtectedRoute
                            path="/service/:period"
                            user={user}
                            setUser={setUser}
                            setArtist={setArtist}
                        >
                            <Helmet title="Takkt | Service Statement Overview" />
                            <ServiceStatementOverview user={user} artist={artist}/>
                        </ProtectedRoute>

                        <ProtectedRoute
                            path="/service"
                            user={user}
                            setUser={setUser}
                            setArtist={setArtist}
                        >
                            <Helmet title="Takkt | Service Statements" />
                            <ServiceStatements user={user} artist={artist} />
                        </ProtectedRoute>

                        <ProtectedRoute
                            path="/insight"
                            user={user}
                            setUser={setUser}
                            setArtist={setArtist}
                        >
                            <Helmet title="Takkt | Insight" />
                            <Insight smallScreen={smallScreen} user={user} artist={artist} />
                        </ProtectedRoute>

                        <ProtectedRoute
                            path="/monitor"
                            user={user}
                            setUser={setUser}
                            setArtist={setArtist}
                        >
                            <Helmet title="Takkt | Monitor" />
                            <Monitor smallScreen={smallScreen} user={user} artist={artist} />
                        </ProtectedRoute>

                        <ProtectedRoute
                            path="/"
                            user={user}
                            setUser={setUser}
                            setArtist={setArtist}
                        >
                            <Dashboard user={user} artist={artist} />
                        </ProtectedRoute>
                    </Switch>

                    <Footer user={user} />
                </Router>
            </ErrorBoundary>
        </ThemeProvider>
    );
};

export default App;
