import React, {useState} from "react";
import {Input, FilterBarWrapper} from "../styled";


interface Props {
    setFilter: any;
    expandHook?: any;
}

const FilterBar: React.FC<Props> = ({setFilter}) => {

    const [state, setState] = useState<string>("")

    const handleChange = (e: any) => {
        setState(e.target.value);
        setFilter(e.target.value);
    };

    return(
        <FilterBarWrapper>
            <Input
                transparent={true}
                value={state}
                onChange={handleChange}
                placeholder="Search for a title"
            />
        </FilterBarWrapper>
    )
}

export default FilterBar;
