import React, { useState } from 'react';
import ConsultancyImg from '../../assets/consultancy.svg';
import DataImg from '../../assets/data.svg';
import SettlementImg from '../../assets/settlement.svg';
import products from '../../util/products';
import { User } from '../../util/types';
import Loading from '../misc/Loading';
import ModalWrapper from './ModalWrapper';
import {
    Button,
    CloseIcon,
    FullscreenOverlay,
    Heading3,
    InfoMessage,
    ProductInformation
} from '../styled';

interface Props {
    showInfo: string;
    user: User;
    onClose: () => void;
}

const InfoModal: React.FC<Props> = ({ onClose, showInfo, user }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean | undefined>();

    const product = products.filter((p) => p.name === showInfo)[0];
    const category = ['data', 'settlement', 'consultancy'][product.category - 1];
    const image = [DataImg, SettlementImg, ConsultancyImg][product.category - 1];

    const requestAccess = () => {
        setLoading(true);

        fetch('https://api.takkt.io/music/v1/contact_form', {
            method: 'POST',
            body: JSON.stringify({
                name: user.first_name + ' ' + user.last_name,
                email: user.email,
                role: 'App',
                subject: 'New Access Request',
                message: `${user.first_name} ${user.last_name} has just requested access for ${product.display_name}.`
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                if (!res.ok) throw new Error();

                setLoading(false);
                setSuccess(true);
            })
            .catch(() => {
                setLoading(false);
                setSuccess(false);
            });
    };

    return (
        <FullscreenOverlay>
            <ModalWrapper onClose={onClose}>
                <ProductInformation show={showInfo !== null} category={product.category}>
                    <div>
                        <img src={image} alt={category} />
                    </div>

                    <div>
                        <Heading3>{showInfo !== null && product.display_name}</Heading3>

                        {showInfo !== null && (
                            <div dangerouslySetInnerHTML={{ __html: product.description }} />
                        )}

                        {loading ? (
                            <Loading small />
                        ) : success === true ? (
                            <InfoMessage success>
                                Thanks for your request, we'll get back to you asap.
                            </InfoMessage>
                        ) : success === false ? (
                            <InfoMessage error>
                                An unexpected error occurred, please try again or contact us
                                directly.
                            </InfoMessage>
                        ) : product.coming_soon ? (
                            <InfoMessage success>
                                We'll let you know as soon as it's available!
                            </InfoMessage>
                        ) : (
                            <div>
                                <Button onClick={requestAccess}>Request Access</Button>
                            </div>
                        )}
                    </div>

                    <CloseIcon onClick={onClose} />
                </ProductInformation>
            </ModalWrapper>
        </FullscreenOverlay>
    );
};

export default InfoModal;
