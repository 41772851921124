import React from 'react';
import { Link } from 'react-router-dom';
import combineTitle from '../../util/combineTitle';
import { Release } from '../../util/types';
import { ContentWrapper, DiscographyGrid, Heading4, NoReleases, ReleaseCard } from '../styled';
import shortenLabel from '../../util/shortenLabel';
import AlbumIcon from '../../assets/album.svg';
import VinylIcon from '../../assets/vinyl.svg';
import SingleIcon from '../../assets/single.svg';
import RingtuneIcon from '../../assets/ringtune.svg';
import DVDIcon from '../../assets/dvd.svg';
import VideoIcon from '../../assets/video.svg';
import CompilationIcon from '../../assets/compilation.svg';
import MiscellaneousIcon from '../../assets/miscellaneous.svg';

const DiscographyReleases: React.FC<{
    releases: Release[];
    covers: { release_id: string; thumbnail_url: string; regular_url: string }[];
    category: string;
    baseUrl: string;
}> = ({ releases, covers, category, baseUrl }) => {
    const filteredMasterReleases = releases
        .filter((release) => (release.manual_category || release.category) === category)
        .sort((a, b) =>
            combineTitle(a.manual_title || a.title, a.title_addition).localeCompare(
                combineTitle(b.manual_title || b.title, b.title_addition)
            )
        )
        .sort((a, b) => (b.manual_year || b.year) - (a.manual_year || a.year));

    let categoryIcon: string;

    switch (category) {
        case 'ALBUM':
            categoryIcon = AlbumIcon;
            break;
        case 'SINGLE/EP':
            categoryIcon = SingleIcon;
            break;
        case 'DVD':
            categoryIcon = DVDIcon;
            break;
        case 'VINYL':
            categoryIcon = VinylIcon;
            break;
        case 'VIDEO':
            categoryIcon = VideoIcon;
            break;
        case 'RINGTUNE':
            categoryIcon = RingtuneIcon;
            break;
        case 'COMPILATION':
            categoryIcon = CompilationIcon;
            break;
        default:
            categoryIcon = MiscellaneousIcon;
            break;
    }

    return (
        <ContentWrapper>
            <DiscographyGrid margin>
                {filteredMasterReleases.length === 0 ? (
                    <NoReleases>No releases were found in this category.</NoReleases>
                ) : (
                    filteredMasterReleases.map((release) => (
                        <ReleaseCard
                            key={release.release_id}
                            coverUrl={
                                covers.filter(
                                    ({ release_id }) => release_id === release.release_id
                                )[0]?.regular_url || ''
                            }
                        >
                            <Link to={`/${baseUrl.replace(/\//g, '')}/${release.release_id}`}>
                                <div>
                                    <div />
                                    <img src={categoryIcon} alt="Cover placeholder" />
                                </div>
                                <Heading4>
                                    {shortenLabel(
                                        combineTitle(
                                            release.manual_title || release.title,
                                            release.title_addition
                                        ),
                                        35
                                    )}
                                </Heading4>
                                <p>{release.manual_year || release.year}</p>
                            </Link>
                        </ReleaseCard>
                    ))
                )}
            </DiscographyGrid>
        </ContentWrapper>
    );
};

export default DiscographyReleases;
